import { PasswordValidation } from "utils/helper";
import { FormattedMessage } from "react-intl";

const CheckValidation = value => {

    const formError = {
        submit: true,
        password: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        confirmPassword: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        }
    };

    const emptyKeys = [
        'password',
        'confirmPassword'
    ];


    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "password" && !PasswordValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
            formError[emptyKeys[i]].errorMessage = 'Please Enter Valid Password';
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "confirmPassword" && !(value?.[emptyKeys[0]] === value?.[emptyKeys[1]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
            formError[emptyKeys[i]].errorMessage = 'Password & Confirm Password Should be Same';
        }
    }
    return formError;
}

export default CheckValidation;