import { useSnackbar } from 'notistack';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useContext, useEffect, useState } from "react";
import "components/common/Toast/Toast.scss";


const ToastNotification = () => {
    const [conf, setConf] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = key => (
        <Fragment>
            <IconButton onClick={() => { closeSnackbar(key); }}>
                <div className='closeIcon'>
                    <CloseIcon />
                </div>
            </IconButton>
        </Fragment>
    );

    useEffect(() => {
        if (conf?.msg) {
            let variant = 'info';
            if (conf.variant) {
                variant = conf.variant;
            }
            enqueueSnackbar(conf.msg, {
                variant: variant,
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
                action
            });
        }
    }, [conf, enqueueSnackbar]);

    return [conf, setConf];
};

export default ToastNotification;