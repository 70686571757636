import {
    GET_QUESTIONS,
} from "./constant";

export const getQuestionsCallAPI = (type, data, external = {}) => {
    switch (type) {
        case GET_QUESTIONS:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};