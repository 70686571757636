import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Image, Stack, Badge } from "react-bootstrap";
import addIcon from "assets/images/addIcon.svg";
import arrowRightIconColor from "assets/images/arrow_right_color.svg";
import arrowRightIconFadeColor from "assets/images/arrow_right_fade_color.svg";
import 'assets/styles/common.scss';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { getProfileCallAPI } from "store/Dashboard/Profile/action";
import { GET_PROFILE } from "store/Dashboard/Profile/constant";
import { FormattedMessage, useIntl } from "react-intl";
import ToastNotification from "components/common/Toast";
import crossIcon from "assets/images/crossIcon.svg";
import { teachingMaterialDropDownCallAPI } from "store/Auth/Registration/TeachingMaterial/action";
import { TEACHING_MATERIAL_DROPDOWN } from "store/Auth/Registration/TeachingMaterial/constant";
import CommonModal from "components/common/Modal";
import SelectInput from "components/common/Input/SelectInput";
import LabelWithInput from "components/common/Input/LabelWithInput";
import { subjectDropDownCallAPI } from "store/Auth/Registration/Subject/action";
import { SUBJECT_DROPDOWN } from "store/Auth/Registration/Subject/constant";
import InputAdornment from '@mui/material/InputAdornment';
import Search from "assets/images/Registration/search.png";
import { gradeLevelDropDownCallAPI } from "store/Auth/Registration/GreadeLevel/action";
import { GRADE_LEVEL_DROPDOWN } from "store/Auth/Registration/GreadeLevel/constant";
import { languageDropDownCallAPI } from "store/Auth/Registration/Language/action";
import { LANGUAGE_DROPDOWN } from "store/Auth/Registration/Language/constant";
import { addTeacherSubjectCallAPI } from "store/Dashboard/AddTeacherSubject/action";
import { ADD_TEACHER_SUBJECT } from "store/Dashboard/AddTeacherSubject/constant";
import Button from '@mui/material/Button';
import CheckSubjectValidation from "components/feature/Teacher/TeacherDetails/validation";
import "components/feature/Assessment/Assessment.scss";
import { InterViewRound, InterViewRoundURL, questions } from "routes/AppUrls";
import { LocaleContext } from "routes/Router";
import { getQuestionsCallAPI } from "store/Dashboard/GetQuestions/action";
import { GET_QUESTIONS } from "store/Dashboard/GetQuestions/constant";
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { getUserRole } from "utils/general";
import verifiedIcon from "assets/images/verified.svg";

const Assessment = ({ onClick }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const intl = useIntl();
    const listRef = useRef(null);
    const [conf, setConf] = ToastNotification();
    const [isSubjectsError, setIsSubjectsError] = useState(false);
    const [isSubjectAddModalActive, setIsSubjectAddModalActive] = useState(false);
    const [selectMaterial, setSelectMaterial] = useState("IG");
    const [materialId, setMaterialId] = useState(19);
    const [questionLoaderId, setQuestionLoaderId] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isListVisible, setIsListVisible] = useState(false);
    const [interviewModal, setInterviewModal] = useState(false);
    const [interviewData, setInterviewData] = useState("");

    const [formAddSubjectValue, setFormAddSubjectValue] = useState({
        gradeLevel: "",
        language: "",
        experience: "",
        subject: ""
    });

    const [formAddSubjectId, setFormAddSubjectId] = useState({
        gradeLevel: "",
        language: "",
        subject: ""
    });

    const profileData = useSelector(state => state?.getProfileReducer?.payload?.data);
    const TeachingMaterialList = useSelector(state => state?.teachingMaterialDropDownReducer?.payload?.data);
    const SubjectList = useSelector(state => state?.subjectDropDownReducer?.payload?.data?.data);
    const GradeLevelList = useSelector(state => state?.gradeLevelDropDownReducer?.payload?.data);
    const LanguageList = useSelector(state => state?.languageDropDownReducer?.payload?.data);
    const questionsLoader = useSelector(state => state?.getQuestionsReducer?.loading);
    const teacherProfileId = useSelector(state => state?.basicDetailsReducer?.teacherProfileId);

    useEffect(() => {
        getProfileApi();
        getTeachingMaterialListApi();
        getSubjectListApi();
        getGradeLevelListApi();
        getLanguageListApi();
    }, []);

    const getProfileApi = (res) => {
        if (getUserRole() === 'Administrator') {
            dispatch(getProfileCallAPI(GET_PROFILE, teacherProfileId, { locale: locale, run: true, navigate: navigate }));
        } else {
            dispatch(getProfileCallAPI(GET_PROFILE, {}, { locale: locale, run: true, navigate: navigate }));
        }
    };

    const getTeachingMaterialListApi = () => {
        let body = {
            "slug": "teaching-material"
        };
        dispatch(teachingMaterialDropDownCallAPI(TEACHING_MATERIAL_DROPDOWN, body, { navigate: navigate }));
    };

    const getProfileDetails = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: res?.data?.data, variant: 'success' });
            getProfileApi();
        };
    };

    const getSubjectListApi = () => {
        let body = {
            "slug": "subject"
        };
        dispatch(subjectDropDownCallAPI(SUBJECT_DROPDOWN, body, { navigate: navigate }));
    };

    const getGradeLevelListApi = () => {
        let body = {
            "slug": "gradlevel"
        };
        dispatch(gradeLevelDropDownCallAPI(GRADE_LEVEL_DROPDOWN, body, { navigate: navigate }));
    };

    const getLanguageListApi = () => {
        dispatch(languageDropDownCallAPI(LANGUAGE_DROPDOWN, {}, { navigate: navigate }));
    };

    const handleAddSubjects = () => {
        setIsSubjectsError(false);
        setIsSubjectAddModalActive(true);
        setFormAddSubjectValue((prev) => ({
            ...prev,
            ["gradeLevel"]: "",
            ["language"]: "",
            ["experience"]: "",
            ["subject"]: "",
        }));
        setSelectMaterial('IG');
    };

    const handleSelect = (data) => {
        setSelectMaterial(data?.name);
        setMaterialId(data?.id);
    };

    const handleCloseSubjectModel = () => {
        setIsSubjectAddModalActive(false);
    };

    const handleAddSubjectChange = (e) => {
        const { name, value } = e?.target;
        setFormAddSubjectValue((prev) => ({
            ...prev,
            [name]: value
        }))
        if (name === "subject") {
            const filteredOptions = SubjectList && SubjectList?.length > 0 && SubjectList?.filter(option =>
                option?.name?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filteredOptions);
        }
    };

    const handleItemClick = (item) => {
        setFormAddSubjectValue((prev) => ({
            ...prev,
            ['subject']: item?.name
        }));
        setFormAddSubjectId((prev) => ({
            ...prev,
            ['subject']: item?.id
        }));
        setIsListVisible(false);
    };

    const handleAddSubjectInputChange = (e) => {
        const { name, value } = e?.target;
        setFormAddSubjectValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));
        setFormAddSubjectId((prev) => ({
            ...prev,
            [name]: value?.id
        }));
    };

    const handleAddSubjectSubmit = () => {
        const error = CheckSubjectValidation(formAddSubjectValue);
        setIsSubjectsError(error);
        if (error.submit) {
            let body = {
                "subject_id": formAddSubjectId?.subject,
                "grade_id": formAddSubjectId?.gradeLevel,
                "teaching_material_id": materialId,
                "language_id": formAddSubjectId?.language,
                "experience": formAddSubjectValue?.experience,
                "status": 69
            }
            dispatch(addTeacherSubjectCallAPI(ADD_TEACHER_SUBJECT, body, { run: true, handleFunction: (res) => getProfileDetails(res), navigate: navigate, toast: true, handleToastError: (res) => handleError(res) }));
            setIsSubjectAddModalActive(false);
        }
    };

    const handleError = (res) => {
        setConf({ msg: res?.data?.message, variant: 'error' });
    };

    const handleStep = (data) => {
        if (data?.status?.slug === "pending") {
            setQuestionLoaderId(data?.id);
            let body = {
                "language": data?.language?.name,
                "type_of_board": data?.teaching_material?.name,
                "subject": data?.name,
                "grade": data?.grade?.name?.split(" ")?.[0],
                "teacher_subject_id": String(data?.id)
            }
            dispatch(getQuestionsCallAPI(GET_QUESTIONS, body, { run: true, handleFunction: (res) => handleRedirect(res), navigate: navigate }));
        } else if (data?.status?.slug?.includes("interview")) {
            setInterviewData(data)
            setInterviewModal(true)
        }
        else {
            let body = {
                subjectId: data?.value_id,
                gradeId: data?.grade?.id,
                teacherMaterialId: data?.teaching_material?.id
            }
            navigate(InterViewRound(locale), { state: { body } });
        }
    };

    const handleRedirect = (res) => {
        if (res?.status === 200) {
            navigate(questions(locale));
        }
    };

    const handleJoinMeeting = (data) => {
        window.open(data.interview_join_url);
    };

    return (
        <Row className="mt-5 mt-lg-0 pb-5 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="TeacherDashboard" /></h5>
            <Row className="page-contents mt-3 m-0">
                <div className="flex justify-between items-center">
                    <p className="subjectTitle m-0">
                        <FormattedMessage id="profile.Subjects" />
                    </p>
                    <Stack direction="horizontal" gap={2} className="addSubjectButton flex justify-end" onClick={() => handleAddSubjects()}>
                        <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                        <p className='mb-0 font-semibold'><FormattedMessage id="profile.AddSubject" /></p>
                    </Stack>
                </div>
                {profileData?.data?.subjects?.length > 0 && profileData?.data?.subjects.map((result, index) => (
                    <div className="boxBorder mt-3">
                        <div className="flex align-items-center justify-between">
                            <p className="mb-0 mr-3 subjectName">{result?.name}</p>
                            <Badge pill className={result?.status?.slug === "failed" ? 'failedPill' : result?.status?.slug === "verified" ? "verifiedPill d-flex items-center text-center" : 'pendingPill'}>
                                {result?.status?.slug === "verified" && <img src={verifiedIcon} alt={"badge"} className="me-2" />}
                                {["failed", "pending", "evaluating", "verified"].includes(result?.status?.slug) ? result?.status?.name : <FormattedMessage id="SlotBooked" />}
                            </Badge>
                        </div>
                        <div className="flex align-items-center mt-3 subjectKey flex-wrap">
                            <p className="mr-5"><FormattedMessage id="Grade" />: <span className="subjectValue">{result?.grade?.name}</span></p>
                            <p className="mr-5"><FormattedMessage id="Material" />: <span className="subjectValue">{result?.teaching_material?.name}</span></p>
                            <p className="mr-5"><FormattedMessage id="Language" />: <span className="subjectValue">{result?.language?.name}</span></p>
                            <p className="mr-5"><FormattedMessage id="Experience" />: <span className="subjectValue">{result?.experience} {'year'}</span></p>
                        </div>
                        {/* <div className="flex align-items-center mt-3 subjectKey justify-between"> */}
                        {result?.status?.slug !== "verified" &&
                            <Row className="w-100 m-0 subjectKey flex align-items-center">
                                <Col xs={12} md={8} className="p-0">
                                    <p className="m-0"> <FormattedMessage id="CurrentStage" />:
                                        {result?.status?.slug === "pending" && <span className="subjectValue"> <FormattedMessage id="AIExam" /></span>}
                                        {result?.status?.slug === "evaluating" && <span className="subjectValue"> <FormattedMessage id="AIExamCompleted,SelectInterviewSlot" /></span>}
                                        {result?.status?.slug === "failed" && <span className="subjectValue"> <FormattedMessage id="AIExamfailed,you’llbeeligibletoretaketheexamafter" /> <strong className="date">{result?.next_exam_date}</strong></span>}
                                        {result?.status?.slug?.includes("interview") && <span className="subjectValue"> <FormattedMessage id="InterviewScheduled" /></span>}
                                    </p>
                                </Col>

                                <Col xs={12} md={4} className={`mt-2 mt-md-0 ${locale === "en" ? "text-end" : "text-start"} p-0`}>
                                    <Button className={result?.status?.slug === "failed" ? "btn_disabled" : "btn_outline"} variant="outlined" disabled={(result?.status?.slug === "failed" || questionsLoader) ? true : false} onClick={() => profileData?.data?.is_active ? handleStep(result) : onClick()}>
                                        {/* {result?.status === "Evaluating" ? <FormattedMessage id="Select Slot" /> : <FormattedMessage id="Start AI Exam" />} */}
                                        {result?.status?.slug === "evaluating" ? <FormattedMessage id="SelectInterviewSlot" /> : result?.status?.slug?.includes("interview") ? <FormattedMessage id="Viewmeetingdetails" /> : <FormattedMessage id="StartAIExam" />}
                                        {questionsLoader && questionLoaderId === result?.id ?
                                            <CircularProgress color="secondary" size={18} sx={{ marginLeft: '10px' }} />
                                            :
                                            <Image src={result?.status?.slug === "failed" ? arrowRightIconFadeColor : arrowRightIconColor} alt={"icon"} style={{ cursor: "pointer", height: '20px', width: '20px', marginLeft: "5px" }} />
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {/* </div> */}
                    </div>
                ))}
            </Row>

            {/* Add Subject Modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isSubjectAddModalActive} handleClose={() => handleCloseSubjectModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="profile.AddSubject" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseSubjectModel()} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-4'>
                                <p className='mb-2 teacher_label'><FormattedMessage id="register.TeachingMaterial" /></p>
                                <div className="flex flex-row items-center flex-wrap gap-3 teachingMaterial">
                                    {TeachingMaterialList && TeachingMaterialList?.data?.length > 0 && TeachingMaterialList.data.map((k, i) => (
                                        <p className={selectMaterial === k?.name ? 'selectTeachingMaterial' : 'unSelectTeachingMaterial'} onClick={() => handleSelect(k)}>{k?.name}</p>
                                    ))}
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <LabelWithInput
                                    ref={listRef}
                                    label={<FormattedMessage id="register.SelectASubject" />}
                                    placeholder={intl.formatMessage({ id: "register.SelectASubject" })}
                                    name="subject"
                                    type="text"
                                    value={formAddSubjectValue?.subject}
                                    onChange={(e) => handleAddSubjectChange(e)}
                                    onClick={() => setIsListVisible(true)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={Search} alt={"icon"} />
                                            </InputAdornment>
                                        )
                                    }}
                                    errorText={isSubjectsError && isSubjectsError?.subject?.error && isSubjectsError?.subject?.errorMessage}
                                />
                                {isListVisible && (
                                    <ul ref={listRef} style={{ width: "37%" }} className="custom-list">
                                        {filteredOptions?.length > 0 && filteredOptions?.map((k) => (
                                            <li className="list-item" onClick={() => handleItemClick(k)}>{k?.name}</li>
                                        ))}
                                    </ul>
                                )}

                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <SelectInput
                                    label={<FormattedMessage id="register.GradeLevel" />}
                                    placeholder={intl.formatMessage({ id: "register.GradeLevel" })}
                                    name={"gradeLevel"}
                                    value={formAddSubjectValue?.gradeLevel}
                                    data={GradeLevelList?.data}
                                    onChange={(e) => handleAddSubjectInputChange(e)}
                                    errorText={isSubjectsError && isSubjectsError?.gradeLevel?.error && isSubjectsError?.gradeLevel?.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-2'>
                                <SelectInput
                                    label={<FormattedMessage id="register.Language" />}
                                    placeholder={intl.formatMessage({ id: "register.Language" })}
                                    name={"language"}
                                    value={formAddSubjectValue?.language}
                                    data={LanguageList?.data}
                                    onChange={(e) => handleAddSubjectInputChange(e)}
                                    errorText={isSubjectsError && isSubjectsError?.language?.error && isSubjectsError?.language?.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-2'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.TeachingExperience" />}
                                    placeholder={intl.formatMessage({ id: "register.TeachingExperience" })}
                                    name="experience"
                                    type="number"
                                    value={formAddSubjectValue?.experience}
                                    onChange={(e) => handleAddSubjectChange(e)}
                                    errorText={isSubjectsError && isSubjectsError?.experience?.error && isSubjectsError?.experience?.errorMessage}
                                />
                            </Col>
                        </Row>

                        <Row >
                            <Button variant="contained" onClick={() => handleAddSubjectSubmit()} className='button mt-5 md:mt-2'><FormattedMessage id="Save" /></Button>
                        </Row>
                    </Box>
                }
            />
            {/* Add Subject Modal  */}

            {/* Add Interview Modal  */}
            <CommonModal padding={'32px 48px'} width={'500px'} modalActive={interviewModal} handleClose={() => setInterviewModal(false)}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id={interviewData?.status?.name} /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => setInterviewModal(false)} />
                                </div>
                            </Col>

                            <Col xs={12} md={12}>
                                <div className='eventViewScheduleTitle mt-4'>
                                    {moment(interviewData?.interview_time).format('ddd, D MMMM')}
                                </div>
                                <div className='eventViewDateTime mt-3'>
                                    {moment(interviewData?.interview_time).format('hh:mm a')} -{' '}
                                    {moment(interviewData?.interview_time).add(1, 'hour').format('hh:mm a')}
                                </div>
                            </Col>

                            <Stack direction="horizontal" gap={2} className='d-flex justify-content-center mt-5'>
                                <Button variant="contained" className='button w-full' onClick={() => handleJoinMeeting(interviewData)}><FormattedMessage id="JoinMeeting" /></Button>
                            </Stack>
                        </Row>
                    </Box>
                }
            />
            {/* Add Interview Modal  */}
        </Row>
    );
};

export default Assessment;
