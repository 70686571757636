import React, { useEffect, useState } from "react";
import { Row, Col, Image, Stack } from "react-bootstrap";
import addIcon from "assets/images/addIcon.svg";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/CategoryManagementDetails/categoryManagementDetails.scss";
import ReusableTabs from "components/common/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToastNotification from "components/common/Toast";
import CommonModal from "components/common/Modal";
import crossIcon from "assets/images/crossIcon.svg";
import EnglishIcon from "assets/images/AdminDashboard/EnglishIcon.png";
import ArabicWhiteIcon from "assets/images/AdminDashboard/EnglishWhiteIcon.svg";
import ArabicIcon from "assets/images/AdminDashboard/ArabicIcon.png";
import EnglishWhiteIcon from "assets/images/AdminDashboard/ArabicWhiteIcon.svg";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import LabelWithInput from "components/common/Input/LabelWithInput";
import { getContentManagementDataCallAPI } from "store/AdminDashboard/GetContentManagement/action";
import { GET_CONTENT_MANAGEMENT_DATA } from "store/AdminDashboard/GetContentManagement/constant";
import { getFaqCallAPI } from "store/AdminDashboard/GetFAQ/action";
import { GET_FAQ } from "store/AdminDashboard/GetFAQ/constant";
import { addContentManagementDataCallAPI } from "store/AdminDashboard/AddContentManagement/action";
import { ADD_CONTENT_MANAGEMENT_DATA } from "store/AdminDashboard/AddContentManagement/constant";
import { addFaqCallAPI } from "store/AdminDashboard/AddFAQ/action";
import { ADD_FAQ } from "store/AdminDashboard/AddFAQ/constant";
import ReusableAccordion from "components/common/ReusableAccordian";
import editIcon from "assets/images/edit.svg";
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Typography, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import plusIcon from "assets/images/Navbar/plus.png";
import minusIcon from "assets/images/Navbar/minus.png";
import styled from 'styled-components';
import CustomPopover from "components/common/CutomPopover";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { editFaqCallAPI } from "store/AdminDashboard/EditFAQ/action";
import { EDIT_FAQ } from "store/AdminDashboard/EditFAQ/constant";
import { deleteFaqCallAPI } from "store/AdminDashboard/DeleteFAq/action";
import { DELETE_FAQ } from "store/AdminDashboard/DeleteFAq/constant";

const AccordionStyle = styled.div`
    .MuiPaper-root{
        border-bottom: 1px solid var(--Colors-Stroke-stroke, #DDDDDD) !important;
        box-shadow: none !important;
    }
    .MuiAccordionSummary-content {
        align-items:center !important;
    }
`;

const ContentManagementDetails = () => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [conf, setConf] = ToastNotification();
    const [tab, setTab] = useState(1);
    const [modalTab, setModalTab] = useState(1);
    const [editModel, setEditModel] = useState(false);
    const [tabInsideData, setTabInsideData] = useState(1);
    const [isError, setIsError] = useState(false);
    const [isAddModalActive, setIsAddModalActive] = useState(false);

    const contentManagementList = useSelector(state => state?.getContentManagementDataReducer?.payload?.data);
    const FaqDataList = useSelector(state => state?.getFaqDataReducer?.payload?.data);

    console.log(contentManagementList, FaqDataList)

    const [formValue, setFormValue] = useState({
        'questionEn': '',
        'answerEn': '',
        'questionAr': '',
        'answerAr': '',
        'messageEn': '',
        'messageAr': ''
    });

    useEffect(() => {
        getContentManagementLists();
    }, []);

    const getType = (val) => {
        switch (val) {
            case 1:
                return "about"
            case 2:
                return "privacy-policy"
            case 3:
                return "terms-conditions"
            default:
                return "about"
        }
    };

    const getContentManagementLists = (val = 1) => {
        dispatch(getContentManagementDataCallAPI(GET_CONTENT_MANAGEMENT_DATA, { type: getType(val) }, { run: true, navigate: navigate }));
    };

    const getFaqList = () => {
        dispatch(getFaqCallAPI(GET_FAQ, {}, { run: true, navigate: navigate }));
    };

    const tabData = [
        { label: intl.formatMessage({ id: "About Website" }) },
        { label: intl.formatMessage({ id: "Privacy Policy" }) },
        { label: intl.formatMessage({ id: "Terms & Conditions" }) },
        { label: intl.formatMessage({ id: "FAQs" }) },
    ];

    const tabModalData = [
        { label: intl.formatMessage({ id: "English" }), icon: EnglishIcon, colorIcon: EnglishWhiteIcon },
        { label: intl.formatMessage({ id: "Arabic" }), icon: ArabicIcon, colorIcon: ArabicWhiteIcon }
    ];

    const handleTabChange = (val) => {
        setTab(val);
        setTabInsideData(1);
        if (val === 4) {
            getFaqList();
        } else {
            getContentManagementLists(val);
        }
    };

    const handleModalTabChange = (val) => {
        setModalTab(val);
    };

    const handleTabInsideDataChange = (val) => {
        setTabInsideData(val);
    };

    const handleAddModel = () => {
        setEditFAQ(false);
        setEditModel(false);
        setModalTab(1);
        setIsAddModalActive(true);
        setFormValue((prev) => ({
            ...prev,
            ['questionEn']: '',
            ['answerEn']: '',
            ['questionAr']: '',
            ['answerAr']: '',
            ['messageEn']: '',
            ['messageAr']: ''
        }))
    };

    const handleEditModal = () => {
        setEditModel(true);
        setModalTab(1);
        setIsAddModalActive(true);
        setFormValue((prev) => ({
            ...prev,
            ['messageEn']: contentManagementList?.data?.data_en,
            ['messageAr']: contentManagementList?.data?.data_ar
        }))
    };

    const handleEditFAQ = () => {
        setEditFAQ(true);
        setEditModel(true);
        setModalTab(1);
        setIsAddModalActive(true);
        setFormValue((prev) => ({
            ...prev,
            ['questionEn']: viewData?.question_en,
            ['answerEn']: viewData?.answer_en,
            ['questionAr']: viewData?.question_ar,
            ['answerAr']: viewData?.answer_ar
        }))
    };

    const handleCloseAddModel = () => {
        setIsAddModalActive(false);
    };

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const getModalHeading = () => {
        switch (tab) {
            case 1:
                return <FormattedMessage id="Add About Website" />
            case 2:
                return <FormattedMessage id="Add Privacy Policy" />
            case 3:
                return <FormattedMessage id="Add Terms & Conditions" />
            case 4:
                return <FormattedMessage id="Add Question" />
            default:
                return <FormattedMessage id="Add About Website" />
        }
    };

    const getModalEditHeading = () => {
        switch (tab) {
            case 1:
                return <FormattedMessage id="Edit About Website" />
            case 2:
                return <FormattedMessage id="Edit Privacy Policy" />
            case 3:
                return <FormattedMessage id="Edit Terms & Conditions" />
            case 4:
                return <FormattedMessage id="Edit Question" />
            default:
                return <FormattedMessage id="Edit About Website" />
        }
    };

    const getSlug = () => {
        switch (tab) {
            case 1:
                return "about"
            case 2:
                return "privacy-policy"
            case 3:
                return "terms-conditions"
            default:
                return "about"
        }
    };

    const handleSave = () => {
        if (formValue?.messageAr !== "" && formValue?.messageEn !== "") {
            let body = {
                "title": "About Website",
                "slug": getSlug(),
                "data_en": formValue?.messageEn,
                "data_ar": formValue?.messageAr
            }
            dispatch(addContentManagementDataCallAPI(ADD_CONTENT_MANAGEMENT_DATA, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
        }
    };

    const handleSuccess = (res) => {
        console.log(res)
        if (res?.status === 200) {
            setAnchorEl(null);
            setConf({ msg: res?.data?.message, variant: 'success' });
            setIsAddModalActive(false);
            if (tab === 4) {
                getFaqList();
            } else {
                getContentManagementLists(tab);
            }
        }
    };

    const handleSaveFAQ = () => {
        if (formValue?.questionEn !== "" && formValue?.questionAr !== "" && formValue?.answerEn !== "" && formValue?.answerAr !== "") {
            let body = {
                "question_en": formValue?.questionEn,
                "answer_en": formValue?.answerEn,
                "question_ar": formValue?.questionAr,
                "answer_ar": formValue?.answerAr
            }
            dispatch(addFaqCallAPI(ADD_FAQ, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
        }
    };

    const handleEditSubmitFAQ = () => {
        if (formValue?.questionEn !== "" && formValue?.questionAr !== "" && formValue?.answerEn !== "" && formValue?.answerAr !== "") {
            let body = {
                "question_en": formValue?.questionEn,
                "answer_en": formValue?.answerEn,
                "question_ar": formValue?.questionAr,
                "answer_ar": formValue?.answerAr
            }
            dispatch(editFaqCallAPI(EDIT_FAQ, body, { updateId: viewData?.id, run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
        }
    };

    const handleDeleteSubmitFAQ = () => {
        dispatch(deleteFaqCallAPI(DELETE_FAQ, {}, { updateId: viewData?.id, run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
    };

    //////////accordian//////////
    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [viewData, setViewData] = useState("");
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [editFAQ, setEditFAQ] = useState(false);

    const handleAccordionChange = (panel) => {
        setExpanded(expanded === panel ? false : panel); // Toggle accordion
    };

    const handlePopoverClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setViewData(item);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const PopoverData = [
        {
            label: <FormattedMessage id="Edit" />,
            icon: <EditOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => handleEditFAQ()
        },
        {
            label: <FormattedMessage id="Delete" />,
            icon: <DeleteOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => handleDeleteSubmitFAQ()

        }
    ];
    //////////accordian//////////

    return (
        <Row className="mt-5 mt-lg-0 pb-0 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="Content Management" /></h5>

            <Row className="mt-1 p-0 m-0">
                <Col md={12} lg={12} xl={12} xxl={8} className="m-0 p-0">
                    <ReusableTabs tabs={tabData} defaultTab={1} currentTab={(val) => handleTabChange(val)} />
                </Col>
            </Row>

            {tab === 1 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 p-3">
                    <Row className="p-0 m-0 ">
                        <div className="flex items-center justify-between">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="About Website" />
                            </p>
                            {contentManagementList?.data?.data_en !== "" &&
                                <div className="d-flex items-center" onClick={() => handleEditModal()} style={{ cursor: 'pointer' }}>
                                    <Image src={editIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-1" />
                                    <p className='p-0 m-0 fs-16 fw-600 fc-911E59 ff-InterSemiBold'><FormattedMessage id="Make Changes" /></p>
                                </div>
                            }
                        </div>
                    </Row>

                    {contentManagementList?.data?.data_en !== "" ?
                        <Row className="mt-2">
                            <Col md={10} lg={8} xl={6} xxl={4} >
                                <ReusableTabs tabs={tabModalData} image={true} defaultTab={1} currentTab={(val) => handleTabInsideDataChange(val)} />
                            </Col>

                            <Col xs={12} md={12} lg={12} className='mt-4'>
                                {tabInsideData === 1 ?
                                    <p>{contentManagementList?.data?.data_en}</p>
                                    :
                                    <p>{contentManagementList?.data?.data_ar}</p>
                                }
                            </Col>
                        </Row>
                        :
                        <Row className="p-0 m-0 mt-5 pt-5 mb-5 pb-5 flex flex-col text-center justify-center items-center">
                            <Col lg={6}>
                                <p className="m-0 fs-20 fw-400 fc-1E1E1E ff-InterRegular">
                                    <FormattedMessage id="You haven’t saved anything here yet. Click on the button below to add About website" />
                                </p>
                                <Button variant="contained" className='button mt-4' onClick={() => handleAddModel()}>
                                    <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-2" />
                                    <FormattedMessage id="Add About Website" />
                                </Button>
                            </Col>
                        </Row>
                    }
                </Row>
            }

            {tab === 2 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 p-3">
                    <Row className="p-0 m-0 ">
                        <div className="flex items-center justify-between">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="Privacy Policy" />
                            </p>
                            {contentManagementList?.data?.data_en !== "" &&
                                <div className="d-flex items-center" onClick={() => handleEditModal()} style={{ cursor: 'pointer' }}>
                                    <Image src={editIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-1" />
                                    <p className='p-0 m-0 fs-16 fw-600 fc-911E59 ff-InterSemiBold'><FormattedMessage id="Make Changes" /></p>
                                </div>
                            }
                        </div>
                    </Row>

                    {contentManagementList?.data?.data_en !== "" ?
                        <Row className="mt-2">
                            <Col md={10} lg={8} xl={6} xxl={4} >
                                <ReusableTabs tabs={tabModalData} image={true} defaultTab={1} currentTab={(val) => handleTabInsideDataChange(val)} />
                            </Col>

                            <Col xs={12} md={12} lg={12} className='mt-4'>
                                {tabInsideData === 1 ?
                                    <p>{contentManagementList?.data?.data_en}</p>
                                    :
                                    <p>{contentManagementList?.data?.data_ar}</p>
                                }
                            </Col>
                        </Row>
                        :
                        <Row className="p-0 m-0 mt-5 pt-5 mb-5 pb-5 flex flex-col text-center justify-center items-center">
                            <Col lg={6}>
                                <p className="m-0 fs-20 fw-400 fc-1E1E1E ff-InterRegular">
                                    <FormattedMessage id="You haven’t saved anything here yet. Click on the button below to add Privacy Policy" />
                                </p>
                                <Button variant="contained" className='button mt-4' onClick={() => handleAddModel()}>
                                    <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-2" />
                                    <FormattedMessage id="Add Privacy Policy" />
                                </Button>
                            </Col>
                        </Row>
                    }
                </Row>
            }

            {tab === 3 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 p-3">
                    <Row className="p-0 m-0 ">
                        <div className="flex items-center justify-between">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="Terms & Conditions" />
                            </p>
                            {contentManagementList?.data?.data_en !== "" &&
                                <div className="d-flex items-center" onClick={() => handleEditModal()} style={{ cursor: 'pointer' }}>
                                    <Image src={editIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-1" />
                                    <p className='p-0 m-0 fs-16 fw-600 fc-911E59 ff-InterSemiBold'><FormattedMessage id="Make Changes" /></p>
                                </div>
                            }
                        </div>
                    </Row>

                    {contentManagementList?.data?.data_en !== "" ?
                        <Row className="mt-2">
                            <Col md={10} lg={8} xl={6} xxl={4} >
                                <ReusableTabs tabs={tabModalData} image={true} defaultTab={1} currentTab={(val) => handleTabInsideDataChange(val)} />
                            </Col>

                            <Col xs={12} md={12} lg={12} className='mt-4'>
                                {tabInsideData === 1 ?
                                    <p>{contentManagementList?.data?.data_en}</p>
                                    :
                                    <p>{contentManagementList?.data?.data_ar}</p>
                                }
                            </Col>
                        </Row>
                        :
                        <Row className="p-0 m-0 mt-5 pt-5 mb-5 pb-5 flex flex-col text-center justify-center items-center">
                            <Col lg={6}>
                                <p className="m-0 fs-20 fw-400 fc-1E1E1E ff-InterRegular">
                                    <FormattedMessage id="You haven’t saved anything here yet. Click on the button below to add Terms & Conditions" />
                                </p>
                                <Button variant="contained" className='button mt-4' onClick={() => handleAddModel()}>
                                    <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-2" />
                                    <FormattedMessage id="Add Terms & Conditions" />
                                </Button>
                            </Col>
                        </Row>
                    }
                </Row>
            }

            {tab === 4 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 p-3">
                    <Row className="p-0 m-0 ">
                        <div className="flex items-center justify-between">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold p-0">
                                <FormattedMessage id="Frequently Asked Questions" />
                            </p>
                            {FaqDataList?.data?.length > 0 &&
                                <Stack direction="horizontal" gap={2} className="addSubjectButton flex justify-end" onClick={() => handleAddModel()}>
                                    <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                    <p className='mb-0 font-semibold'><FormattedMessage id="Add Question" /></p>
                                </Stack>
                            }
                        </div>
                    </Row>

                    {FaqDataList?.data?.length > 0 ?
                        <Row className="mt-2">
                            <Col md={10} lg={8} xl={6} xxl={4} >
                                <ReusableTabs tabs={tabModalData} image={true} defaultTab={1} currentTab={(val) => handleTabInsideDataChange(val)} />
                            </Col>
                            {tabInsideData === 1 ?
                                <Col lg={12} className="mt-4">
                                    {FaqDataList?.data.map((item, index) => (
                                        <AccordionStyle >
                                            <Accordion
                                                key={index}
                                                expanded={expanded === `panel${index}`}
                                            >
                                                <AccordionSummary
                                                    aria-controls={`panel${index}bh-content`}
                                                    id={`panel${index}bh-header`}
                                                >
                                                    <div className="me-2">
                                                        {expanded === `panel${index}` ? <img src={minusIcon} alt="icon" style={{ height: "24px", width: "24px" }} onClick={() => handleAccordionChange(`panel${index}`)} /> : <img src={plusIcon} alt="icon" style={{ height: "24px", width: "24px" }} onClick={() => handleAccordionChange(`panel${index}`)} />}
                                                    </div>

                                                    <Typography sx={{ flexGrow: 1 }}>{item?.question_en}</Typography>
                                                    <MoreHorizIcon aria-describedby={id} style={{ fill: '#3C1053', cursor: "pointer" }} onClick={(e) => handlePopoverClick(e, item)} />
                                                    <CustomPopover id={id} open={open} anchorEl={anchorEl} handleClose={handlePopoverClose} options={PopoverData} />
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>{item?.answer_en}</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </AccordionStyle>
                                    ))}
                                </Col>
                                :
                                <Col lg={12} className="mt-4">
                                    {FaqDataList?.data.map((item, index) => (
                                        <AccordionStyle >
                                            <Accordion
                                                key={index}
                                                expanded={expanded === `panel${index}`}
                                            >
                                                <AccordionSummary
                                                    aria-controls={`panel${index}bh-content`}
                                                    id={`panel${index}bh-header`}
                                                >
                                                    <div className="me-2">
                                                        {expanded === `panel${index}` ? <img src={minusIcon} alt="icon" style={{ height: "24px", width: "24px" }} onClick={() => handleAccordionChange(`panel${index}`)} /> : <img src={plusIcon} alt="icon" style={{ height: "24px", width: "24px" }} onClick={() => handleAccordionChange(`panel${index}`)} />}
                                                    </div>

                                                    <Typography sx={{ flexGrow: 1 }}>{item?.question_ar}</Typography>
                                                    <MoreHorizIcon aria-describedby={id} style={{ fill: '#3C1053', cursor: "pointer" }} onClick={(e) => handlePopoverClick(e, item)} />
                                                    <CustomPopover id={id} open={open} anchorEl={anchorEl} handleClose={handlePopoverClose} options={PopoverData} />
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>{item?.answer_ar}</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </AccordionStyle>
                                    ))}
                                </Col>
                            }
                        </Row>
                        :
                        <Row className="p-0 m-0 mt-5 pt-5 mb-5 pb-5 flex flex-col text-center justify-center items-center">
                            <Col lg={6}>
                                <p className="m-0 fs-20 fw-400 fc-1E1E1E ff-InterRegular">
                                    <FormattedMessage id="You haven’t saved anything here yet. Click on the button below to add a question" />
                                </p>
                                <Button variant="contained" className='button mt-4' onClick={() => handleAddModel()}>
                                    <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-2" />
                                    <FormattedMessage id="Add Question" />
                                </Button>
                            </Col>
                        </Row>
                    }
                </Row>
            }

            {/* Add Modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isAddModalActive} handleClose={() => handleCloseAddModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2">
                                        {editModel ? getModalEditHeading() : getModalHeading()}
                                    </h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseAddModel()} />
                                </div>
                            </Col>

                            <Col md={12} lg={12} xl={12} xxl={8} className="mt-3">
                                <ReusableTabs tabs={tabModalData} image={true} defaultTab={1} currentTab={(val) => handleModalTabChange(val)} />
                            </Col>


                            {tab === 4 ?
                                <>
                                    {modalTab === 1 ?
                                        <>
                                            <Col xs={12} md={12} className='mt-0'>
                                                <LabelWithInput
                                                    label={<FormattedMessage id="Question" />}
                                                    placeholder={intl.formatMessage({ id: "Question" })}
                                                    name="questionEn"
                                                    type="text"
                                                    value={formValue?.questionEn}
                                                    onChange={(e) => handleChange(e)}
                                                    errorText={isError && isError?.questionEn?.error && isError?.questionEn?.errorMessage}
                                                />
                                            </Col>
                                            <Col xs={12} md={12} className='mt-2'>
                                                <LabelWithInput
                                                    label={<FormattedMessage id="Answer" />}
                                                    placeholder={intl.formatMessage({ id: "Answer" })}
                                                    height="150"
                                                    multiline
                                                    name="answerEn"
                                                    value={formValue?.answerEn}
                                                    onChange={(e) => handleChange(e)}
                                                    errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
                                                />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col xs={12} md={12} className='mt-0'>
                                                <LabelWithInput
                                                    label={<FormattedMessage id="Question" />}
                                                    placeholder={intl.formatMessage({ id: "Question" })}
                                                    name="questionAr"
                                                    type="text"
                                                    value={formValue?.questionAr}
                                                    onChange={(e) => handleChange(e)}
                                                    errorText={isError && isError?.questionAr?.error && isError?.questionAr?.errorMessage}
                                                />
                                            </Col>
                                            <Col xs={12} md={12} className='mt-2'>
                                                <LabelWithInput
                                                    label={<FormattedMessage id="Answer" />}
                                                    placeholder={intl.formatMessage({ id: "Answer" })}
                                                    height="150"
                                                    multiline
                                                    name="answerAr"
                                                    value={formValue?.answerAr}
                                                    onChange={(e) => handleChange(e)}
                                                    errorText={isError && isError?.answerAr?.error && isError?.answerAr?.errorMessage}
                                                />
                                            </Col>
                                        </>
                                    }

                                    <Row className="flex flex-col items-end m-0 p-0">
                                        <Col xs={6} md={6} className='flex'>
                                            <Button variant="outlined" className='cancelButton mt-5 md:mt-2 w-100 me-3' onClick={() => handleCloseAddModel()}><FormattedMessage id="Cancel" /></Button>
                                            <Button variant="contained" className={`${(formValue?.questionEn !== "" && formValue?.questionAr !== "" && formValue?.answerEn !== "" && formValue?.answerAr !== "") ? 'button' : 'disableButton'} mt-5 md:mt-2 w-100`} onClick={() => editFAQ ? handleEditSubmitFAQ() : handleSaveFAQ()}><FormattedMessage id="save" /></Button>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    {modalTab === 1 ?
                                        <Col xs={12} md={12} className='mt-0'>
                                            <LabelWithInput
                                                placeholder={intl.formatMessage({ id: "Write Something here ...." })}
                                                marginTop="0"
                                                height="350"
                                                multiline
                                                name="messageEn"
                                                value={formValue?.messageEn}
                                                onChange={(e) => handleChange(e)}
                                                errorText={isError && isError?.messageEn?.error && isError?.messageEn?.errorMessage}
                                            />
                                        </Col>
                                        :
                                        <Col xs={12} md={12} className='mt-0'>
                                            <LabelWithInput
                                                placeholder={intl.formatMessage({ id: "أكتب شيئا هنا...." })}
                                                marginTop="0"
                                                height="350"
                                                multiline
                                                name="messageAr"
                                                value={formValue?.messageAr}
                                                onChange={(e) => handleChange(e)}
                                                errorText={isError && isError?.messageAr?.error && isError?.messageAr?.errorMessage}
                                            />
                                        </Col>

                                    }
                                    <Row className="flex flex-col items-end m-0 p-0">
                                        <Col xs={6} md={6} className='flex'>
                                            <Button variant="outlined" className='cancelButton mt-5 md:mt-2 w-100 me-3' onClick={() => handleCloseAddModel()}><FormattedMessage id="Cancel" /></Button>
                                            <Button variant="contained" className={`${(formValue?.messageAr !== "" && formValue?.messageEn !== "") ? 'button' : 'disableButton'} mt-5 md:mt-2 w-100`} onClick={() => handleSave()}><FormattedMessage id="save" /></Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Row>
                    </Box>
                }
            />
            {/* Add Modal  */}

        </Row >
    );
};

export default ContentManagementDetails;
