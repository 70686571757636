import {
    SEARCH_USER_BY_FIELD,
    SEARCH_USER_BY_FIELD_ERROR,
    SEARCH_USER_BY_FIELD_SUCCESS,
} from "./constant";

const searchUserByFieldReducer = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_USER_BY_FIELD:
            return { ...state, loading: true, error: '' };
        case SEARCH_USER_BY_FIELD_ERROR:
            return { ...state, type: SEARCH_USER_BY_FIELD_ERROR, loading: false, error: action.payload };
        case SEARCH_USER_BY_FIELD_SUCCESS:
            return { ...state, type: SEARCH_USER_BY_FIELD_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default searchUserByFieldReducer;