import { AUTH_OTP_VERIFICATION } from "./constant";

export const authOTPVerificationCallApi = (type, data, external = {}) => {
    switch (type) {
        case AUTH_OTP_VERIFICATION:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type
            }
    }
};