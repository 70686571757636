import {
    ADD_TEACHER_AVAILABILITY,
    ADD_TEACHER_AVAILABILITY_ERROR,
    ADD_TEACHER_AVAILABILITY_SUCCESS,
} from "./constant";

const addTeacherAvailabilityReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_TEACHER_AVAILABILITY:
            return { ...state, loading: true, error: '' };
        case ADD_TEACHER_AVAILABILITY_ERROR:
            return { ...state, type: ADD_TEACHER_AVAILABILITY_ERROR, loading: false, error: action.payload };
        case ADD_TEACHER_AVAILABILITY_SUCCESS:
            return { ...state, type: ADD_TEACHER_AVAILABILITY_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default addTeacherAvailabilityReducer;