import React from 'react';
import ErrorImage from 'assets/images/rob.png';

const Error404 = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div>
                <h1>
                    404
                </h1>
                <p>Error</p>
            </div>

            <div>
                <img width="150" alt="error404" src={ErrorImage} />
            </div>
        </div>
    );
}

export default Error404;
