import { Row, Col, Stack, Image } from "react-bootstrap";
import backIcon from "assets/images/backArrow.png";
import { useNavigate } from "react-router-dom";
import { getLandingURL } from "routes/AppUrls";
import { LocaleContext } from "routes/Router";
import { useContext, useEffect, useRef, useState } from "react";
import profileAvtarIcon from "assets/images/ProfileAvtarIcon.svg";
import CameraIcon from "assets/images/cameraIconUpload.svg";
import editIcon from "assets/images/edit.svg";
import callIcon from "assets/images/call.svg";
import mailIcon from "assets/images/mail.svg";
import { FormattedMessage, useIntl } from "react-intl";
import CommonModal from "components/common/Modal";
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import crossIcon from "assets/images/crossIcon.svg";
import deleteIcon from "assets/images/delete.svg";
import LabelWithInput from "components/common/Input/LabelWithInput";
import PhoneInput from 'react-phone-input-2';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useDispatch, useSelector } from "react-redux";
import { getProfileCallAPI } from "store/Dashboard/Profile/action";
import { GET_PROFILE } from "store/Dashboard/Profile/constant";
import moment from 'moment';
import { editProfileImageCallAPI } from "store/Dashboard/EditProfileImage/action";
import { EDIT_PROFILE_IMAGE } from "store/Dashboard/EditProfileImage/constant";
import { editProfileCallAPI } from "store/Dashboard/EditProfile/action";
import { EDIT_PROFILE } from "store/Dashboard/EditProfile/constant";

const ProfileScreen = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const intl = useIntl();
    const fileInputRef = useRef();
    const [isProfilePicModalActive, setIsProfilePicModalActive] = useState(false);
    const [files, setFiles] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isModalActive, setIsModalActive] = useState(false);
    const [countryCode, setCountryCode] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const phoneNumberInputRef = useRef(null);
    const [formValue, setformValue] = useState({
        fullName: "",
        phoneNumber: ""
    });
    const [formId, setformId] = useState({
        fullName: "",
        phoneNumber: ""
    });

    const profileData = useSelector(state => state?.getProfileReducer?.payload?.data);

    useEffect(() => {
        getProfileApi();
    }, []);

    const getProfileApi = (res) => {
        dispatch(getProfileCallAPI(GET_PROFILE, {}, { locale: locale, run: true, handleFunction: (res) => getProfileDetails(res), navigate: navigate }));
    };

    const getProfileDetails = (res) => {
        setFiles(res?.data?.data?.profile_image);
        setformValue((prev) => ({
            ...prev,
            ["fullName"]: res?.data?.data?.name,
            ["email"]: res?.data?.data?.email,
            ["phoneNumber"]: res?.data?.data?.phone

        }));

        setformId((prev) => ({
            ...prev,
            ['fullName']: res?.data?.data?.name,
            ['email']: res?.data?.data?.email,
            ['phoneNumber']: res?.data?.data?.phone
        }));
    }

    const handleClick = () => {
        navigate(getLandingURL(locale));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        if (file) {
            let Data = new FormData();
            Data.append("profile_image", file);
            dispatch(editProfileImageCallAPI(EDIT_PROFILE_IMAGE, Data, { run: true, handleFunction: (res) => getProfileApi(res), navigate: navigate, toast: true }));
            setIsProfilePicModalActive(false);
        }
    };

    const handleUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileDelete = () => {
        setFiles(null);
        setIsProfilePicModalActive(false);
    };

    const handleEditProfile = () => {
        setIsError(false);
        setIsModalActive(true);
        setformValue((prev) => ({
            ...prev,
            ["fullName"]: profileData?.data?.name,
            ["phoneNumber"]: profileData?.data?.phone
        }));
        setCountryCode(`+${profileData?.data?.country?.slug === 'qatar' ? '974' : ''}`);
    };

    const handleSubmit = () => {
        let body = {
            "name": formValue.fullName,
            "phone": formValue.phoneNumber,
        };
        dispatch(editProfileCallAPI(EDIT_PROFILE, body, { run: true, handleFunction: (res) => getProfileApi(res), navigate: navigate }));
        setIsModalActive(false);
    };

    const handleClose = () => {
        setIsModalActive(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
        setformId((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleCountryCodeChange = (value, country) => {
        setCountryCode(`+${country.dialCode}`);
        phoneNumberInputRef.current.focus();
    };

    const handlePhoneNumberChange = (e) => {
        const phoneNumberValue = e.target.value;
        setformValue((prev) => ({
            ...prev,
            [e.target.name]: phoneNumberValue
        }));

        setformId((prev) => ({
            ...prev,
            [e.target.name]: phoneNumberValue
        }));

        const fullPhoneNumber = `${countryCode}${phoneNumberValue}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

        if (parsedPhoneNumber) {
            setIsPhoneNumberValid(parsedPhoneNumber.isValid());
        } else {
            setIsPhoneNumberValid(false);
        }
    };


    return (
        <>
            <Row className="mb-4">
                <Stack direction="horizontal" gap={3} className="items-center ">
                    <img src={backIcon} alt="Back Icon" className="backArrow" style={{ cursor: "pointer" }} onClick={handleClick} />
                    <p className="p-0 m-0 fs-24 fw-500 ff-InterMedium fc-1E1E1E"><FormattedMessage id="Profile" /></p>
                </Stack>
            </Row>

            <Row className="page-content m-0 mt-3 mt-lg-0">
                <Col sm={12} xxl={1} xl={2} md={2} className="d-flex">
                    <div className="profilePic mx-auto mx-md-0">
                        <Image src={files ? files : profileAvtarIcon} alt={"icon"} className="userPic" />
                        <div className="cameraIcon">
                            <Image src={CameraIcon} alt={"icon"} style={{ cursor: "pointer", height: "32px", width: "32px" }} onClick={() => setIsProfilePicModalActive(true)} />
                        </div>
                    </div>
                    <div className="d-flex d-md-none justify-content-end">
                        <Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} onClick={() => handleEditProfile()} />
                    </div>
                </Col>
                <Col sm={12} xxl={5} md={6}>
                    <div className="ms-md-3">
                        <p className="profileName_Heading text-md-start text-center">{profileData?.data?.name}</p>
                        <Stack direction="horizontal" gap={2} className="justify-content-between justify-content-md-start">
                            <div className="d-flex">
                                <Image src={mailIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                <p className='mb-0 ProfileKey ms-2' ><FormattedMessage id="profile.EmailId:" /></p>
                            </div>
                            <div>
                                <p className='mb-0 ProfileValue' >{profileData?.data?.email}</p>
                            </div>
                        </Stack>
                        <Stack direction="horizontal" gap={2} className="mt-2 justify-content-between justify-content-md-start">
                            <div className="d-flex">
                                <Image src={callIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                <p className='mb-0 ProfileKey ms-2' ><FormattedMessage id="profile.PhoneNo:" /></p>
                            </div>
                            <div>
                                <p className='mb-0 ProfileValue' >{profileData?.data?.phone}</p>
                            </div>
                        </Stack>
                    </div>
                </Col>
                <Col sm={12} xxl={6} md={4} className="d-flex flex-column justify-content-between">
                    <Stack direction="horizontal" gap={2} className="d-none justify-end d-md-flex" onClick={() => handleEditProfile()}>
                        <Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} />
                        <p className='mb-0 buttonSelector' style={{ color: "#911E59", cursor: "pointer", }}><FormattedMessage id="EditProfile" /></p>
                    </Stack>
                    {/* flex justify-start md:justify-end */}
                    <Stack direction="horizontal" gap={2} className="flex justify-content-between justify-content-md-end mt-2 mt-md-0">
                        <p className='mb-0 ProfileKey' ><FormattedMessage id="profile.ProfileLastUpdated:" /></p>
                        <p className='mb-0 ProfileValue'>{moment(profileData?.data?.last_updated).format("YYYY-MM-DD")}</p>
                    </Stack>
                </Col>
            </Row>
            <Row className="page-content mt-3 m-0 mb-3 mb-lg-0">
                <div className="flex justify-between">
                    <p className="subjectTitle m-0 mb-2">
                    <FormattedMessage id="SubjectPreference" />
                    </p>
                </div>
                {profileData?.data?.subjects?.length > 0 && profileData?.data?.subjects.map((result, index) => (
                    <div className="mt-2">
                        <div className="subjectKey">
                            <Row>
                                <Col xs={6} sm={3} >
                                    <Stack>
                                        <p className='mb-0'><FormattedMessage id="GradeLevel" /></p>
                                        <p className='mb-0 subjectValue'>{result?.grade?.name}</p>
                                    </Stack>
                                </Col>
                                <Col xs={6} sm={3} className="mt-2 mt-sm-0">
                                    <Stack>
                                        <p className='mb-0'><FormattedMessage id="Material" /></p>
                                        <p className='mb-0 subjectValue'>{result?.teaching_material?.name}</p>
                                    </Stack>
                                </Col>
                                <Col xs={6} sm={3} className="mt-2 mt-sm-0">
                                    <Stack>
                                        <p className='mb-0'><FormattedMessage id="Language" /></p>
                                        <p className='mb-0 subjectValue'>{result?.language?.name}</p>
                                    </Stack>
                                </Col>
                                <Col xs={6} sm={3} className="mt-2 mt-sm-0">
                                    <Stack>
                                        <p className='mb-0'><FormattedMessage id="Subject" /></p>
                                        <p className='mb-0 subjectValue'>{result?.name}</p>
                                    </Stack>
                                </Col>
                            </Row>
                        </div>
                    </div>
                ))}
            </Row>

            {/* Profile Picture Modal  */}
            <CommonModal padding={'32px 48px'} width={'450px'} modalActive={isProfilePicModalActive} handleClose={() => setIsProfilePicModalActive(false)}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className='heading2'> <FormattedMessage id="profile.ProfileImage" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => setIsProfilePicModalActive(false)} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-2 flex justify-center'>
                                <Image src={files ? files : profileAvtarIcon} alt={"icon"} style={{ height: '200px', width: '200px', borderRadius: '50%' }} />
                            </Col>

                            <input
                                ref={fileInputRef}
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                disabled={false}
                                style={{ display: 'none' }} // Hide the file input
                                onChange={handleFileChange}
                            />

                            <Col xs={12} md={12} className="mt-5">
                                <div className="flex justify-between">
                                    <Button variant="outlined"
                                        className="profileButton"
                                        startIcon={<Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '20px', width: '20px' }} />}
                                        sx={{
                                            borderColor: '#911E59',
                                            color: '#911E59',
                                            '&:hover': {
                                                borderColor: '#911E59',
                                                backgroundColor: '#FFF2F8',
                                                color: '#911E59',
                                            },
                                        }}
                                        onClick={handleUpload}
                                    >
                                        <FormattedMessage id="profile.ChangeImage" />
                                    </Button>
                                    <Button variant="outlined"
                                        className="profileButton"
                                        startIcon={<Image src={deleteIcon} alt={"icon"} style={{ cursor: "pointer", height: '20px', width: '20px' }} />}
                                        sx={{
                                            borderColor: '#911E59',
                                            color: '#911E59',
                                            '&:hover': {
                                                borderColor: '#911E59',
                                                backgroundColor: '#FFF2F8',
                                                color: '#911E59',
                                            },
                                        }}
                                        onClick={handleFileDelete}
                                    >
                                        <FormattedMessage id="profile.RemoveImage" />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Profile Picture Modal  */}

            {/* Edit Profile modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isModalActive} handleClose={handleClose}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="EditProfile" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={handleClose} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-4'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.FullName" />}
                                    placeholder={intl.formatMessage({ id: "register.FullName" })}
                                    name="fullName"
                                    type="text"
                                    value={formValue.fullName}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.fullName.error && isError.fullName.errorMessage}
                                />
                            </Col>


                            <Col xs={12} md={12} className='mt-3'>
                                <div className="phone-number-container">
                                    <label className="phone-label"><FormattedMessage id="PhoneNumber" /></label>
                                    <div className="phone-input-wrapper">

                                        <div className="country-code-input">
                                            <PhoneInput
                                                country={'qa'}
                                                value={countryCode}
                                                onChange={handleCountryCodeChange}
                                                inputProps={{
                                                    name: 'countryCode',
                                                    required: true,
                                                }}
                                                placeholder="country"
                                                inputClass="custom-country-input"
                                                disableCountryCode={true}
                                                disableDropdown={false}
                                                onlyCountries={['qa', 'us', 'in', 'gb']}
                                            />
                                        </div>

                                        <div className="phone-number-input">
                                            <input
                                                type="number"
                                                name="phoneNumber"
                                                value={formValue?.phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                ref={phoneNumberInputRef}
                                                placeholder="e.g: 8521364783"
                                                className={`custom-phone-input ${!isPhoneNumberValid ? 'invalid' : ''}`}
                                            />
                                        </div>
                                    </div>

                                    {isError && isError.phoneNumber.error && isError.phoneNumber.errorMessage ?
                                        <p className="error-message">{isError.phoneNumber.errorMessage}</p>
                                        :
                                        !isPhoneNumberValid ?
                                            <p className="error-message"><FormattedMessage id="Invalidphonenumberforselectedcountrycode" /></p>
                                            :
                                            ""
                                    }

                                </div>
                            </Col>

                        </Row>

                        <Row className="mt-5 md:mt-2">
                            <Col>
                                <Button variant="contained" onClick={() => handleClose()} className='w-full' style={{ color: '#911E59', border: '1px solid #911E59', fontFamily: 'Inter_SemiBold', backgroundColor: 'transparent', borderRadius: '12px', height: '50px', fontWeight: '600', fontSize: '18px' }}><FormattedMessage id="Cancel" /></Button>
                            </Col>
                            <Col>
                                <Button variant="contained" onClick={() => handleSubmit()} className='button w-full'><FormattedMessage id="Save" /></Button>
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Edit Profile modal  */}
        </>
    )
}

export default ProfileScreen;