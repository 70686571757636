import React, { useContext } from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LocaleContext } from 'routes/Router';
import { Box } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const WrapperFormLabel = styled(FormLabel)`
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  font-family: Inter_Bold !important;
  color: #1E1E1E !important;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const WrapperErrorDiv = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.errorColor};
  line-height: 27px;
  font-weight: 600;
  font-family:Inter_SemiBold;
  margin-top: 5px;
`;


function DateTimePickerInput({ containerProps, ...props }) {
  const { locale } = useContext(LocaleContext);

  return (
    <FormControl fullWidth {...containerProps}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <WrapperFormLabel locale={locale} id="yes-no-input-radio-button">{props.label}</WrapperFormLabel>
      </Box>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {props?.datepicker &&
          <DatePicker
            label={props?.placeholder}
            value={props?.value}
            onChange={props?.onChange}
          />
        }

        {props?.timepicker &&
          <TimePicker
            label={props?.placeholder}
            value={props?.value}
            onChange={props?.onChange}
          />
        }
      </LocalizationProvider>

      {props.errorText && (
        <WrapperErrorDiv locale={locale}>
          <ErrorOutlineIcon /> {props.errorText}
        </WrapperErrorDiv>
      )}
    </FormControl>
  );
}

export default DateTimePickerInput;

