import {
    ADD_QUALIFICATION,
    ADD_QUALIFICATION_ERROR,
    ADD_QUALIFICATION_SUCCESS,
} from "./constant";

const addQualificationReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_QUALIFICATION:
            return { ...state, loading: true, error: '' };
        case ADD_QUALIFICATION_ERROR:
            return { ...state, type: ADD_QUALIFICATION_ERROR, loading: false, error: action.payload };
        case ADD_QUALIFICATION_SUCCESS:
            return { ...state, type: ADD_QUALIFICATION_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default addQualificationReducer;