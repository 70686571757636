import { takeLatest, put } from "redux-saga/effects";
import {
    AUTH_REGISTER,
    AUTH_REGISTER_SUCCESS,
    AUTH_REGISTER_ERROR
} from "./constant";
import url from "services/urls.json";
import { postMethod } from "services/apiServices";
import LocalStorageManager from "managers/LocalStorageManger";
import { ClearTokenRedirectLogin } from "utils/general";


function* postAuthRegister(action) {

    try {
        const response = yield postMethod(url.register, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: AUTH_REGISTER_SUCCESS, payload: response });
            LocalStorageManager.setItem('registerToken', response?.data?.data?.access_token);
            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: AUTH_REGISTER_ERROR, payload: response });
            if (response.status === 401) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
            if (action.external && action.external.error === true) {
                action.external.handleErrorFunction(response);
            }
        }

    } catch (error) {
        yield put({ type: AUTH_REGISTER_ERROR, payload: error });
    }
}

function* authRegisterSaga() {
    yield takeLatest(AUTH_REGISTER, postAuthRegister);
}

export default authRegisterSaga;