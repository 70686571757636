import React, { useContext } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import "components/feature/Navbar/navbar.scss";
import logoIcon from "assets/images/Navbar/logo.svg";
import langIconEn from "assets/images/languageEnIcon.svg";
import langIconAr from "assets/images/languageArIcon.svg";
import { LocaleContext } from 'routes/Router';
import 'assets/styles/common.scss';
import { AboutUsNav, ContactUsNav, getLandingURL, HomeNav, login, register, studentDashboard, studentProfile } from 'routes/AppUrls';
import { useNavigate } from 'react-router-dom';
import { getUserRole } from 'utils/general';
import { authLogoutCallAPI } from 'store/Auth/Logout/action';
import { AUTH_LOGOUT } from 'store/Auth/Logout/constant';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { Avatar } from '@mui/material';
import { FormattedMessage, useIntl } from "react-intl";
import { UserRoles } from 'store/BasicDetails/RegisterationDetails/actions';
import { USER_ROLES } from 'store/BasicDetails/RegisterationDetails/constant';

const CustomNavbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [conf, setConf] = ToastNotification();
    const intl = useIntl();
    const { locale, setLocale } = useContext(LocaleContext);

    const profileData = useSelector(state => state?.getProfileReducer?.payload?.data);

    const handleLogOut = () => {
        dispatch(authLogoutCallAPI(AUTH_LOGOUT, '', { run: true, handleFunction: (res) => handleRedirect(res), navigate: navigate, toast: true }));
    };

    const handleRedirect = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: intl.formatMessage({ id: "logoutsuccessfully!" }), variant: 'success' });
            navigate(getLandingURL(locale));
        } else {
            setConf({ msg: intl.formatMessage({ id: "Pleasetryagain." }), variant: 'error' });
        }
    };

    const LanguageSwitcher = (value) => {
        setLocale(value);
    };

    const handleBecomeTutor = () => {
        dispatch(UserRoles(USER_ROLES, 'teacher'));
        navigate(register(locale));
    };

    return (
        <Navbar expand="lg" className="navbar py-3 px-0 px-lg-5" >
            <Container fluid>
                <Navbar.Brand className='d-flex items-center'>
                    <img
                        src={logoIcon}
                        alt="Logo"
                        width="28"
                        height="25"
                        className={`d-inline-block align-top ${locale === "en" ? 'mr-2' : 'me-2'} items-center`}
                    />{' '}
                    <p className='p-0 m-0 fs-16 fw-400 ff-LexendDecaRegular fc-334155'><FormattedMessage id="MazadEdu" /></p>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbar-nav" />

                <Navbar.Collapse id="navbar-nav">
                    <Nav className={locale === "en" ? 'ml-auto' : 'mr-auto'}>
                        <Nav.Link onClick={() => getUserRole() === "Student" ? navigate(studentDashboard(locale)) : navigate(getLandingURL(locale))} className={`${(getLandingURL(locale) === window?.location?.pathname) || (studentDashboard(locale) === window?.location?.pathname) ? 'underline-text fs-20 fw-600 ff-InterSemiBold fc-334155' : 'fs-16 fw-400 ff-InterRegular fc-334155 '}`} ><FormattedMessage id="Home" /></Nav.Link>
                        <Nav.Link onClick={() => navigate(AboutUsNav(locale))} className={`${AboutUsNav(locale) === window?.location?.pathname ? 'underline-text fs-20 fw-600 ff-InterSemiBold fc-334155' : 'fs-16 fw-400 ff-InterRegular fc-334155 '}`} ><FormattedMessage id="AboutUs" /></Nav.Link>
                        <Nav.Link onClick={() => navigate(ContactUsNav(locale))} className={`${ContactUsNav(locale) === window?.location?.pathname ? 'underline-text fs-20 fw-600 ff-InterSemiBold fc-334155' : 'fs-16 fw-400 ff-InterRegular fc-334155 '}`} ><FormattedMessage id="Contact" /></Nav.Link>
                    </Nav>

                    <div className={`d-flex ${locale === "en" ? 'ml-auto' : 'mr-auto'} items-center`}>
                        <Nav.Link className="d-flex align-items-center" onClick={() => locale === "en" ? LanguageSwitcher("ar") : LanguageSwitcher("en")}>
                            <img
                                src={locale === "en" ? langIconAr : langIconEn}
                                alt="Language Icon"
                                width="40"
                                height="40"
                                className={locale === "en" ? 'mr-5' : 'ml-5'}
                            />{' '}
                        </Nav.Link>

                        {getUserRole() === "Student" ?
                            <>
                                <Avatar className={locale === "en" ? 'me-3' : 'ms-3'} onClick={() => navigate(studentProfile(locale))} style={{ cursor: "pointer", backgroundColor: '#D8E3FF', color: '#4573B9' }}>{profileData?.data?.name?.charAt(0)}</Avatar>
                                <Button variant="contained" className='button' onClick={() => handleLogOut()}><FormattedMessage id="Logout" /></Button>
                            </>
                            :
                            <>
                                <Button variant="outlined" className={`cancelButton ${locale === "en" ? 'me-2' : 'ms-2'}`} onClick={() => handleBecomeTutor()}>
                                    <FormattedMessage id="BecomeaTutor" />
                                </Button>
                                <Button variant="contained" className='button' onClick={() => navigate(login(locale))}><FormattedMessage id="Login" /></Button>
                            </>
                        }
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
};

export default CustomNavbar;
