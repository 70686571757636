import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Tabs, TabsList as BaseTabsList, TabPanel as BaseTabPanel, Tab as BaseTab, tabClasses } from '@mui/base';
import { buttonClasses } from '@mui/base/Button';

const Tab = styled(BaseTab)`
  font-family: 'Inter_Medium';
  color: #A2A2A2;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  line-height: 1.5;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items:center;

  &:hover {
    background-color: #911E59;
    color: #FFFFFF;
  }

  &.${tabClasses.selected} {
    background-color: #911E59;
    color: #FFFFFF;
    align-items:center;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-family: Inter_Regular;
  font-size: 0.875rem;
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
    min-width: 100%;
    background-color: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.4)' : 'rgba(0,0,0, 0.2)'};

    @media (max-width: 614px) {
      flex-direction: column; /* Stack tabs vertically on small screens */
      padding: 10px;
    }

    @media (min-width: 614px) {
      flex-direction: row; /* Horizontal on large screens */
    }
  `,
);

const ReusableTabs = ({ tabs, defaultTab = 1, currentTab, image = false }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const handleChange = (val) => {
    setSelectedTab(val);
    currentTab(val);
  };

  return (
    <Tabs value={selectedTab} onChange={(e, newValue) => handleChange(newValue)} className='p-0'>
      <TabsList>
        {tabs.map((tab, index) => (
          <Tab key={index} value={index + 1}>
            {image &&
              <img src={selectedTab === index + 1 ? tab.colorIcon : tab.icon} alt='icon' className='me-2' />
            }
            {tab.label}
          </Tab>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default ReusableTabs;

