import React, { } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LandingScreen from "components/feature/LandingScreen";

const LandingPage = () => {

    return (
        <Container fluid>
            <Row>
                <Col md={12} style={{ padding: 0, margin: 0 }}>
                    <LandingScreen />
                </Col>
            </Row >
        </Container >
    )
};

export default LandingPage;