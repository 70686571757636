import {
    CITY_DROPDOWN,
} from "./constant";

export const cityDropDownCallAPI = (type, data, external = {}) => {
    switch (type) {
        case CITY_DROPDOWN:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};