import {
    DELETE_MANAGE_PLAN,
    DELETE_MANAGE_PLAN_ERROR,
    DELETE_MANAGE_PLAN_SUCCESS,
} from "./constant";

const deleteManagePlanReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_MANAGE_PLAN:
            return { ...state, loading: true, error: '' };
        case DELETE_MANAGE_PLAN_ERROR:
            return { ...state, type: DELETE_MANAGE_PLAN_ERROR, loading: false, error: action.payload };
        case DELETE_MANAGE_PLAN_SUCCESS:
            return { ...state, type: DELETE_MANAGE_PLAN_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default deleteManagePlanReducer;