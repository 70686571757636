import { takeLatest, put } from "redux-saga/effects";
import {
    GET_AVAILABLE_TEACHER_LIST,
    GET_AVAILABLE_TEACHER_LIST_SUCCESS,
    GET_AVAILABLE_TEACHER_LIST_ERROR,
} from "./constant";
import url from "services/urls.json";
import { getMethodWithToken } from "services/apiServices";
import { ClearTokenRedirectLogin, getUserRole } from "utils/general";

function* getAvailableTeacherListApi(action) {

    try {
        let response;
        if (getUserRole() === 'Administrator') {
            response = yield getMethodWithToken(url.getAdminAvaialbeTeacher + "?subject_id=" + action?.data?.body?.subjectId + "&grade_id=" + action?.data?.body?.gradeId + "&teaching_material_id=" + action?.data?.body?.teacherMaterialId + "&new_teacher_id=" + action?.external?.id);
        } else {
            response = yield getMethodWithToken(url.getAvailableTeacherList + "?subject_id=" + action?.data?.body?.subjectId + "&grade_id=" + action?.data?.body?.gradeId + "&teaching_material_id=" + action?.data?.body?.teacherMaterialId);
        }

        if (response.status === 200 || response.status === 201) {
            yield put({ type: GET_AVAILABLE_TEACHER_LIST_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: GET_AVAILABLE_TEACHER_LIST_ERROR, payload: response });
            if (response.status === 401) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response);
            }
        }

    } catch (error) {
        yield put({ type: GET_AVAILABLE_TEACHER_LIST_ERROR, payload: error });
    }
}

function* getAvailableTeacherListSaga() {
    yield takeLatest(GET_AVAILABLE_TEACHER_LIST, getAvailableTeacherListApi);
}

export default getAvailableTeacherListSaga;