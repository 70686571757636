import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Stack, Image } from "react-bootstrap";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";
import ToastNotification from "components/common/Toast";
import { supportListCallAPI } from "store/AdminDashboard/SupportList/action";
import { SUPPORT_LIST } from "store/AdminDashboard/SupportList/constant";
import Pagination from '@mui/material/Pagination';
import CommonModal from "components/common/Modal";
import crossIcon from "assets/images/crossIcon.svg";
import { Box } from "@mui/system";

const SupportDetails = () => {

    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const [conf, setConf] = ToastNotification();
    const { locale } = useContext(LocaleContext);

    const SupportListData = useSelector(state => state?.supportListReducer?.payload?.data);
    const [page, setPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page
    const [totalPages, setTotalPages] = useState(Math.ceil((SupportListData?.meta?.total || 0) / rowsPerPage));
    const [isModalActive, setIsModalActive] = useState(false);
    const [moreText, setMoreText] = useState("");

    useEffect(() => {
        getSupportList();
    }, [page, rowsPerPage]);

    const getSupportList = () => {
        let params = { page, limit: rowsPerPage };
        dispatch(supportListCallAPI(SUPPORT_LIST, { params }, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
    };

    const handleSuccess = (res) => {
        if (res?.status === 200) {
            setTotalPages(Math.ceil((res?.data?.meta?.total || 0) / rowsPerPage));
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const TextWithReadMore = ({ text, limit = 100 }) => {
        const isTextLong = text.length > limit;

        return (
            <div className="d-flex align-items-center m-0 p-0">
                <p className="m-0 p-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular truncate">
                    {!isTextLong ? text : `${text.slice(0, limit)}...`}
                </p>
                {isTextLong && (
                    <button onClick={() => (setIsModalActive(true), setMoreText(text))} className="fs-14 fw-500 fc-911E59 ff-InterMedium underline text-sm  ms-2">
                        <FormattedMessage id="Read More" />
                    </button>
                )}
            </div>
        );
    };

    const handleClose = () => {
        setIsModalActive(false);
    };

    return (
        <Row className="mt-5 mt-lg-0 pb-0 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="Support" /></h5>
            <Row className="page-parentstudent-contents mt-3 m-0 ">
                <Row className="parent-student-list ps-3 bc-F8F8F8 p-0 m-0">
                    <Col sm={6} md={2} className="align-center text-start">
                        <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="First Name" /></p>
                    </Col>
                    <Col sm={6} md={2} className="align-center text-start">
                        <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Last Name" /></p>
                    </Col>
                    <Col sm={6} md={2} className="align-center text-start">
                        <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Email Address" /></p>
                    </Col>
                    <Col sm={6} md={2} className="align-center text-center">
                        <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Contact Number" /></p>
                    </Col>
                    <Col sm={6} md={2} className="align-center text-center">
                        <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Date Received" /></p>
                    </Col>
                    <Col sm={6} md={2} className="align-center text-start">
                        <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Message" /></p>
                    </Col>
                </Row>

                {SupportListData?.data?.length > 0 && SupportListData?.data.map((item, index) =>
                    <Row className="parent-student-list ps-3 p-0 m-0">
                        <Col sm={6} md={2} className="align-center text-start">
                            <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">{item?.firstname}</p>
                        </Col>
                        <Col sm={6} md={2} className="align-center text-start">
                            <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">{item?.lastname}</p>
                        </Col>
                        <Col sm={6} md={2} className="align-center text-start">
                            <p className="m-0 fs-14 fw-400 fc-375D84 ff-InterRegular">{item?.email}</p>
                        </Col>
                        <Col sm={6} md={2} className="align-center text-center">
                            <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">{item?.isd_code} {item?.phone}</p>
                        </Col>
                        <Col sm={6} md={2} className="align-center text-center">
                            <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">{item?.date_added}</p>
                        </Col>
                        <Col sm={6} md={2} className="align-center text-start">
                            <TextWithReadMore text={item?.message} limit={18} />
                        </Col>
                    </Row>
                )}
            </Row>

            <Row className="mt-3 m-0 p-0">
                <Stack spacing={2} className="p-0">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        className="d-flex justify-end"
                    />
                </Stack>
            </Row>

            {/* Read More modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isModalActive} handleClose={handleClose}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="Message" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={handleClose} />
                                </div>
                            </Col>
                            <Col xs={12} md={12} className='mt-4'>
                                {moreText}
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Read More modal  */}
        </Row>
    );
};

export default SupportDetails;
