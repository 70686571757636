import React, { useContext } from "react";
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import moment from 'moment';

const WrapperTextField = styled(TextField)`
	width: 100%;
	margin-top: ${(props) => props.marginLabelTop ? `${props.marginLabelTop}px` : "20px !important"};

	label{
		width:100%
	}
	.MuiInputLabel-root {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		color: #1E1E1E;
		font-family: Inter_SemiBold;
		transform: none;
		top: -5px;
	}

	.MuiInputLabel-root.Mui-focused  {
		color: #1E1E1E;
	}

	.MuiInput-root {
		// border: 1px solid #DDDDDD;
		border:1px solid ${(props) => props.errorText ? '#EE3232' : '#DDDDDD'};
		border-radius: 8px;
		height: 50px !important;
		padding: 15px;
		color: ${(props) => props.theme.lightGray};
		margin-top: ${(props) => props.marginTop ? props.marginTop + "px" : '25px'};
		font-family: Inter_Regular;
		font-size: 16px;
		font-weight: 400;
		line-height: 16px;

		&:before {
			border: none !important;
		}
		&:after {
			border: none !important;
		}

		.MuiSelect-select {
			background: none !important;
		}

		.MuiSelect-nativeInput {
			padding: ${(props) => props.inputPadding};
			color: #1E1E1E;
			opacity: 1;
			border: none;
			font-weight: 400;
			height:46px !important;
			bottom: 1px;
			font-family: Inter_Regular;
			line-height: 16px;
			border-radius: 8px;

			&::placeholder {
				color: #A2A2A2;
				font-size: 16px;
				font-weight: 400;
			}
		}

		.MuiSvgIcon-root {
			position: ${(props) => props.locale === 'en' ? 'unset' : 'relative'};
   		    margin-left: ${(props) => props.locale === 'en' ? '' : '16px'};
    		margin-top: ${(props) => props.locale === 'en' ? '' : '-16px'};
			border: 1px solid ${(props) => props.theme.darkArrow};
		    border-width: 0 2px 2px 0;
		    display: inline-block;
		    transform: rotate(45deg);
		    -webkit-transform: rotate(45deg);
		    padding: 3px;
		    font-size: 16px;
		    right: 15px;
    		top: calc(50% - 0.1em);
		    color: #1E1E1E;
		    // width: 12px;
    		// height: 12px;
			width:7.4px;
			height:7.4px;
		}

		.MuiSvgIcon-root.MuiSelect-iconOpen {
			transform: rotate(-135deg);
  			-webkit-transform: rotate(-135deg);
  			top: calc(50% - 0em);
		}
	}

	.MuiInput-root.Mui-focused {
		border: 1px solid #911E59;
		color: red;

		.MuiInput-input {
			color: green;
		}
		
		&:before {
			border: none !important;
		}
		&:after {
			border: none !important;
			direction: rtl;
		}
	}
`;

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: #FFF2F8 !important;
	color:#911E59 !important;
  }
`;

const WrapperErrorDiv = styled.div`
	font-size: 16px;
	color: #EE3232;
	line-height: 24px;
	font-weight: 400;
	font-family: Inter_Regular;
	margin-top: 5px;
	position:absolute;
	bottom:-30px;
`;

function SelectInput({ containerProps, ...props }) {

	return (
		<FormControl fullWidth>
			<WrapperTextField
				select
				id="standard-required"
				variant="standard"
				fullWidth={true}
				InputLabelProps={{
					shrink: true
				}}
				{...props}
			>
				{props?.data?.length > 0 && props?.data.map((k, i) => (
					<StyledMenuItem onClick={(e) => props?.getValue && props?.getValue(e, k)} value={k}>{k?.name || (k?.date && moment(k?.date).format('Do MMMM, YYYY'))}</StyledMenuItem>

				))}
			</WrapperTextField>

			{props.errorText &&
				<WrapperErrorDiv>
					{/* <ErrorOutlineIcon /> {props.errorText} */}
					{props.errorText}
				</WrapperErrorDiv>
			}
		</FormControl>
	);
};

SelectInput.defaultProps = {
	height: 50,
	inputPadding: "14px"
};

export default SelectInput;