import {
    ADD_SUBJECT_GRADE_ADMIN,
    ADD_SUBJECT_GRADE_ADMIN_ERROR,
    ADD_SUBJECT_GRADE_ADMIN_SUCCESS,
} from "./constant";

const addSubjectGradeReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_SUBJECT_GRADE_ADMIN:
            return { ...state, loading: true, error: '' };
        case ADD_SUBJECT_GRADE_ADMIN_ERROR:
            return { ...state, type: ADD_SUBJECT_GRADE_ADMIN_ERROR, loading: false, error: action.payload };
        case ADD_SUBJECT_GRADE_ADMIN_SUCCESS:
            return { ...state, type: ADD_SUBJECT_GRADE_ADMIN_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default addSubjectGradeReducer;