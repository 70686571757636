import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Welcome from "components/layout/welcomeLayout";
import { useNavigate } from "react-router-dom";
import LoginScreen from "components/feature/Teacher/LoginScreen";
import { LocaleContext } from "routes/Router";
import { getLandingURL } from "routes/AppUrls";
import { FormattedMessage } from "react-intl";

const Login = () => {
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);

    const handleRedirect = () => {
        navigate(getLandingURL(locale));
    };

    return (
        <Container fluid>
            <Row>
                <Col md={6} style={{ padding: 0, margin: 0 }}>
                    <Welcome onClick={handleRedirect} heading={<FormattedMessage id="login.WelcomeBack" />} />
                </Col>
                <Col md={6} sm={12} className="right_Container">
                    <Row>
                        <Col xxl={1}>
                            {/* Empty space on the left */}
                        </Col>
                        <Col xxl={10}>
                            <LoginScreen />
                        </Col>
                        <Col xxl={1}>
                            {/* Empty space on the right */}
                        </Col>
                    </Row>
                </Col>
            </Row >
        </Container >
    )
};

export default Login;