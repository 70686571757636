import { CONTACT_US } from "./constant";

export const contactUsCallApi = (type, data, external = {}) => {
    switch (type) {
        case CONTACT_US:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type
            }
    }
};