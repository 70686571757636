import React, { useContext } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import frame from "assets/images/welcomeFrame.png";
import frameAr from "assets/images/welcomeFrameAr.svg";
import backIcon from "assets/images/backArrow.png";
import styled from 'styled-components';
import "assets/styles/common.scss";
import { FormattedMessage } from "react-intl";
import { LocaleContext } from "routes/Router";

const WrapperDiv = styled.div`
    background: ${(props) => props?.locale === "en" ? `url(${frame}) no-repeat center center` : `url(${frameAr}) no-repeat center center`};
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const WelcomeTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const BackIconContainer = styled.div`
    .backArrow{
        cursor:pointer;
    }
`;

const Welcome = ({ onClick, heading }) => {
    const { locale } = useContext(LocaleContext);

    const handleClick = () => {
        onClick();
    };

    return (
        <WrapperDiv locale={locale}>
            <Row>
                <Col xs={12} md={12} lg={12} >
                    <BackIconContainer className={`m-20 md:mt-80 md:mr-0 md:mb-190 ${locale === "en" ? "xl:ml-110" : "xl:mr-110"}`}>
                        <Stack direction="horizontal" gap={2} className={locale === "en" ? "ltr" : "ltr justify-end "}>
                            <img src={backIcon} alt="Back Icon" className="backArrow" onClick={handleClick} />
                            <p className="mb-0 layout_title"><FormattedMessage id="MazadEdu" /></p>
                        </Stack>
                    </BackIconContainer>
                    <WelcomeTextContainer className={`d-none d-md-block m-20 ${locale === "en" ? "xl:ml-140" : "xl:mr-140"}`}>
                        <h2 className='layout'>{heading ? heading : <FormattedMessage id="Welcome" />}</h2>
                        {/* <p className="layout_text"><FormattedMessage id="WelcomeSubText" /></p> */}
                    </WelcomeTextContainer>
                </Col>
            </Row>
        </WrapperDiv>
    )
};

export default Welcome;
