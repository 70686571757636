import {
    EDIT_QUALIFICATION,
    EDIT_QUALIFICATION_ERROR,
    EDIT_QUALIFICATION_SUCCESS,
} from "./constant";

const editQualificationReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_QUALIFICATION:
            return { ...state, loading: true, error: '' };
        case EDIT_QUALIFICATION_ERROR:
            return { ...state, type: EDIT_QUALIFICATION_ERROR, loading: false, error: action.payload };
        case EDIT_QUALIFICATION_SUCCESS:
            return { ...state, type: EDIT_QUALIFICATION_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default editQualificationReducer;