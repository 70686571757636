import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import CustomNavbar from 'components/feature/Navbar';
import SearchFilterScreen from 'components/feature/Student/serachFilterScreen';

const SearchFilter = () => {


    return (
        <Container fluid>
            <Row>
                <CustomNavbar />
            </Row>
            <Row>
                <SearchFilterScreen />
            </Row>
        </Container>
    );
};

export default SearchFilter;