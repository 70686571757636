import { EmailValidation, PhoneNumberValidation } from "utils/helper";
import { FormattedMessage } from "react-intl";

const pushNotificationValidation = value => {

    const formError = {
        submit: true,
        country: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        state: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        city: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        deviceType: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        userType: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        grades: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        subjects: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        title: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        description: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        }
    };

    const emptyKeys = [];
    for (let key in value) {
        emptyKeys.push(key);
    };


    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }
    return formError;
}

export default pushNotificationValidation;