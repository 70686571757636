import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'assets/styles/common.scss';
import ContentManagementDetails from 'components/feature/Admin/ContentManagementDetails';

const ContentManagement = () => {

    return (
        <Container fluid className='dashboard_container'>
            <Row>
                <Col lg={2}>
                    {/* Empty space on the left */}
                </Col>
                <Col lg={8} className="mt-lg-5 mb-lg-3">
                    <ContentManagementDetails />
                </Col>
                <Col lg={2}>
                    {/* Empty space on the right */}
                </Col>
            </Row>
        </Container>
    );
}

export default ContentManagement;