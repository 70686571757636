import {
    EDIT_PROFILE_IMAGE,
    EDIT_PROFILE_IMAGE_ERROR,
    EDIT_PROFILE_IMAGE_SUCCESS,
} from "./constant";

const editProfileImageReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_PROFILE_IMAGE:
            return { ...state, loading: true, error: '' };
        case EDIT_PROFILE_IMAGE_ERROR:
            return { ...state, type: EDIT_PROFILE_IMAGE_ERROR, loading: false, error: action.payload };
        case EDIT_PROFILE_IMAGE_SUCCESS:
            return { ...state, type: EDIT_PROFILE_IMAGE_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default editProfileImageReducer;