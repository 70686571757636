import {
    FEATURED_LIST,
} from "./constant";

export const featuredListCallAPI = (type, data, external = {}) => {
    switch (type) {
        case FEATURED_LIST:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};