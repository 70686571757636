import {
    PACKAGE_PLAN,
    PACKAGE_PLAN_ERROR,
    PACKAGE_PLAN_SUCCESS,
} from "./constant";

const packagePlanReducer = (state = {}, action) => {
    switch (action.type) {
        case PACKAGE_PLAN:
            return { ...state, loading: true, error: '' };
        case PACKAGE_PLAN_ERROR:
            return { ...state, type: PACKAGE_PLAN_ERROR, loading: false, error: action.payload };
        case PACKAGE_PLAN_SUCCESS:
            return { ...state, type: PACKAGE_PLAN_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default packagePlanReducer;