import {
    PLAN_TYPE_DROPDOWN,
} from "./constant";

export const planTypeDropdownCallAPI = (type, data, external = {}) => {
    switch (type) {
        case PLAN_TYPE_DROPDOWN:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};