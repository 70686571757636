import { AUTH_RESET_PASSWORD } from "./constant";

export const authResetPasswordCallApi = (type, data, external = {}) => {
    switch (type) {
        case AUTH_RESET_PASSWORD:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type
            }
    }
};