import {
    STATE_DROPDOWN,
} from "./constant";

export const stateDropDownCallAPI = (type, data, external = {}) => {
    switch (type) {
        case STATE_DROPDOWN:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};