import React, { useContext } from 'react'
import styled from 'styled-components'
import LanguageIcon from '@mui/icons-material/Language';
import LanguageEnIcon from 'assets/images/languageEnIcon.svg';
import LanguageArIcon from 'assets/images/languageArIcon.svg';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
    height:40px;
    width:40px;
    border-radius:50%;
    position:absolute;
    right:20px;
    top:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:999;
`
const LangSwitcher = () => {

  const { locale, setLocale } = useContext(LocaleContext);

  const LanguageSwitcher = (value) => {
    setLocale(value);
  };

  return (
    <WrapperDiv>
      {locale === 'en' ?
        <img src={LanguageArIcon} alt="icon" style={{cursor:'pointer'}} onClick={() => LanguageSwitcher("ar")} />
        // <LanguageIcon style={{ color: 'white', cursor: "pointer" }} onClick={() => LanguageSwitcher("ar")} />
        :
        // <LanguageIcon style={{ color: 'white', cursor: "pointer" }} onClick={() => LanguageSwitcher("en")} />
        <img src={LanguageEnIcon} alt="icon" style={{cursor:'pointer'}} onClick={() => LanguageSwitcher("en")} />
      }
    </WrapperDiv>
  )
}

export default LangSwitcher
