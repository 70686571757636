import { FormattedMessage } from "react-intl";

const CheckValidation = value => {

    const formError = {
        submit: true,
        bio: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
    };

    const emptyKeys = [
        'bio',
    ];


    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }
    return formError;
}

export default CheckValidation;