import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'assets/styles/common.scss';
import ProfilePage from 'components/feature/Teacher/Profile';

const Profile = () => {
    return (
        <Container fluid className='dashboard_container'>
            <Row className=''>
                <Col md={0} lg={2}>
                    {/* Empty space on the left */}
                </Col>
                <Col md={12} lg={9} className="mt-lg-5 mb-lg-5">
                    <ProfilePage />
                </Col>
                <Col  md={0} lg={1}>
                    {/* Empty space on the right */}
                </Col>
            </Row>
        </Container>
    );
}

export default Profile;