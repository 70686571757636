import React from 'react';
import { Row, Container } from 'react-bootstrap';
import CustomNavbar from 'components/feature/Navbar';
import Footer from 'components/feature/Footer';
import AboutUsScreen from 'components/feature/Student/AboutUsScreen';
import 'assets/styles/common.scss';

const AboutUs = () => {

    return (
        <Container fluid>
            <Row>
                <CustomNavbar />
            </Row>
            <Row className='bc-F7F5EF'>
                <AboutUsScreen />
            </Row>
            <Row className='bc-F7F5EF'>
                <Footer />
            </Row>
        </Container>
    );
};

export default AboutUs;