import React, { useContext, useEffect, useState, useRef } from "react";
import { Row, Col, Image } from "react-bootstrap";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/ParentStudentDetails/parentstudent.scss";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SwitchInput from "components/common/Input/SwitchInput";
import { getAllStudentsCallAPI } from "store/AdminDashboard/GetAllStudentLists/action";
import { GET_ALL_STUDENTS_LIST } from "store/AdminDashboard/GetAllStudentLists/constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { LocaleContext } from "routes/Router";
import CustomPopover from "components/common/CutomPopover";
import { UPDATE_USER_STATUS } from "store/AdminDashboard/UpdateUserStatus/constant";
import { updateUserStatusCallAPI } from "store/AdminDashboard/UpdateUserStatus/action";
import ToastNotification from "components/common/Toast";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import LabelWithInput from "components/common/Input/LabelWithInput";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CommonModal from "components/common/Modal";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import crossIcon from "assets/images/crossIcon.svg";
import { UPDATE_USER_DATA } from "store/AdminDashboard/UpdateUserData/constant";
import { updateUserDataCallAPI } from "store/AdminDashboard/UpdateUserData/action";
import CheckValidation from "components/feature/Teacher/PersonalDetails/validation";
import InputAdornment from '@mui/material/InputAdornment';
import searchIcon from "assets/images/Navbar/search.svg";
import { searchUserByFieldCallAPI } from "store/AdminDashboard/SearchUserByFiled/action";
import { SEARCH_USER_BY_FIELD } from "store/AdminDashboard/SearchUserByFiled/constant";

const ParentStudentDetails = () => {

    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [conf, setConf] = ToastNotification();
    const { locale } = useContext(LocaleContext);
    const AllStudentList = useSelector(state => state?.getAllStudentsListReducer?.payload?.data);

    const [page, setPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page
    const [isError, setIsError] = useState(false);
    const [isModalActive, setIsModalActive] = useState(false);
    const [viewData, setViewData] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [allStudentList, setAllStudentList] = useState(AllStudentList);
    const [totalPages, setTotalPages] = useState(Math.ceil((AllStudentList?.meta?.total || 0) / rowsPerPage));

    const [formValue, setformValue] = useState({
        fullName: "",
        phoneNumber: ""
    });

    const handlePopoverClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setViewData(item);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const PopoverData = [
        {
            label: <FormattedMessage id="Edit" />,
            icon: <EditOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => handleEditModel(),
        }
    ];

    useEffect(() => {
        if (searchValue !== "") {
            handleSearch(searchValue, page, rowsPerPage);
        } else {
            getAllStudentLists(page, rowsPerPage);
        }
    }, [page, rowsPerPage]);

    const getAllStudentLists = (page, rowsPerPage) => {
        let params = { page, limit: rowsPerPage };
        dispatch(getAllStudentsCallAPI(GET_ALL_STUDENTS_LIST, { params }, { run: true, handleFunction: (res) => handleGetValue(res), navigate: navigate }));
    };

    const handleGetValue = (res) => {
        setTotalPages(Math.ceil((res?.data?.meta?.total || 0) / rowsPerPage));
        setAllStudentList(res?.data);
    };

    const handleSwitchChange = (event, item) => {
        let body = {
            "user_status": !item?.userstatus
        };
        dispatch(updateUserStatusCallAPI(UPDATE_USER_STATUS, body, { statusId: item?.id, run: true, navigate: navigate, handleFunction: (res) => handleSuccess(res) }));
    };

    const handleSuccess = (res) => {
        setConf({ msg: res?.data?.data, variant: 'success' });
        getAllStudentLists(page, rowsPerPage);
    };

    ////////// Pagination ////////////////////


    const handlePageChange = (event, value) => {
        setPage(value);
    };

    ////////// Pagination ////////////////////

    ///////////Edit User/////////////////////

    const handleClose = () => {
        setIsModalActive(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleEditModel = () => {
        setIsModalActive(true);
        setformValue((prev) => ({
            ...prev,
            ["fullName"]: viewData.name,
            ["phoneNumber"]: viewData?.phone
        }));
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit && isPhoneNumberValid) {
            let body = {
                "name": formValue.fullName,
                "phone": formValue.phoneNumber
            };
            dispatch(updateUserDataCallAPI(UPDATE_USER_DATA, body, { statusId: viewData?.id, run: true, handleFunction: () => getAllStudentLists(page, rowsPerPage), navigate: navigate }));
            setIsModalActive(false);
        }
    };

    ////////////////////////////////countryCode///////////////////////////////

    const [countryCode, setCountryCode] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const phoneNumberInputRef = useRef(null);

    const handleCountryCodeChange = (value, country) => {
        setCountryCode(`+${country.dialCode}`);
        phoneNumberInputRef.current.focus();
    };

    const handlePhoneNumberChange = (e) => {
        const phoneNumberValue = e.target.value;
        setformValue((prev) => ({
            ...prev,
            [e.target.name]: phoneNumberValue
        }));

        const fullPhoneNumber = `${countryCode}${phoneNumberValue}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

        if (parsedPhoneNumber) {
            setIsPhoneNumberValid(parsedPhoneNumber.isValid());
        } else {
            setIsPhoneNumberValid(false);
        }
    };
    ////////////////////////////////countryCode///////////////////////////////

    ///////////Edit User/////////////////////

    const handleSearch = (searchValue, page = 1, rowsPerPage = 10) => {
        setSearchValue(searchValue);
        let body = {
            "searchtext": searchValue
        }
        let params = { page, limit: rowsPerPage, student: true };
        dispatch(searchUserByFieldCallAPI(SEARCH_USER_BY_FIELD, body, { params: params, run: true, handleFunction: (res) => handleSearchSuccess(res, searchValue), navigate: navigate }));
    };

    const handleSearchSuccess = (res, value) => {
        if (value !== "") {
            setAllStudentList(res?.data);
            setTotalPages(Math.ceil((res?.data?.meta?.total || 0) / rowsPerPage));
        } else {
            setAllStudentList(AllStudentList);
            setTotalPages(Math.ceil((AllStudentList?.meta?.total || 0) / rowsPerPage));
        }
    };


    return (
        <Row className="mt-5 mt-lg-0 pb-0 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="Parents/Students" /></h5>
            <Row className="page-parentstudent-contents mt-3 m-0 ">
                <Row className="m-0 p-0">
                    <Col md={6} className="p-0">
                        <LabelWithInput
                            placeholder={intl.formatMessage({ id: "Searchbyusername,contactoremail..." })}
                            name="search"
                            type="text"
                            marginTop="0"
                            marginLabelTop="0"
                            height="56"
                            value={searchValue}
                            onChange={(e) => handleSearch(e?.target?.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Image src={searchIcon} alt="icon" style={{ cursor: "pointer" }} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Col>
                </Row>
                <div className="parent-student-conatiner mt-3">
                    <Row className="parent-student-list ps-3">
                        <Col md={2} className="align-center text-start">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="User Name" /></p>
                        </Col>
                        <Col md={2} className="align-center text-start">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Contact Number" /></p>
                        </Col>
                        <Col md={2} className="align-center text-start">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Email Address" /></p>
                        </Col>
                        <Col md={2} className="align-center text-center">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Date Added" /></p>
                        </Col>
                        <Col md={2} className="align-center text-center">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Status" /></p>
                        </Col>
                        <Col md={2} className="align-center text-center">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Actions" /></p>
                        </Col>
                    </Row>

                    {allStudentList?.data?.length > 0 && allStudentList?.data.map((item, index) =>
                        <Row className="parent-student-list ps-3">
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">{item?.name}</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">{item?.phone}</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-375D84 ff-InterRegular">{item?.email}</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">{item?.date_joined}</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <SwitchInput
                                    label={false}
                                    checked={item?.userstatus}
                                    onChange={(e) => handleSwitchChange(e, item)}
                                    color="secondary"
                                />
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <MoreHorizIcon aria-describedby={id} style={{ fill: '#3C1053', cursor: "pointer" }} onClick={(e) => handlePopoverClick(e, item)} />
                                <CustomPopover id={id} open={open} anchorEl={anchorEl} handleClose={handlePopoverClose} options={PopoverData} />
                            </Col>
                        </Row>
                    )}
                </div>
            </Row>

            <Row className="mt-3 m-0 p-0">
                <Stack spacing={2} className="p-0">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        className="d-flex justify-end"
                    />
                </Stack>
            </Row>

            {/* Edit User modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isModalActive} handleClose={handleClose}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="EditParent/Student" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={handleClose} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-4'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.FullName" />}
                                    placeholder={intl.formatMessage({ id: "register.FullName" })}
                                    name="fullName"
                                    type="text"
                                    value={formValue.fullName}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.fullName.error && isError.fullName.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={12} className='mt-3'>
                                <div className="phone-number-container">
                                    <label className="phone-label"><FormattedMessage id="PhoneNumber" /></label>
                                    <div className="phone-input-wrapper">

                                        <div className="country-code-input">
                                            <PhoneInput
                                                country={'qa'}
                                                value={countryCode}
                                                onChange={handleCountryCodeChange}
                                                inputProps={{
                                                    name: 'countryCode',
                                                    required: true,
                                                }}
                                                placeholder="country"
                                                inputClass="custom-country-input"
                                                disableCountryCode={true}
                                                disableDropdown={false}
                                                onlyCountries={['qa', 'us', 'in', 'gb']}
                                            />
                                        </div>

                                        <div className="phone-number-input">
                                            <input
                                                type="number"
                                                name="phoneNumber"
                                                value={formValue?.phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                ref={phoneNumberInputRef}
                                                placeholder="e.g: 8521364783"
                                                className={`custom-phone-input ${!isPhoneNumberValid ? 'invalid' : ''}`}
                                            />
                                        </div>
                                    </div>

                                    {isError && isError.phoneNumber.error && isError.phoneNumber.errorMessage ?
                                        <p className="error-message">{isError.phoneNumber.errorMessage}</p>
                                        :
                                        !isPhoneNumberValid ?
                                            <p className="error-message"><FormattedMessage id="Invalidphonenumberforselectedcountrycode" /></p>
                                            :
                                            ""
                                    }

                                </div>
                            </Col>

                        </Row>

                        <Row >
                            <Button variant="contained" onClick={() => handleSubmit()} className='button mt-5 md:mt-2'><FormattedMessage id="Save" /></Button>
                        </Row>
                    </Box>
                }
            />
            {/* Edit User modal  */}

        </Row>
    );
};

export default ParentStudentDetails;
