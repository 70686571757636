import {
    AUTH_REGISTER,
    AUTH_REGISTER_ERROR,
    AUTH_REGISTER_SUCCESS,
} from "./constant";

const authRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTH_REGISTER:
            return { ...state, loading: true, error: '' };
        case AUTH_REGISTER_ERROR:
            return { ...state, type: AUTH_REGISTER_ERROR, loading: false, error: action.payload };
        case AUTH_REGISTER_SUCCESS:
            return { ...state, type: AUTH_REGISTER_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default authRegisterReducer;