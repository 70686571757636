import globalEn from "utils/locale/common/en.json";
import registerEn from "utils/locale/register/en.json";
import loginEn from "utils/locale/login/en.json";
import profileEn from "utils/locale/Profile/en.json";
import teacherDashboardEn from "utils/locale/teacherDashboard/en.json";
import studentDashboardEn from "utils/locale/studentDashboard/en.json";
import adminDashboardEn from "utils/locale/adminDashboard/en.json";

export default function TranslationsEn() {
    return {
        ...globalEn,
        ...registerEn,
        ...loginEn,
        ...profileEn,
        ...teacherDashboardEn,
        ...studentDashboardEn,
        ...adminDashboardEn
    };
}