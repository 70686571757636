import { PASSWORD_DETAILS, PERSONAL_DETAILS, PROFESSIONAL_DETAILS, USER_ROLES } from "./constant";

const initialValue = {
   personalDetailsData: {},
   passwordDetailsData: {},
   ProfessionalDetailsData: {},
   userRoles: "student"
};

const RegistrationDetailsReducer = (state = initialValue, action) => {
   switch (action.type) {
      case PERSONAL_DETAILS:
         return { ...state, personalDetailsData: action.data };
      case PASSWORD_DETAILS:
         return { ...state, passwordDetailsData: action.data };
      case PROFESSIONAL_DETAILS:
         return { ...state, ProfessionalDetailsData: action.data };
      case USER_ROLES:
         return { ...state, userRoles: action.data };
      default:
         return state;
   }
};

export default RegistrationDetailsReducer;