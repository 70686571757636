import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import 'assets/styles/common.scss';
import CheckValidation from 'components/feature/Teacher/ForgotScreen/validation';
import { FormattedMessage, useIntl } from "react-intl";

const ForgotScreen = ({ onClick }) => {

    const [isError, setIsError] = useState(false);
    const intl = useIntl();

    const [formValue, setformValue] = useState({
        email: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            onClick(formValue);
        }
    };

    return (
        <Container>

            <Row className='pt-20  md:pt-120 mb-5'>
                <Row className='mb-5'>
                    <Col>
                        <h2 className='heading2'><FormattedMessage id="register.ResetPassword" /></h2>
                    </Col>
                </Row>

                <Col xs={12} md={12}>
                    <LabelWithInput
                        label={<FormattedMessage id="register.EmailId" />}
                        placeholder={intl.formatMessage({ id: "register.EmailId" })}
                        name="email"
                        type="text"
                        value={formValue?.email}
                        onChange={(e) => handleChange(e)}
                        errorText={isError && isError.email.error && isError.email.errorMessage}
                    />
                </Col>
            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'><FormattedMessage id="register.Continue" /></Button>
            </Row>
        </Container>
    );
};

export default ForgotScreen;
