import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Image, Stack, Badge, Form, Card } from "react-bootstrap";
import 'assets/styles/common.scss';
import 'components/feature/Student/SearchAiScreen/searchAiScreen.scss';
import Button from '@mui/material/Button';
import LabelWithInput from "components/common/Input/LabelWithInput";
import InputAdornment from '@mui/material/InputAdornment';
import micIcon from "assets/images/Navbar/mic.svg";
import speakIcon from "assets/images/Navbar/micIcon.svg";
import ListenIcon from "assets/images/Navbar/listenIcon.png";
import AiSearchFrameImage from "assets/images/Navbar/AiSearchFrame.png";
import MobileImage from "assets/images/Navbar/mobileImage.png";
import checkIcon from "assets/images/Navbar/Checkbox.png";
import sendIcon from "assets/images/Navbar/sendIcon.png";
import arrowRightIcon from "assets/images/Navbar/arrow_right_alt.png";
import prevIcon from "assets/images/Navbar/prevIcon.png";
import nextIcon from "assets/images/Navbar/nextIcon.png";
import AiTextImageIcon from "assets/images/Navbar/AITextImageIcon.svg";
import googlePlayImage from "assets/images/Navbar/googlePlayImage.png";
import AvtarFrameImage from "assets/images/Navbar/AvtarFrame.png";
import appStoreImage from "assets/images/Navbar/appStoreImage.png";
import { FormattedMessage, useIntl } from "react-intl";
import crossIcon from "assets/images/crossIcon.svg";
import { teacherSearchCallAPI } from "store/StudentDashboard/TeacherSearch/action";
import { TEACHER_SEARCH } from "store/StudentDashboard/TeacherSearch/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ToastNotification from "components/common/Toast";
import { getUserRole } from "utils/general";
import CommonModal from "components/common/Modal";
import { Box } from "@mui/system";
import { LocaleContext } from "routes/Router";
import AvatarImage from "assets/images/Navbar/AvatarFeatured.svg";

import { adminDashboard, adminLogin, featureListDetails, filterStudentScreen, register, reset, teacherDashboard } from 'routes/AppUrls';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckValidation from 'components/feature/Teacher/LoginScreen/validation';
import { AUTH_LOGIN } from 'store/Auth/Login/constant';
import { authLoginCallAPI } from 'store/Auth/Login/action';
import profileIcon from "assets/images/Navbar/teacherProfile.png";
import LocationIcon from "assets/images/location_on.svg";
import book from "assets/images/Availability/book.svg";
import StarIcon from "assets/images/star.svg";
import arrowRightIconColor from "assets/images/arrow_right_color.svg";
import { getProfileCallAPI } from "store/Dashboard/Profile/action";
import { GET_PROFILE } from "store/Dashboard/Profile/constant";
import tutorImage from "assets/images/Navbar/tutor.svg"
import { List, ListItem } from "@mui/material";
import { USER_ROLES } from "store/BasicDetails/RegisterationDetails/constant";
import { UserRoles } from "store/BasicDetails/RegisterationDetails/actions";
import SwitchWithLabelInput from "components/common/Input/SwitchWithLabelInput";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { subjectDropDownCallAPI } from "store/Auth/Registration/Subject/action";
import { SUBJECT_DROPDOWN } from "store/Auth/Registration/Subject/constant";
import ReusableAccordion from "components/common/ReusableAccordian";
import { packagePlanAPI } from "store/Auth/Registration/PackagePlan/action";
import { PACKAGE_PLAN } from "store/Auth/Registration/PackagePlan/constant";
import { featuredListCallAPI } from "store/StudentDashboard/FeaturedList/action";
import { FEATURED_LIST } from "store/StudentDashboard/FeaturedList/constant";

const SearchAiScreen = () => {

    const [speakModal, setSpeakModal] = useState(false);
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [conf, setConf] = ToastNotification();
    const [loginModal, setLoginModal] = useState(false);
    const [choosePlan, setChoosePlan] = useState(0);
    const [switchPlan, setSwitchPlan] = useState(false);

    const SubjectList = useSelector(state => state?.subjectDropDownReducer?.payload?.data);
    const PackageList = useSelector(state => state?.packagePlanReducer?.payload?.data);
    const FeaturedListData = useSelector(state => state?.featuredListReducer?.payload?.data?.data);

    console.log(FeaturedListData)

    /////////////////Speech Recognize ///////////
    const [transcript, setTranscript] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [recognition, setRecognition] = useState(null);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition && speakModal) {
            const recognitionInstance = new SpeechRecognition();
            // recognitionInstance.continuous = true;
            recognitionInstance.interimResults = true;
            recognitionInstance.lang = 'en-US';

            recognitionInstance.onresult = (event) => {
                const results = event.results;
                const currentTranscript = results[results.length - 1][0].transcript;
                const isFinal = results[results.length - 1].isFinal;
                if (isFinal) {
                    setTranscript((prev) => prev + currentTranscript + ' ');
                }
            };

            recognitionInstance.onend = () => {
                setIsListening(false);
                setSpeakModal(false);
            };

            recognitionInstance.onerror = (event) => console.error('Error:', event.error);

            setRecognition(recognitionInstance);
            startListening(recognitionInstance);

            if (getUserRole() === 'Student') {
                getProfileApi();
            }

            return () => recognitionInstance.stop();
        } else {
            console.error("Speech recognition not supported");
        }

    }, [speakModal]);


    const getProfileApi = (res) => {
        dispatch(getProfileCallAPI(GET_PROFILE, {}, { locale: locale, run: true, navigate: navigate }));
    };

    useEffect(() => {
        getSubjectListApi();
        getPlanListApi();
        getFeaturedList();
    }, []);

    const getSubjectListApi = () => {
        let body = {
            "slug": "subject"
        };
        dispatch(subjectDropDownCallAPI(SUBJECT_DROPDOWN, body, { navigate: navigate }));
    };

    const getPlanListApi = () => {
        dispatch(packagePlanAPI(PACKAGE_PLAN, {}, { navigate: navigate }));
    };

    const getFeaturedList = () => {
        dispatch(featuredListCallAPI(FEATURED_LIST, {}, { run: true, navigate: navigate }));
    };

    const startListening = (recognitions) => {
        if (recognitions) {
            recognitions.start();
            setIsListening(true);
        }
    };

    const stopListening = () => {
        if (recognition) {
            setTranscript('');
            recognition.stop();
        }
    };
    /////////////////Speech Recognize ///////////

    const handleSpeakModalOpen = () => {
        setSpeakModal(true);
        setTranscript('');
    };

    const handleSpeakModalClose = () => {
        setTranscript('');
        stopListening();
        setSpeakModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setTranscript(value);
    };

    useEffect(() => {
        if (transcript !== '') {
            handleTeacherSearch();
        }
    }, [transcript]);

    const handleTeacherSearch = () => {
        if (transcript !== '' && getUserRole() === 'Student') {
            let body = {
                "query": transcript
            }
            dispatch(teacherSearchCallAPI(TEACHER_SEARCH, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
        } else {
            if (transcript === '') {
                setConf({ msg: "please Type Something", variant: 'error' });
            } else {
                setLoginModal(true);
            }
        }
    };

    const handlePopularSearch = (value) => {
        if (getUserRole() === 'Student') {
            let body = {
                "query": value
            }
            dispatch(teacherSearchCallAPI(TEACHER_SEARCH, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
        } else {
            setLoginModal(true);
        }
    };

    const handleSuccess = (res) => {
        if (res?.data?.status === "success") {
            navigate(filterStudentScreen(locale));
        }
    };

    const [isPassVisible, setIsPassVisible] = useState(false);
    const [isError, setIsError] = useState(false);
    const location = useLocation();
    const { locale } = useContext(LocaleContext);

    const [formValue, setformValue] = useState({
        email: "",
        password: ""
    });

    const handleSignup = () => {
        dispatch(UserRoles(USER_ROLES, 'student'));
        navigate(register(locale));
    };

    const handleForgot = () => {
        navigate(reset(locale));
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            let body = {
                "email": formValue?.email,
                "password": formValue?.password
            };
            dispatch(authLoginCallAPI(AUTH_LOGIN, body, { run: true, handleFunction: (res) => handleRedirect(res), handleToastError: (res) => ToastErrorFunction(res), navigate: navigate, toast: true }));
        }
    };

    const ToastErrorFunction = (res) => {
        setConf({ msg: intl.formatMessage({ id: "InvalidCredential!" }), variant: 'error' });
    };

    const handleRedirect = (res) => {
        if (res?.data?.status_code === 200 && res?.data?.data !== null) {
            setConf({ msg: intl.formatMessage({ id: "loginsuccessfully!" }), variant: 'success' });
            if (res?.data?.data?.userrole === "Administrator") {
                navigate(adminDashboard(locale));
            } else {
                navigate(teacherDashboard(locale));
            }
        } else {
            setConf({ msg: intl.formatMessage({ id: "Invalidcredentials.Pleasetryagain." }), variant: 'error' });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleRedirectResiter = () => {
        dispatch(UserRoles(USER_ROLES, 'teacher'));
        navigate(register(locale));
    };

    const handleChoosePlan = (index) => {
        setChoosePlan(index);
    };

    const handleSwitchPlan = (e) => {
        setSwitchPlan(e.target.checked);
    };

    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                }}
                onClick={onClick}
            >
                <img src={nextIcon} alt="icon" />
            </div>
        );
    };

    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                }}
                onClick={onClick}
            >
                <img src={prevIcon} alt="icon" />
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {speakModal ?
                <Row className="backgroundImage2 m-0 p-0 speakModal" >
                    <div className="flex justify-end close pe-5">
                        <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer" }} onClick={() => handleSpeakModalClose()} />
                    </div>
                    <div className="d-flex flex-col justify-center ">
                        <p className="p-0 m-0 fs-24 fw-500 ff-LexendDecaMedium fc-911E59">{isListening ? <FormattedMessage id="Listening..." /> : <FormattedMessage id="SpeakNow" />}</p>
                        {isListening ?
                            <Image src={ListenIcon} alt={"icon"} style={{ height: "244px", width: "244px", cursor: "pointer", margin: 'auto' }} className="mt-5 pt-4" onClick={stopListening} />
                            :
                            <Image src={speakIcon} alt={"icon"} style={{ height: "120px", cursor: "pointer" }} className="mt-5 pt-4" onClick={startListening} />
                        }
                        <p className="p-0 m-0 fs-24 fw-500 mt-2 ff-LexendDecaMedium fc-911E59">{transcript}</p>
                    </div>
                </Row>
                :
                <>
                    <Row className="searchScreen backgroundImage m-0" >
                        <Row className="text-center mt-5 pt-4 p-0 m-0">
                            <Col lg={{ span: 6 }} className={'mx-auto'}>
                                <h2 className="mt-4 pt-2 fs-48 fc-375D84 fw-600 ff-LexendDecaSemibold"><FormattedMessage id="LandingTitle" /></h2>
                                <p className="mt-3 fs-20 fc-A3A3A3 fw-400 ff-InterRegular"><FormattedMessage id="LandingSubTitle" /></p>
                            </Col>
                        </Row>

                        <Row className="text-center items-center mt-4 pt-2 p-0 m-0">
                            <Col lg={{ span: 8 }} className="search-box mx-auto">
                                <Stack direction="horizontal" gap={3} className="items-center ">
                                    <Image src={AiTextImageIcon} alt="icon" />
                                    <p className="p-0 m-0 fs-20 fw-600 ff-LexendDecaSemibold fc-mixColor"><FormattedMessage id="AskAI" /></p>
                                </Stack>
                                <Stack direction="horizontal" gap={3} className="items-center mt-3">
                                    <LabelWithInput
                                        placeholder={intl.formatMessage({ id: "Search....." })}
                                        name="search"
                                        type="text"
                                        marginTop="0"
                                        marginLabelTop="0"
                                        height="72"
                                        value={transcript}
                                        onChange={(e) => handleChange(e)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Image src={micIcon} alt="icon" onClick={() => handleSpeakModalOpen()} style={{ cursor: "pointer" }} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <Button variant="contained" className='button' style={{ height: "72px !important" }} onClick={handleTeacherSearch} ><FormattedMessage id="Search" />
                                        <Image src={sendIcon} alt={"icon"} style={{ height: '24px', width: '24px', marginLeft: "5px" }} />
                                    </Button>
                                </Stack>
                            </Col>
                        </Row>

                        <Row className="text-center items-center mt-4 p-0 m-0">
                            <Col lg={{ span: 8 }} className={'mx-auto'}>
                                <Stack direction="horizontal" gap={3} className="justify-content-center searchResult">
                                    <p className="p-0 m-0 fs-18 fc-000000 fw-400 ff-InterRegular"><FormattedMessage id="Popularsearches" />:</p>
                                    {SubjectList?.data?.length > 0 && SubjectList?.data.map((item, index) => (
                                        <p className="p-0 m-0 popular-search fs-16 fc-4D4F51 px-3 py-2 fw-400 ff-InterRegular" style={{ cursor: "pointer" }} onClick={() => handlePopularSearch(item?.name)}>{item?.name}</p>
                                    ))}
                                </Stack>
                            </Col>
                        </Row>
                    </Row>

                    <Row className="m-0 bc-FDFCFA" >
                        <Row className="text-center pt-4 p-0 m-0">
                            <Col md={{ span: 6 }} className="mx-auto">
                                <h2 className="mt-4 pt-2 fs-40 fc-911E59 fw-500 ff-LexendDecaMedium"><FormattedMessage id="FeaturedTeachers" /></h2>
                                <p className="mt-3 fs-20 fc-A2A2A2 fw-400 ff-InterRegular"> <FormattedMessage id="FeatureSubTitle" /></p>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-center">
                            {FeaturedListData?.length > 0 && FeaturedListData.map((data, index) => (
                                index <= 2 &&
                                <Col lg={{ span: 3, offset: 0 }}>
                                    <Card className="teachersFeature mt-3 mt-lg-0">
                                        <Image src={data?.profile_image ? data?.profile_image : AvatarImage} alt={"icon"} rounded />
                                        <Card.Body>
                                            <Card.Title>
                                                <h2 className="fs-24 fc-1E1E1E fw-600 ff-LexendDecaMedium">{data?.name}</h2>
                                            </Card.Title>
                                            <Card.Text>
                                                <div className="flex align-items-center mt-2">
                                                    <Image src={LocationIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                                    <p className='mb-0 ml-2 fc-A2A2A2 fw-500 ff-InterRegular'>{data?.city?.name}</p>
                                                </div>
                                                <div className="flex align-items-center mt-2">
                                                    <Image src={book} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                                    <p className='mb-0 ml-2 fc-A2A2A2 fw-500 ff-InterRegular'>{data?.grade_name} {data?.subject_name}</p>
                                                </div>
                                                <div className="flex justify-content-between  flex-wrap">
                                                    <div className="flex align-items-center mt-2">
                                                        <Image src={StarIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                                        <p className='mb-0 ml-2 fc-A2A2A2 fw-500 ff-InterRegular'>{data?.average_rating ? (+data.average_rating).toFixed(1) : null}</p>
                                                    </div>
                                                    {/* <div className="mt-2">
                                                        <p className="p-0 m-0 fs-20 fw-700 ff-InterBold fc-1E1E1E"><FormattedMessage id="QAR2.5" /> <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E"><FormattedMessage id="persession" /></span></p>
                                                    </div> */}
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>

                        <Row className="text-center my-5 p-0 m-0">
                            {FeaturedListData?.length > 3 &&
                                <Col md={{ span: 6 }} className="mx-auto">
                                    <Button className='' variant="outlined" style={{ backgroundColor: 'transparent', color: '#911E59', border: '1px solid #911E59' }} onClick={() => navigate(featureListDetails(locale))}>
                                        <FormattedMessage id="Seemoreteachers" />
                                        <Image src={arrowRightIconColor} alt={"icon"} style={{ cursor: "pointer", height: '20px', width: '20px', marginLeft: "5px" }} />
                                    </Button>
                                </Col>
                            }
                        </Row>
                    </Row>

                    {!getUserRole() &&
                        <Row className="m-0 p-0 mt-5" >
                            <Row className="d-lg-none">
                                <Col>
                                    <h2 className="fs-32 fc-1E1E1E fw-600 ff-LexendDecaMedium"><FormattedMessage id="Becomeatutor" /></h2>
                                    <p className="fs-18 fc-A2A2A2 fw-400 ff-InterRegular"><FormattedMessage id="TutorContent" /></p>
                                </Col>
                            </Row>

                            <Col lg={{ span: 12 }} xl={{ span: 10 }} className={'mx-auto'}>
                                <Row >
                                    <Col lg={6} className="my-3 my-lg-0 ">
                                        <Image src={tutorImage} alt={"icon"} rounded />
                                    </Col>
                                    <Col lg={6} className={`d-flex flex-column justify-content-center ${locale === "en" ? "ps-md-5" : "pe-md-5"}`}>
                                        <Row className="d-none d-lg-flex">
                                            <Col>
                                                <h2 className="fs-32 fc-1E1E1E fw-600 ff-LexendDecaMedium"> <FormattedMessage id="Becomeatutor" /> </h2>
                                                <p className="fs-18 fc-A2A2A2 fw-400 ff-InterRegular"><FormattedMessage id="TutorContent" /></p>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg="auto">
                                                <Button fullWidth variant="contained" className='button' sx={{ height: "50px !important", fontSize: '16px !important' }} onClick={() => handleRedirectResiter()}>
                                                    <FormattedMessage id="GetStarted" />
                                                    <Image src={arrowRightIcon} alt={"icon"} style={{ cursor: "pointer", height: '20px', width: '20px', marginLeft: "5px" }} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }

                    <Row className="m-0 p-0 my-5 pb-5 bc-FDFCFA" >
                        <Row className="text-center pt-4 p-0 m-0">
                            <Col md={{ span: 6 }} className="mx-auto">
                                <h2 className="mt-4 fs-32 fc-1E1E1E fw-500 ff-LexendDecaMedium"><FormattedMessage id="Choosetheplanbestsuitedforyourneed" /></h2>
                                <div className="d-flex justify-center">
                                    <SwitchWithLabelInput
                                        height={'56px'}
                                        thumbHeight={'46px'}
                                        thumbWidth={'160px'}
                                        backgroundColor={'white'}
                                        thumbMargin={'3px'}
                                        borderRadius={'12px'}
                                        thumbBorderRadius={'8px'}
                                        locale={locale}
                                        onText={<p size='18' lineHeight='24' marginTop={0}><FormattedMessage id="Micropackages" /></p>}
                                        offText={<p size='18' lineHeight='24' marginTop={0}><FormattedMessage id="Macropackages" /></p>}
                                        checked={switchPlan}
                                        onChange={(e) => handleSwitchPlan(e)}
                                        name="planType"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4 mb-4 justify-content-center m-0 p-0">
                            <Col md={{ span: 10 }} className="mx-auto">
                                <Row className="justify-content-center">
                                    {PackageList?.data?.length > 0 && PackageList.data.filter(item => (switchPlan ? item?.plantype?.slug === "macro-package" : item?.plantype?.slug === "micro-package")).map((item, index) => (
                                        <Col lg={{ span: 3, offset: 0 }}>
                                            <Card className={`teachersFeature mt-3 mt-lg-0 ${choosePlan === index ? "borderRed" : ""}`} onClick={() => handleChoosePlan(index)}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        <h2 className="fs-32 fc-1E1E1E fw-500 ff-LexendDecaMedium">{item?.name}</h2>
                                                    </Card.Title>
                                                    <Card.Text>
                                                        <div className="flex align-items-center mt-2">
                                                            <p className='mb-0 fs-32 fc-1E1E1E fw-500 ff-LexendDecaMedium'>{item?.price} <span className="fs-24">{"QAR"}</span></p>
                                                        </div>
                                                        <div className="flex align-items-center mt-2">
                                                            <p className='mb-0 fs-16 fc-000000 fw-500 ff-InterMedium'>{item?.billingcycle?.name}</p>
                                                        </div>
                                                        <div className="flex align-items-center mt-2">
                                                            <p className='mb-0 fs-18 fc-000000 fw-500 ff-InterMedium'><FormattedMessage id="Features" /></p>
                                                        </div>
                                                        <div className="flex align-items-center mt-2 fs-16 fc-000000 fw-400 ff-InterRegular">
                                                            <ul className="m-0 p-0">
                                                                <li className="d-flex mt-2"><img src={checkIcon} alt={"icon"} className="me-2" style={{ height: "24px", width: "24px" }} />{item?.features}</li>
                                                            </ul>
                                                        </div>
                                                        <div className="mt-4">
                                                            <Button variant="outlined" className='button-outline w-100'><FormattedMessage id="GetStarted" /></Button>
                                                        </div>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Row>

                    <Row className="m-0 p-0 my-5 intoducingAiSearch" >
                        <Row className="m-0 p-0" >
                            <Col lg={{ span: 12 }} xl={{ span: 11 }} className={'mx-auto'}>
                                <Row >
                                    <Col lg={{ span: 4, offset: 1 }} className={`d-flex flex-column justify-content-center `}>
                                        <Row className="flex">
                                            <Col>
                                                <h2 className="fs-40 fc-101212 fw-400 ff-LexendDecaRegular"> <FormattedMessage id="Introducingour" /> <span className="fc-911E59"><FormattedMessage id="AISearch" /></span> </h2>
                                                <p className="fs-18 fc-817E7E fw-400 ff-InterRegular"><FormattedMessage id="IntoducingAiContent" /></p>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg="auto">
                                                <Button fullWidth variant="contained" className='button' sx={{ height: "50px !important", fontSize: '16px !important' }} onClick={() => handleScrollTop()}>
                                                    <FormattedMessage id="GetStarted" />
                                                    <Image src={arrowRightIcon} alt={"icon"} style={{ cursor: "pointer", height: '20px', width: '20px', marginLeft: "5px" }} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg={{ span: 6, offset: 1 }} className={`d-flex flex-column justify-content-center text-end my-3 my-lg-0`}>
                                        <Image src={AiSearchFrameImage} alt={"icon"} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Row>

                    <Row className="m-0 p-0 my-5" >
                        <Row className="m-0 p-0" >
                            <Col lg={{ span: 12 }} xl={{ span: locale === "en" ? 12 : 11 }} className={'mx-auto'}>
                                <Row >
                                    <Col lg={{ span: 4, offset: 1 }} className={`d-flex flex-column justify-content-center`}>
                                        <Row className="flex ">
                                            <Col>
                                                <h2 className="fs-40 fc-101212 fw-400 ff-LexendDecaRegular"> <FormattedMessage id="Downloadthe" /> <span className="fc-911E59"><FormattedMessage id="App" /></span> </h2>
                                                <p className="fs-18 fc-817E7E fw-400 ff-InterRegular"><FormattedMessage id="Sedpellentesquelectusphasellusvivamustempuslectusaliquamutconvallis." /></p>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col xs={12} lg="auto" className="d-flex justify-content-center ">
                                                <div className="flex flex-col sm:flex-row lg:flex-row gap-2">
                                                    <Image src={googlePlayImage} alt="Google Play" style={{ height: "59px", width: "204px", cursor: "pointer" }} />
                                                    <Image src={appStoreImage} alt="App Store" style={{ height: "59px", width: "204px", cursor: "pointer" }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg={{ span: locale === "en" ? 6 : 7, offset: locale === "en" ? 1 : 0 }} className={`d-flex flex-column justify-content-center text-end my-3 my-lg-0`}>
                                        <Image src={MobileImage} alt={"icon"} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Row>

                    <Row className=" my-5 bc-F7F5EF py-5 p-0 m-0" style={{ overflow: "hidden" }}>
                        <Col lg={{ span: 10 }} className={`mx-auto`}>
                            <h2 className="fs-40 fc-911E59 fw-500 ff-LexendDecaMedium"> <FormattedMessage id="Whatouruserssay" /></h2>
                        </Col>

                        <Col lg={{ span: 11, offset: 1 }} className="p-0 mt-4">
                            <Slider {...settings} className={locale === "en" ? "sliderDetailsEn" : "sliderDetailsAr"}>
                                {[1, 2, 3, 4, 5].map((data, index) => (
                                    <div className="p-2">
                                        <Card className={`teachersFeature mt-3 mt-lg-0 `}>
                                            <Card.Body>
                                                <Card.Title >
                                                    <div className="flex align-items-center mt-2">
                                                        <Image src={AvtarFrameImage} alt={"image"} className="me-2" />
                                                        <h2 className="fs-20 fc-1E1E1E fw-600 ff-LexendDecaSemibold p-0 m-0"><FormattedMessage id="UserName" /></h2>
                                                    </div>
                                                </Card.Title>
                                                <Card.Text>
                                                    <div className="flex align-items-center mt-2">
                                                        <p className='mb-0 fs-18 fc-A2A2A2 fw-400 ff-InterRegular'><FormattedMessage id="LoremipsumParagraph" /></p>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </Slider>
                        </Col>
                    </Row>

                    <Row className="my-5 bc-FDFCFA py-5 p-0 ps-3 ps-lg-0  pe-3 pe-lg-0 m-0">
                        <Col lg={{ span: 10 }} className={`mx-auto teachersFeature bc-FFFFFF p-4`}>
                            <Row>
                                <Col lg={4}>
                                    <h2 className="fs-32 fc-1E1E1E fw-500 ff-LexendDecaMedium p-0 m-0"><FormattedMessage id="Frequentlyaskedquestions" /></h2>
                                    <p className="mb-0 mt-3 fs-186 fc-A2A2A2 fw-400 ff-InterRegular"><FormattedMessage id="Frequentlyparagraph" /></p>
                                    <Stack direction="horizontal" gap={5} className="mt-5 items-center">
                                        <Button variant="outlined" className='button-outline'><FormattedMessage id="Morequestions" /></Button>
                                        <p className="underline mb-0 fs-18 fc-911E59 fw-600 ff-InterSemiBold"><FormattedMessage id="ContactUs" /></p>
                                    </Stack>
                                </Col>

                                <Col lg={8}>
                                    <div>
                                        <ReusableAccordion title={<FormattedMessage id="Loremipsumdolorsitametconsectetur?" />} defaultExpanded>
                                            <FormattedMessage id="accordianParagraph" />
                                        </ReusableAccordion>
                                        <ReusableAccordion title={<FormattedMessage id="Orcienimscelerisqueegeteget?" />} >
                                            <FormattedMessage id="accordianParagraph" />
                                        </ReusableAccordion>
                                        <ReusableAccordion title={<FormattedMessage id="Nulladonecconvallisestame?" />} >
                                            <FormattedMessage id="accordianParagraph" />
                                        </ReusableAccordion>
                                        <ReusableAccordion title={<FormattedMessage id="Velitfermentumegetaliquamin.Ipsumsedpellentesque?" />}>
                                            <FormattedMessage id="accordianParagraph" />
                                        </ReusableAccordion>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </>
            }

            {/* Login Modal  */}
            <CommonModal padding={'32px 48px'} width={'700px'} modalActive={loginModal} handleClose={() => setLoginModal(false)}
                innerContent={
                    <Box >
                        <Row className='pt-20 mb-5'>
                            <Row className=''>
                                <Col>
                                    <h2 className='heading2'><FormattedMessage id="Login Required" /></h2>
                                    <p className="fs-18 fw-400 ff-InterRegular fc-A2A2A2"><FormattedMessage id="You have to be registered user to view the teacher’s full profile" /></p>
                                </Col>
                            </Row>

                            <Col xs={12} md={12} className='mt-4'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.EmailId" />}
                                    placeholder={intl.formatMessage({ id: "register.EmailId" })}
                                    name="email"
                                    type="text"
                                    value={formValue?.email}
                                    onChange={handleInputChange}
                                    errorText={isError && isError.email.error && isError.email.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={12} className='mt-3'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.Password" />}
                                    placeholder={intl.formatMessage({ id: "register.Password" })}
                                    name="password"
                                    value={formValue?.password}
                                    type={isPassVisible ? "text" : "password"}
                                    onChange={handleInputChange}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {isPassVisible ? (
                                                    <VisibilityOutlinedIcon sx={{ cursor: "pointer", color: '#911E59' }} onClick={() => setIsPassVisible(!isPassVisible)} />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon sx={{ cursor: "pointer", color: '#911E59' }} onClick={() => setIsPassVisible(!isPassVisible)} />
                                                )}
                                            </>
                                        )
                                    }}
                                    errorText={isError && isError.password.error && isError.password.errorMessage}
                                />
                            </Col>

                            {location.pathname !== adminLogin(locale) &&
                                <>
                                    <Col xs={12} md={12} className='d-flex justify-content-end mt-4'>
                                        <p className='resend forgot' onClick={handleForgot}><FormattedMessage id="ForgotPassword" /></p>
                                    </Col>

                                    <Col xs={12} md={12} className='d-flex justify-content-center mt-4'>
                                        <p className='already_account'><FormattedMessage id="login.DoNotHaveAnAccount" /> <span className='signup' onClick={handleSignup}><FormattedMessage id="SignUp" /></span></p>
                                    </Col>
                                </>
                            }

                        </Row>

                        <Row>
                            <Button variant="contained" onClick={() => handleSubmit()} className='button mt-5 md:mt-2'><FormattedMessage id="register.Continue" /></Button>
                        </Row>
                    </Box>
                }
            />
            {/* Login Modal  */}
        </>

    );
};

export default SearchAiScreen;