import {
    SUBJECT_DROPDOWN,
    SUBJECT_DROPDOWN_ERROR,
    SUBJECT_DROPDOWN_SUCCESS,
} from "./constant";

const subjectDropDownReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBJECT_DROPDOWN:
            return { ...state, loading: true, error: '' };
        case SUBJECT_DROPDOWN_ERROR:
            return { ...state, type: SUBJECT_DROPDOWN_ERROR, loading: false, error: action.payload };
        case SUBJECT_DROPDOWN_SUCCESS:
            return { ...state, type: SUBJECT_DROPDOWN_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default subjectDropDownReducer;