import React, { useContext } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Button from '@mui/material/Button';
import circleCheckIcon from "assets/images/TeacherDashboard/greenCheckCircle.svg";
import "components/feature/QuestionnaireSuccess/questionnaireSuccess.scss";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import { teacherDashboard } from 'routes/AppUrls';
import { FormattedMessage, useIntl } from "react-intl";

const QuestionAnswerResult = ({ onClick }) => {

    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);

    const QuestionsAnswersData = useSelector(state => state?.SubmitQuestionsReducer?.payload?.data?.data);

    const handleRedirect = () => {
        navigate(teacherDashboard(locale));
    };

    const getOptionId = (data) => {
        let index = data?.options?.findIndex(obj => obj === data?.correct_answer);
        return index + 1;
    };

    const getOptionWrongId = (data) => {
        let index = data?.options?.findIndex(obj => obj === data?.submitted_answer);
        return index + 1;
    };

    return (
        <Row className="main-content p-4 mt-5 mt-lg-0 pb-5 m-0">
            <Row className='ResultDetails'>
                <Col xs={12} sm={12} md={12}>
                    <p className='Test'><FormattedMessage id="TestResults" /></p>
                </Col>
                <Col xs={12} sm={12} md={6} className='d-flex flex-col mt-1 justify-content-start'>
                    <p className={`m-0 resultHeading ${QuestionsAnswersData?.pass ? "green-color" : "orange-color"}`} >{QuestionsAnswersData?.pass ? <FormattedMessage id="Congratulations" /> : <FormattedMessage id="Sorry!" />}</p>
                    <p className='mt-2 resultSubheading'>{QuestionsAnswersData?.pass ? <FormattedMessage id="Youhavepassedtheexam" /> : <FormattedMessage id="Youhavefailedtheexam,youcantryagainin6months" />}</p>
                </Col>
                <Col xs={6} sm={6} md={6} className='d-none d-md-flex justify-content-end align-items-center'>
                    <Button variant="contained" className='button' onClick={handleRedirect}><FormattedMessage id="Continuetonextstep" /></Button>
                </Col>
            </Row>

            <Row>
                <h5 className='options mt-4'>
                    <FormattedMessage id="Summary" />
                </h5>
            </Row>

            <Row>
                <Col className='d-flex flex-wrap'>
                    <p className='summaryHeading me-5'><FormattedMessage id="Totalquestions" />: <span className='summaryResults black-color'>{QuestionsAnswersData?.total_questions}</span></p>
                    <p className='summaryHeading me-5'><FormattedMessage id="Correctanswers" />: <span className='summaryResults black-color'>{QuestionsAnswersData?.correct_count}</span></p>
                    <p className='summaryHeading me-5'><FormattedMessage id="Yourscore" />: <span className={`summaryResults ${QuestionsAnswersData?.pass ? "green-color" : "orange-color"}`}>{(QuestionsAnswersData?.correct_count / QuestionsAnswersData?.total_questions) * 100}%</span></p>
                </Col>
            </Row>

            {QuestionsAnswersData?.detailed_results?.length > 0 && QuestionsAnswersData?.detailed_results?.map((k, i) => (
                <Row className='questionAnswers mt-4 mb-4'>
                    <Col md={8} className='justify-content-start'>
                        <p className='m-0 align-items-center questionHeading'>
                            {`Q${k?.question_id}. ${k?.question}`}
                        </p>

                        {!k?.is_correct &&
                            <>
                                <h5 className='options mt-4'>
                                    <FormattedMessage id="Youranswer" />:
                                </h5>
                                <div className="question-list mt-2">
                                    <div className={"question-item"}>
                                        <div className='d-flex justify-content-center align-items-center gap-3'>
                                            <p className={"circle m-0"}>{getOptionWrongId(k)}</p>
                                            <p className='m-0'>{k?.submitted_answer}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <h5 className='options mt-4'>
                            {k?.is_correct ? <FormattedMessage id="Youranswerwascorrect" /> : <FormattedMessage id="Correctanswer" />}
                        </h5>
                        <div className="question-list mt-2">
                            <div className={"selectQuestion-item"}>
                                <div className='d-flex justify-content-center align-items-center gap-3'>
                                    <p className={"selecterCircle m-0"}>{getOptionId(k)}</p>
                                    <p className='m-0 '>{k?.correct_answer}</p>
                                </div>
                                <div><Image src={circleCheckIcon} style={{ minWidth: "24px", minHeight: "24px" }} alt="icon" /></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ))}

            <Row>
                <Col xs={12} sm={12} md={12} className='d-flex d-md-none justify-content-center align-items-center'>
                    <Button variant="contained" className='button' onClick={handleRedirect}><FormattedMessage id="Continuetonextstep" /></Button>
                </Col>
            </Row>
        </Row >
    );
};

export default QuestionAnswerResult;