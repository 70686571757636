import React from 'react';
import { Stack } from 'react-bootstrap';
import Button from '@mui/material/Button';
import 'assets/styles/common.scss';

const EmailVerification = ({ onClick }) => {

    const handleRedirect = () => {
        onClick();
    };

    return (
        <div className="main-content flex text-center flex-col align-items-center justify-content-center page-content p-4 mt-5 mt-lg-0 pb-5 min-h-[89vh]">
            <h2 className="heading2">Email Verification Required</h2>
            <p>
                In order to continue with AI exam, you need to verify your email first.
            </p>

            <Stack direction="horizontal" gap={2} className='d-flex justify-content-center mt-5 mb-5'>
                <Button variant="contained" className='button' onClick={() => handleRedirect()}>Proceed to Verify Email</Button>
            </Stack>
        </div>
    );
};

export default EmailVerification;