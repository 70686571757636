import {
    INTERVIEW_FEEDBACK,
    INTERVIEW_FEEDBACK_ERROR,
    INTERVIEW_FEEDBACK_SUCCESS,
} from "./constant";

const getInterviewFeedbackReducer = (state = {}, action) => {
    switch (action.type) {
        case INTERVIEW_FEEDBACK:
            return { ...state, loading: true, error: '' };
        case INTERVIEW_FEEDBACK_ERROR:
            return { ...state, type: INTERVIEW_FEEDBACK_ERROR, loading: false, error: action.payload };
        case INTERVIEW_FEEDBACK_SUCCESS:
            return { ...state, type: INTERVIEW_FEEDBACK_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default getInterviewFeedbackReducer;