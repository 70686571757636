import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToastNotification from "components/common/Toast";
import "components/feature/Admin/PushNotificationDetails/pushNotificationdetails.scss";
import SelectInput from "components/common/Input/SelectInput";
import LabelWithInput from "components/common/Input/LabelWithInput";
import { countryDropDownCallAPI } from "store/Auth/Registration/Country/action";
import { COUNTRY_DROPDOWN } from "store/Auth/Registration/Country/constant";
import { stateDropDownCallAPI } from "store/Auth/Registration/State/action";
import { STATE_DROPDOWN } from "store/Auth/Registration/State/constant";
import { cityDropDownCallAPI } from "store/Auth/Registration/City/action";
import { CITY_DROPDOWN } from "store/Auth/Registration/City/constant";
import Button from '@mui/material/Button';
import { gradeLevelDropDownCallAPI } from "store/Auth/Registration/GreadeLevel/action";
import { GRADE_LEVEL_DROPDOWN } from "store/Auth/Registration/GreadeLevel/constant";
import { subjectDropDownCallAPI } from "store/Auth/Registration/Subject/action";
import { SUBJECT_DROPDOWN } from "store/Auth/Registration/Subject/constant";
import { DEVICE_TYPE_DROPDOWN } from "store/AdminDashboard/DeviceTypeDropDown/constant";
import { userTypeDropdownCallAPI } from "store/AdminDashboard/UserTypeDropDown/action";
import { USER_TYPE_DROPDOWN } from "store/AdminDashboard/UserTypeDropDown/constant";
import { deviceTypeDropdownCallAPI } from "store/AdminDashboard/DeviceTypeDropDown/action";
import { pushNotificationCallAPI } from "store/AdminDashboard/PushNotification/action";
import { PUSH_NOTIFICATION } from "store/AdminDashboard/PushNotification/constant";
import pushNotificationValidation from "./pushNotificationValidation";

const PushNotificationDetails = () => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [conf, setConf] = ToastNotification();
    const [isError, setIsError] = useState(false);

    const countryList = useSelector(state => state?.countryDropDownReducer?.payload?.data);
    const stateList = useSelector(state => state?.stateDropDownReducer?.payload?.data);
    const cityList = useSelector(state => state?.cityDropDownReducer?.payload?.data);
    const GradeLevelList = useSelector(state => state?.gradeLevelDropDownReducer?.payload?.data);
    const SubjectList = useSelector(state => state?.subjectDropDownReducer?.payload?.data);
    const DeviceTypeList = useSelector(state => state?.deviceTypeDropdownReducer?.payload?.data);
    const UserTypeList = useSelector(state => state?.userTypeDropdownReducer?.payload?.data);

    const [formValue, setformValue] = useState({
        country: "",
        state: "",
        city: "",
        deviceType: "",
        userType: "",
        grades: "",
        subjects: "",
        title: "",
        description: ""
    });

    const [formId, setformId] = useState({
        country: null,
        state: null,
        city: null,
        deviceType: null,
        userType: "",
        grades: null,
        subjects: null,
        title: "",
        description: ""
    });


    useEffect(() => {
        getCountryListApi();
        getDeviceTypeListApi();
        getUserTypeListApi();
        getGradeLevelListApi();
        getSubjectListApi();
    }, []);

    const getCountryListApi = () => {
        let body = {
            "slug": "country"
        };
        dispatch(countryDropDownCallAPI(COUNTRY_DROPDOWN, body, { navigate: navigate }));
    };

    const getDeviceTypeListApi = () => {
        let body = {
            "slug": "devicetype"
        };
        dispatch(deviceTypeDropdownCallAPI(DEVICE_TYPE_DROPDOWN, body, { navigate: navigate }));
    };

    const getUserTypeListApi = () => {
        let body = {
            "slug": "usertype"
        };
        dispatch(userTypeDropdownCallAPI(USER_TYPE_DROPDOWN, body, { navigate: navigate }));
    };

    const getGradeLevelListApi = () => {
        let body = {
            "slug": "gradlevel"
        };
        dispatch(gradeLevelDropDownCallAPI(GRADE_LEVEL_DROPDOWN, body, { navigate: navigate }));
    };

    const getSubjectListApi = () => {
        let body = {
            "slug": "subject"
        };
        dispatch(subjectDropDownCallAPI(SUBJECT_DROPDOWN, body, { navigate: navigate }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "country") {
            let body = {
                "slug": "state",
                "parent_id": value?.id
            };
            dispatch(stateDropDownCallAPI(STATE_DROPDOWN, body, { navigate: navigate }));
            setformValue((prev) => ({
                ...prev,
                ["state"]: "",
                ['city']: ""
            }));
        }
        if (name === "state") {
            let body = {
                "slug": "city",
                "parent_id": value?.id
            };
            dispatch(cityDropDownCallAPI(CITY_DROPDOWN, body, { navigate: navigate }));
            setformValue((prev) => ({
                ...prev,
                ['city']: ""
            }));
        }

        setformValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));

        setformId((prev) => ({
            ...prev,
            [name]: value?.id
        }));
    };

    const handleSendNotification = () => {
        let value = {
            userType: formValue?.userType,
            title: formValue?.title,
            description: formValue?.description,
        };
        const error = pushNotificationValidation(value);
        setIsError(error);
        if (error.submit) {
            let body = {
                "country_id": formId?.country,
                "state_id": formId?.state,
                "city_id": formId?.city,
                "devicetype_id": formId?.deviceType,
                "usertype_id": formId?.userType,
                "grade_id": formId?.grades,
                "subject_id": formId?.subjects,
                "title": formValue?.title,
                "description": formValue?.description
            }
            dispatch(pushNotificationCallAPI(PUSH_NOTIFICATION, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }))
        }
    };

    const handleSuccess = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: res?.data?.message, variant: 'success' });
            setformValue((prev) => ({
                ...prev,
                ["country"]: "",
                ["state"]: "",
                ["city"]: "",
                ["deviceType"]: "",
                ["userType"]: "",
                ["grades"]: "",
                ["subjects"]: "",
                ["title"]: "",
                ["description"]: ""
            }));

            setformId((prev) => ({
                ...prev,
                ["country"]: null,
                ["state"]: null,
                ["city"]: null,
                ["deviceType"]: null,
                ["userType"]: "",
                ["grades"]: null,
                ["subjects"]: null,
                ["title"]: "",
                ["description"]: ""
            }));
        }
    };

    return (
        <Row className="mt-5 mt-lg-0 pb-0 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="Push Notifications" /></h5>

            <Row className="page-notification-contents mt-3 m-0 pb-5 p-3">
                <p className="m-0 fs-18 fw-400 fc-525252 ff-InterRegular">
                    <FormattedMessage id="Fill the following details" />
                </p>

                <Col xs={12} md={5} className='mt-3'>
                    <SelectInput
                        label={<FormattedMessage id="Country" />}
                        placeholder={intl.formatMessage({ id: "Country" })}
                        name={"country"}
                        value={formValue?.country}
                        data={countryList?.data}
                        onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError?.country?.error && isError?.country?.errorMessage}
                    />
                </Col>

                <Col xs={0} md={5} className='mt-3'>
                </Col>

                <Col xs={12} md={5} className='mt-3'>
                    <SelectInput
                        label={<FormattedMessage id="State" />}
                        placeholder={intl.formatMessage({ id: "State" })}
                        name={"state"}
                        value={formValue?.state}
                        data={stateList?.data}
                        onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError?.state?.error && isError?.state?.errorMessage}
                    />
                </Col>

                <Col xs={12} md={5} className='mt-3'>
                    <SelectInput
                        label={<FormattedMessage id="City" />}
                        placeholder={intl.formatMessage({ id: "City" })}
                        name={"city"}
                        value={formValue?.city}
                        data={cityList?.data}
                        onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError?.city?.error && isError?.city?.errorMessage}
                    />
                </Col>

                <Col xs={12} md={5} className='mt-3'>
                    <SelectInput
                        label={<FormattedMessage id="Device Type" />}
                        placeholder={intl.formatMessage({ id: "Device Type" })}
                        name={"deviceType"}
                        value={formValue?.deviceType}
                        data={DeviceTypeList?.data}
                        onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError?.deviceType?.error && isError?.deviceType?.errorMessage}
                    />
                </Col>

                <Col xs={12} md={5} className='mt-3'>
                    <SelectInput
                        label={<FormattedMessage id="User Type" />}
                        placeholder={intl.formatMessage({ id: "User Type" })}
                        name={"userType"}
                        value={formValue?.userType}
                        data={UserTypeList?.data}
                        onChange={(e) => handleInputChange(e)}
                        errorText={isError && isError?.userType?.error && isError?.userType?.errorMessage}
                    />
                </Col>

                <Col xs={12} md={10} className='mt-3'>
                    <SelectInput
                        label={<FormattedMessage id="Grade(s)" />}
                        placeholder={intl.formatMessage({ id: "Grade(s)" })}
                        name={"grades"}
                        value={formValue?.grades}
                        data={GradeLevelList?.data}
                        onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError?.grades?.error && isError?.grades?.errorMessage}
                    />
                </Col>

                <Col xs={12} md={10} className='mt-3'>
                    <SelectInput
                        label={<FormattedMessage id="Subject(s)" />}
                        placeholder={intl.formatMessage({ id: "Subject(s)" })}
                        name={"subjects"}
                        value={formValue?.subjects}
                        data={SubjectList?.data}
                        onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError?.subjects?.error && isError?.subjects?.errorMessage}
                    />
                </Col>

                <Col xs={12} md={10} className='mt-3'>
                    <LabelWithInput
                        label={<FormattedMessage id="Notification Title" />}
                        placeholder={intl.formatMessage({ id: "Notification Title" })}
                        name="title"
                        type="text"
                        value={formValue?.title}
                        onChange={(e) => handleChange(e)}
                        errorText={isError && isError?.title?.error && isError?.title?.errorMessage}
                    />
                </Col>

                <Col xs={12} md={10} className='mt-3'>
                    <LabelWithInput
                        label={<FormattedMessage id="Description" />}
                        placeholder={intl.formatMessage({ id: "Description" })}
                        height="118"
                        multiline
                        name="description"
                        value={formValue?.description}
                        onChange={(e) => handleChange(e)}
                        errorText={isError && isError?.description?.error && isError?.description?.errorMessage}
                    />
                </Col>

                <Col xs={12} md={10} className="d-flex justify-end">
                    <Button variant="contained" className='button mt-4 md:mt-2' onClick={handleSendNotification}><FormattedMessage id="Send Notifications" /></Button>
                </Col>

            </Row>
        </Row>
    );
};

export default PushNotificationDetails;
