import {
    DEVICE_TYPE_DROPDOWN,
} from "./constant";

export const deviceTypeDropdownCallAPI = (type, data, external = {}) => {
    switch (type) {
        case DEVICE_TYPE_DROPDOWN:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};