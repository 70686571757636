import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import CustomNavbar from 'components/feature/Navbar';
import ProfileScreen from 'components/feature/Student/profileScreen';

const StudentProfile = () => {

    return (
        <Container fluid className='dashboard_container'>
            <Row>
                <CustomNavbar />
            </Row>
            <Row className=''>
                <Col md={0} lg={0}>
                    {/* Empty space on the left */}
                </Col>
                <Col md={12} lg={11} className="mt-4">
                    <ProfileScreen />
                </Col>
                <Col md={0} lg={0}>
                    {/* Empty space on the right */}
                </Col>
            </Row>
        </Container>
    );
};

export default StudentProfile;