import {
    TEACHER_FULL_PROFILE,
    TEACHER_FULL_PROFILE_ERROR,
    TEACHER_FULL_PROFILE_SUCCESS,
} from "./constant";

const teacherFullProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case TEACHER_FULL_PROFILE:
            return { ...state, loading: true, error: '' };
        case TEACHER_FULL_PROFILE_ERROR:
            return { ...state, type: TEACHER_FULL_PROFILE_ERROR, loading: false, error: action.payload };
        case TEACHER_FULL_PROFILE_SUCCESS:
            return { ...state, type: TEACHER_FULL_PROFILE_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default teacherFullProfileReducer;