import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Footer from '../Footer';
import CustomNavbar from '../Navbar';
import SearchAiScreen from '../Student/SearchAiScreen';

const LandingScreen = () => {


    return (
        <Container fluid>
            <Row>
                <CustomNavbar />
            </Row>
            <Row>
                <SearchAiScreen />
            </Row>
            <Row>
                <Footer />
            </Row>

        </Container>
    );
};

export default LandingScreen;
