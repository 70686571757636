import {
    STUDENT_REVIEW,
    STUDENT_REVIEW_ERROR,
    STUDENT_REVIEW_SUCCESS,
} from "./constant";

const studentReviewReducer = (state = {}, action) => {
    switch (action.type) {
        case STUDENT_REVIEW:
            return { ...state, loading: true, error: '' };
        case STUDENT_REVIEW_ERROR:
            return { ...state, type: STUDENT_REVIEW_ERROR, loading: false, error: action.payload };
        case STUDENT_REVIEW_SUCCESS:
            return { ...state, type: STUDENT_REVIEW_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default studentReviewReducer;