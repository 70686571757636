import React, { useEffect, useState } from "react";
import { Row, Col, Image, Stack } from "react-bootstrap";
import addIcon from "assets/images/addIcon.svg";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/CategoryManagementDetails/categoryManagementDetails.scss";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReusableTabs from "components/common/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CustomPopover from "components/common/CutomPopover";
import ToastNotification from "components/common/Toast";
import { getCategoryManagementDataCallAPI } from "store/AdminDashboard/GetCategoryManagement/action";
import { GET_CATEGORY_MANAGEMENT_DATA } from "store/AdminDashboard/GetCategoryManagement/constant";
import CommonModal from "components/common/Modal";
import LabelWithInput from "components/common/Input/LabelWithInput";
import crossIcon from "assets/images/crossIcon.svg";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import CheckSubjectValidation from "components/feature/Teacher/TeacherDetails/validation";
import { addSubjectGradeCallAPI } from "store/AdminDashboard/AddSubjectGradeAdmin/action";
import { ADD_SUBJECT_GRADE_ADMIN } from "store/AdminDashboard/AddSubjectGradeAdmin/constant";
import { editSubjectGradeCallAPI } from "store/AdminDashboard/EditSubjectGradeAdmin/action";
import { EDIT_SUBJECT_GRADE_ADMIN } from "store/AdminDashboard/EditSubjectGradeAdmin/constant";
import { deleteSubjectGradeCallAPI } from "store/AdminDashboard/DeleteSubjectGradeAdmin/action";
import { DELETE_SUBJECT_GRADE_ADMIN } from "store/AdminDashboard/DeleteSubjectGradeAdmin/constant";

const CategoryManagementDetails = () => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [conf, setConf] = ToastNotification();
    const [anchorEl, setAnchorEl] = useState(null);
    const [tab, setTab] = useState(1);

    const categoryManagementList = useSelector(state => state?.getCategoryManagementDataReducer?.payload?.data);
    const [viewData, setViewData] = useState("");
    const [isSubjectsError, setIsSubjectsError] = useState(false);
    const [isGradesError, setIsGradesError] = useState(false);
    const [isSubjectAddModalActive, setIsSubjectAddModalActive] = useState(false);
    const [isGradeAddModalActive, setIsGradeAddModalActive] = useState(false);
    const [editSubject, setEditSubject] = useState(false);
    const [editGrade, setEditGrade] = useState(false);

    const [formAddSubjectValue, setFormAddSubjectValue] = useState({
        subjectNameEn: "",
        subjectNameAr: "",
    });
    const [formAddGradeValue, setFormAddGradeValue] = useState({
        gradeNameEn: "",
        gradeNameAr: "",
    });

    const handlePopoverClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setViewData(item);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const PopoverData = [
        {
            label: <FormattedMessage id="Edit" />,
            icon: <EditOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => { tab === 1 ? editSubjectHandle() : editGradeHandle() },
        },
        {
            label: <FormattedMessage id="Delete" />,
            icon: <DeleteOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => { tab === 1 ? deleteSubjectHandle() : deleteGradeHandle() },
        }
    ];

    useEffect(() => {
        getCategoryManagementLists();
    }, []);

    const getCategoryManagementLists = () => {
        dispatch(getCategoryManagementDataCallAPI(GET_CATEGORY_MANAGEMENT_DATA, { type: "subject" }, { run: true, navigate: navigate }));
    };

    const tabData = [
        { label: intl.formatMessage({ id: "Subjects" }) },
        { label: intl.formatMessage({ id: "Grade" }) },
    ];

    const editSubjectHandle = () => {
        setEditSubject(true);
        setIsSubjectAddModalActive(true);
        setFormAddSubjectValue((prev) => ({
            ...prev,
            ["subjectNameEn"]: viewData?.en_name,
            ["subjectNameAr"]: viewData?.ar_name,
        }));
    };

    const editGradeHandle = () => {
        setEditGrade(true);
        setIsGradeAddModalActive(true);
        setFormAddGradeValue((prev) => ({
            ...prev,
            ["gradeNameEn"]: viewData?.en_name,
            ["gradeNameAr"]: viewData?.ar_name,
        }));
    };

    const deleteSubjectHandle = () => {
        dispatch(deleteSubjectGradeCallAPI(DELETE_SUBJECT_GRADE_ADMIN, {}, { subjectId: viewData?.id, run: true, handleFunction: (res) => handleCategorySuccess(res), navigate: navigate }));
        setAnchorEl(null);
    };

    const deleteGradeHandle = () => {
        dispatch(deleteSubjectGradeCallAPI(DELETE_SUBJECT_GRADE_ADMIN, {}, { subjectId: viewData?.id, run: true, handleFunction: (res) => handleGradeSuccess(res), navigate: navigate }));
        setAnchorEl(null);
    };

    const handleCategorySuccess = (res) => {
        setConf({ msg: res?.data?.message, variant: 'success' });
        getCategoryManagementLists();
    };

    const handleGradeSuccess = (res) => {
        setConf({ msg: res?.data?.message, variant: 'success' });
        getGradeLevelLists();
    };

    const getGradeLevelLists = () => {
        dispatch(getCategoryManagementDataCallAPI(GET_CATEGORY_MANAGEMENT_DATA, { type: "gradlevel" }, { run: true, navigate: navigate }));
    };

    const handleTabChange = (val) => {
        setTab(val);
        if (val === 1) {
            getCategoryManagementLists();
        } else if (val === 2) {
            getGradeLevelLists();
        }
    };

    const handleAddSubjects = () => {
        setEditSubject(false);
        setIsSubjectsError(false);
        setIsSubjectAddModalActive(true);
        setFormAddSubjectValue((prev) => ({
            ...prev,
            ["subjectNameEn"]: "",
            ["subjectNameAr"]: "",
        }));
    };

    const handleAddGrade = () => {
        setEditGrade(false);
        setIsGradesError(false);
        setIsGradeAddModalActive(true);
        setFormAddGradeValue((prev) => ({
            ...prev,
            ["gradeNameEn"]: "",
            ["gradeNameAr"]: "",
        }));
    };

    const handleAddSubjectSubmit = () => {
        const error = CheckSubjectValidation(formAddSubjectValue);
        setIsSubjectsError(error);
        if (error.submit) {
            let body = {
                "name_en": formAddSubjectValue?.subjectNameEn,
                "name_ar": formAddSubjectValue?.subjectNameAr,
                "type": "subject"
            }
            dispatch(addSubjectGradeCallAPI(ADD_SUBJECT_GRADE_ADMIN, body, { run: true, handleFunction: (res) => handleCategorySuccess(res), navigate: navigate }));
            setIsSubjectAddModalActive(false);
            setAnchorEl(null);
        }
    };

    const handleEditSubjectSubmit = () => {
        const error = CheckSubjectValidation(formAddSubjectValue);
        setIsSubjectsError(error);
        if (error.submit) {
            let body = {
                "name_en": formAddSubjectValue?.subjectNameEn,
                "name_ar": formAddSubjectValue?.subjectNameAr,
            }
            dispatch(editSubjectGradeCallAPI(EDIT_SUBJECT_GRADE_ADMIN, body, { subjectId: viewData?.id, run: true, handleFunction: (res) => handleCategorySuccess(res), navigate: navigate }));
            setIsSubjectAddModalActive(false);
            setAnchorEl(null);
        }
    };

    const handleEditGradeSubmit = () => {
        const error = CheckSubjectValidation(formAddGradeValue);
        setIsGradesError(error);
        if (error.submit) {
            let body = {
                "name_en": formAddGradeValue?.gradeNameEn,
                "name_ar": formAddGradeValue?.gradeNameAr,
            }
            dispatch(editSubjectGradeCallAPI(EDIT_SUBJECT_GRADE_ADMIN, body, { subjectId: viewData?.id, run: true, handleFunction: (res) => handleGradeSuccess(res), navigate: navigate }));
            setIsGradeAddModalActive(false);
            setAnchorEl(null);
        }
    };

    const handleAddGradeSubmit = () => {
        const error = CheckSubjectValidation(formAddGradeValue);
        setIsGradesError(error);
        if (error.submit) {
            let body = {
                "name_en": formAddGradeValue?.gradeNameEn,
                "name_ar": formAddGradeValue?.gradeNameAr,
                "type": "gradlevel"
            }
            dispatch(addSubjectGradeCallAPI(ADD_SUBJECT_GRADE_ADMIN, body, { run: true, handleFunction: (res) => handleGradeSuccess(res), navigate: navigate }));
            setIsGradeAddModalActive(false);
        }
    };

    const handleAddSubjectChange = (e) => {
        const { name, value } = e?.target;
        setFormAddSubjectValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleAddGradeChange = (e) => {
        const { name, value } = e?.target;
        setFormAddGradeValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleCloseSubjectModel = () => {
        setIsSubjectAddModalActive(false);
    };

    const handleCloseGradeModel = () => {
        setIsGradeAddModalActive(false);
    };

    return (
        <Row className="mt-5 mt-lg-0 pb-0 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="Category Management" /></h5>

            <Row className="mt-1 p-0 m-0">
                <Col md={6} lg={6} xl={6} xxl={4} className="m-0 p-0">
                    <ReusableTabs tabs={tabData} defaultTab={1} currentTab={(val) => handleTabChange(val)} />
                </Col>
            </Row>

            {tab === 1 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 ps-2 pe-2">
                    <Row className="p-0 m-0">
                        <div className="flex justify-between items-center mt-2">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="profile.Subjects" />
                            </p>
                            <Stack direction="horizontal" gap={2} className="addSubjectButton flex justify-end" onClick={() => handleAddSubjects()}>
                                <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                <p className='mb-0 font-semibold'><FormattedMessage id="profile.AddSubject" /></p>
                            </Stack>
                        </div>
                    </Row>

                    <Row className="categoryList p-0 m-0">
                        <Row className="parent-student-list">
                            <Col md={4} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Subject Name - English" /></p>
                            </Col>
                            <Col md={3} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Subject Name - Arabic" /></p>
                            </Col>
                            <Col md={4} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Date Added" /></p>
                            </Col>
                            <Col md={1} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Actions" /></p>
                            </Col>
                        </Row>

                        {categoryManagementList?.data?.length > 0 && categoryManagementList?.data.map((item, index) =>
                            <Row className="parent-student-list">
                                <Col md={4} className="align-center text-start">
                                    <p className="m-0 fs-20 fw-500 fc-293169  ff-InterMedium">{item?.en_name}</p>
                                </Col>
                                <Col md={3} className="align-center text-start">
                                    <p className="m-0 fs-20 fw-500 fc-293169  ff-InterMedium">{item?.ar_name}</p>
                                </Col>
                                <Col md={4} className="align-center text-center">
                                    <p className="m-0 fs-16 fw-400 fc-60656C ff-InterRegular">{item?.date_added}</p>
                                </Col>
                                <Col md={1} className="align-center text-center">
                                    <MoreHorizIcon aria-describedby={id} style={{ fill: '#3C1053', cursor: "pointer" }} onClick={(e) => handlePopoverClick(e, item)} />
                                    <CustomPopover id={id} open={open} anchorEl={anchorEl} handleClose={handlePopoverClose} options={PopoverData} />
                                </Col>
                            </Row>
                        )}
                    </Row>
                </Row>
            }

            {tab === 2 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 ps-2 pe-2">
                    <Row className="p-0 m-0">
                        <div className="flex justify-between items-center mt-2">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="Grades" />
                            </p>
                            <Stack direction="horizontal" gap={2} className="addSubjectButton flex justify-end" onClick={() => handleAddGrade()}>
                                <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                <p className='mb-0 font-semibold'><FormattedMessage id="Add Grade" /></p>
                            </Stack>
                        </div>
                    </Row>

                    <Row className="categoryList p-0 m-0">
                        <Row className="parent-student-list">
                            <Col md={4} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Subject Name - English" /></p>
                            </Col>
                            <Col md={3} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Subject Name - Arabic" /></p>
                            </Col>
                            <Col md={4} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Date Added" /></p>
                            </Col>
                            <Col md={1} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular"><FormattedMessage id="Actions" /></p>
                            </Col>
                        </Row>

                        {categoryManagementList?.data?.length > 0 && categoryManagementList?.data.map((item, index) =>
                            <Row className="parent-student-list">
                                <Col md={4} className="align-center text-start">
                                    <p className="m-0 fs-20 fw-500 fc-293169  ff-InterMedium">{item?.en_name}</p>
                                </Col>
                                <Col md={3} className="align-center text-start">
                                    <p className="m-0 fs-20 fw-500 fc-293169  ff-InterMedium">{item?.ar_name}</p>
                                </Col>
                                <Col md={4} className="align-center text-center">
                                    <p className="m-0 fs-16 fw-400 fc-60656C ff-InterRegular">{item?.date_added}</p>
                                </Col>
                                <Col md={1} className="align-center text-center">
                                    <MoreHorizIcon aria-describedby={id} style={{ fill: '#3C1053', cursor: "pointer" }} onClick={(e) => handlePopoverClick(e, item)} />
                                    <CustomPopover id={id} open={open} anchorEl={anchorEl} handleClose={handlePopoverClose} options={PopoverData} />
                                </Col>
                            </Row>
                        )}
                    </Row>
                </Row>
            }

            {/* Add Subject Modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isSubjectAddModalActive} handleClose={() => handleCloseSubjectModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2">{editSubject ? <FormattedMessage id="Edit Subject" /> : <FormattedMessage id="profile.AddSubject" />}</h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseSubjectModel()} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <LabelWithInput
                                    label={<FormattedMessage id="Subject Name - English" />}
                                    placeholder={intl.formatMessage({ id: "Subject Name - English" })}
                                    name="subjectNameEn"
                                    type="text"
                                    value={formAddSubjectValue?.subjectNameEn}
                                    onChange={(e) => handleAddSubjectChange(e)}
                                    errorText={isSubjectsError && isSubjectsError?.subjectNameEn?.error && isSubjectsError?.subjectNameEn?.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={12} className='mt-2' style={{ direction: "rtl" }}>
                                <LabelWithInput
                                    label={<FormattedMessage id="اسم الموضوع - عربي" />}
                                    placeholder={intl.formatMessage({ id: "اسم الموضوع - عربي" })}
                                    name="subjectNameAr"
                                    type="text"
                                    value={formAddSubjectValue?.subjectNameAr}
                                    onChange={(e) => handleAddSubjectChange(e)}
                                    errorText={isSubjectsError && isSubjectsError?.subjectNameAr?.error && isSubjectsError?.subjectNameAr?.errorMessage}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6} md={6} className=''>
                                <Button variant="outlined" className='cancelButton mt-5 md:mt-2 w-100' onClick={() => handleCloseSubjectModel()}><FormattedMessage id="Cancel" /></Button>
                            </Col>
                            <Col xs={6} md={6} className=''>
                                <Button variant="contained" className='button mt-5 md:mt-2 w-100' onClick={() => editSubject ? handleEditSubjectSubmit() : handleAddSubjectSubmit()}>{editSubject ? <FormattedMessage id="Save" /> : <FormattedMessage id="Add Subject" />}</Button>
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Add Subject Modal  */}

            {/* Add Grade Modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isGradeAddModalActive} handleClose={() => handleCloseGradeModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2">{editGrade ? <FormattedMessage id="Edit Grade" /> : <FormattedMessage id="Add Grade" />}</h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseGradeModel()} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <LabelWithInput
                                    label={<FormattedMessage id="Grade Name" />}
                                    placeholder={intl.formatMessage({ id: "Grade Name" })}
                                    name="gradeNameEn"
                                    type="text"
                                    value={formAddGradeValue?.gradeNameEn}
                                    onChange={(e) => handleAddGradeChange(e)}
                                    errorText={isGradesError && isGradesError?.gradeNameEn?.error && isGradesError?.gradeNameEn?.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={12} className='mt-2' style={{ direction: "rtl" }}>
                                <LabelWithInput
                                    label={<FormattedMessage id="اسم الصف" />}
                                    placeholder={intl.formatMessage({ id: "اسم الصف" })}
                                    name="gradeNameAr"
                                    type="text"
                                    value={formAddGradeValue?.gradeNameAr}
                                    onChange={(e) => handleAddGradeChange(e)}
                                    errorText={isGradesError && isGradesError?.gradeNameAr?.error && isGradesError?.gradeNameAr?.errorMessage}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6} md={6} className=''>
                                <Button variant="outlined" className='cancelButton mt-5 md:mt-2 w-100' onClick={() => handleCloseGradeModel()}><FormattedMessage id="Cancel" /></Button>
                            </Col>
                            <Col xs={6} md={6} className=''>
                                <Button variant="contained" className='button mt-5 md:mt-2 w-100' onClick={() => editGrade ? handleEditGradeSubmit() : handleAddGradeSubmit()}>{editGrade ? <FormattedMessage id="Save" /> : <FormattedMessage id="Add Grade" />}</Button>
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Add Grade Modal  */}

        </Row>
    );
};

export default CategoryManagementDetails;
