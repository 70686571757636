import React, { useContext } from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import SuccessfulImage from "assets/images/Registration/successfulIcon.svg";
import { login } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from "react-intl";


const Successful = () => {

    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);

    const handleSubmit = () => {
        navigate(login(locale));
    };


    return (
        <Container className='container'>

            <Row className='header sm:pt-20 md:pt-120 mb-5'>
                <Row className='sm:my-2 my-5'>
                    <Col xs={12} md={12} className='d-flex justify-content-center'>
                        <Image src={SuccessfulImage} alt={'image'} />
                    </Col>

                    <Col xs={12} md={12} className='d-flex justify-content-center mt-4'>
                        <h2 className='heading'><FormattedMessage id="EmailVerifiedMessage" /></h2>
                    </Col>

                    {/* <Col xs={12} md={12} className='d-flex justify-content-center mt-2 sucessfulSubheading'>
                        <p>An email has been sent to your email ID...</p>
                    </Col> */}
                </Row>
            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'><FormattedMessage id="GetStarted" /></Button>
            </Row>

        </Container>
    );
};

export default Successful;
