import React, { useEffect, useState } from "react";
import { Row, Col, Image, Stack } from "react-bootstrap";
import addIcon from "assets/images/addIcon.svg";
import editIcon from "assets/images/edit.svg";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/CategoryManagementDetails/categoryManagementDetails.scss";
import "components/feature/Admin/ManagePlanDetails/managePlan.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getManagePlanCallAPI } from "store/AdminDashboard/GetManagePlan/action";
import { GET_MANAGE_PLAN } from "store/AdminDashboard/GetManagePlan/constant";
import CommonModal from "components/common/Modal";
import LabelWithInput from "components/common/Input/LabelWithInput";
import crossIcon from "assets/images/crossIcon.svg";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { planTypeDropdownCallAPI } from "store/AdminDashboard/PlanTypeDropDown/action";
import { PLAN_TYPE_DROPDOWN } from "store/AdminDashboard/PlanTypeDropDown/constant";
import { billingCycleDropdownCallAPI } from "store/AdminDashboard/BillingCycleDropDown/action";
import { BILLING_CYCLE_DROPDOWN } from "store/AdminDashboard/BillingCycleDropDown/constant";
import { addManagePlanCallAPI } from "store/AdminDashboard/AddManagePlan/action";
import { ADD_MANAGE_PLAN } from "store/AdminDashboard/AddManagePlan/constant";
import ToastNotification from "components/common/Toast";
import checkValidation from "./checkValidation";
import { editManagePlanCallAPI } from "store/AdminDashboard/EditmanagePlan/action";
import { EDIT_MANAGE_PLAN } from "store/AdminDashboard/EditmanagePlan/constant";
import { deleteManagePlanCallAPI } from "store/AdminDashboard/DeleteManagePlan/action";
import { DELETE_MANAGE_PLAN } from "store/AdminDashboard/DeleteManagePlan/constant";


const ManagePlanDetails = () => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [conf, setConf] = ToastNotification();

    const [isModalActive, setIsModalActive] = useState(false);
    const [editPlan, setEditPlan] = useState(false);
    const [editId, setEditId] = useState("");
    const [planTypeId, setPlanTypeId] = useState(112);
    const [billingCycleId, setBillingCycleId] = useState("");
    const [isError, setIsError] = useState(false);

    const managePlanData = useSelector(state => state?.getManagePlanReducer?.payload?.data?.data);
    const planTypeData = useSelector(state => state?.planTypeDropdownReducer?.payload?.data?.data);
    const billingCycleData = useSelector(state => state?.billingCycleDropdownReducer?.payload?.data?.data);

    const [formValue, setFormValue] = useState({
        planName: "",
        price: "",
        features: ""
    });

    useEffect(() => {
        getManagePlanList();
        getPlanTypeList();
        getBillingCycleList(planTypeId);
    }, []);

    const getManagePlanList = () => {
        dispatch(getManagePlanCallAPI(GET_MANAGE_PLAN, {}, { run: true, navigate: navigate }));
    };

    const getPlanTypeList = () => {
        let body = {
            "slug": "plantype"
        }
        dispatch(planTypeDropdownCallAPI(PLAN_TYPE_DROPDOWN, body, { run: true, navigate: navigate }));
    };

    const getBillingCycleList = (id) => {
        let body = {
            "slug": "billingcycle",
            "parent_id": id
        }
        dispatch(billingCycleDropdownCallAPI(BILLING_CYCLE_DROPDOWN, body, { run: true, navigate: navigate }));
    };

    const handleCloseModel = () => {
        setIsModalActive(false);
    };

    const handleAddPlan = () => {
        setIsModalActive(true);
        setEditPlan(false);

        setFormValue((prev) => ({
            ...prev,
            ["planName"]: "",
            ["price"]: "",
            ["features"]: "",
        }));

        setPlanTypeId(112);
        setBillingCycleId("");
    };

    const handleEditPlan = (item) => {
        setIsModalActive(true);
        setEditPlan(true);
        setEditId(item?.id);

        setFormValue((prev) => ({
            ...prev,
            ["planName"]: item?.name,
            ["price"]: item?.price,
            ["features"]: item?.features,
        }));

        const PlanItem = planTypeData?.filter((obj) => obj.name === item?.plantype?.name);
        const billingCycleItem = billingCycleData?.filter((obj) => obj.name === item?.billingcycle?.name);
        setPlanTypeId(PlanItem?.[0]?.id);
        setBillingCycleId(billingCycleItem?.[0]?.id);
        getBillingCycleList(PlanItem?.[0].id);
    };

    const handleDeletePlan = (item) => {
        dispatch(deleteManagePlanCallAPI(DELETE_MANAGE_PLAN, item?.id, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }))
    };

    const handleAddPlanSubmit = () => {
        const error = checkValidation(formValue);
        setIsError(error);
        if (error.submit && billingCycleId) {
            let body = {
                "name": formValue?.planName,
                "plantype_id": planTypeId,
                "billingcycle_id": billingCycleId,
                "price": formValue?.price,
                "features": formValue?.features
            }
            dispatch(addManagePlanCallAPI(ADD_MANAGE_PLAN, body, { run: true, handleFunction: (res) => handleSuccess(res), naviagte: navigate }));
        }
    };

    const handleEditPlanSubmit = () => {
        const error = checkValidation(formValue);
        setIsError(error);
        if (error.submit && billingCycleId) {
            let body = {
                "name": formValue?.planName,
                "plantype_id": planTypeId,
                "billingcycle_id": billingCycleId,
                "price": formValue?.price,
                "features": formValue?.features
            }
            dispatch(editManagePlanCallAPI(EDIT_MANAGE_PLAN, body, { editId: editId, run: true, handleFunction: (res) => handleSuccess(res), naviagte: navigate }));
        }
    };

    const handleSuccess = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: res?.data?.message, variant: 'success' });
            setIsModalActive(false);
            getManagePlanList();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelect = (data) => {
        setPlanTypeId(data?.id);
        getBillingCycleList(data?.id);
        setBillingCycleId("");
    };

    const handleSelectBillingCycle = (data) => {
        setBillingCycleId(data?.id);
    };

    return (
        <Row className="page-categoryManagement-contents mt-5 mt-lg-0 m-0 p-0 ps-4 pe-4 pb-5 pt-4">
            <Row className="p-0 m-0">
                <div className="flex justify-between items-center mt-2 p-0">
                    <p className="m-0 fs-20 fw-600 fc-1E1E1E ff-InterSemiBold">
                        <FormattedMessage id="Manage Plans" />
                    </p>
                    <Stack direction="horizontal" gap={2} className="addSubjectButton flex justify-end" onClick={() => handleAddPlan()}>
                        <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                        <p className='mb-0 font-semibold'><FormattedMessage id="Add a Plan" /></p>
                    </Stack>
                </div>
            </Row>

            {managePlanData?.length > 0 && managePlanData.map((item, index) => (
                <Row className="planBox mt-3 p-3 m-0">
                    <div className="flex justify-between items-center mt-1 p-0 ">
                        <p className="m-0 fs-24 fw-500 fc-1E1E1E ff-InterMedium">
                            {item?.name}
                        </p>
                        <Stack direction="horizontal" gap={4} className="flex justify-end" >
                            <div className="d-flex items-center" onClick={() => handleEditPlan(item)}>
                                <Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} />
                                <p className='mb-0 buttonSelector' style={{ color: "#911E59", cursor: "pointer", }}><FormattedMessage id="Edit Plan" /></p>
                            </div>

                            {/* <Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} /> */}
                            <div className="d-flex items-center" onClick={() => handleDeletePlan(item)}>
                                <DeleteOutlinedIcon style={{ cursor: "pointer", height: '24px', width: '24px' }} />
                                <p className='mb-0 buttonSelector ' style={{ color: "#911E59", cursor: "pointer", }}><FormattedMessage id="Delete" /></p>
                            </div>
                        </Stack>
                    </div>

                    <div className="flex align-items-center mt-2 p-0 flex-wrap">
                        <p className="mr-5 mb-2 fs-18 fw-400 fc-A2A2A2 ff-InterRegular"><FormattedMessage id="Billing Cycle" />: <span className="fw-600 fc-1E1E1E ff-InterSemiBold">{item?.billingcycle?.name}</span></p>
                        <p className="mr-5 mb-2 fs-18 fw-400 fc-A2A2A2 ff-InterRegular"><FormattedMessage id="Price" />: <span className="fw-600 fc-1E1E1E ff-InterSemiBold">{item?.price} QAR</span></p>
                        <p className="mr-5 mb-2 fs-18 fw-400 fc-A2A2A2 ff-InterRegular"><FormattedMessage id="Plan Type" />: <span className="fw-600 fc-1E1E1E ff-InterSemiBold">{item?.plantype?.name}</span></p>
                    </div>

                    <div className="mt-0 p-0">
                        <p className="p-0 m-0 fs-18 fw-400 fc-A2A2A2 ff-InterRegular"><FormattedMessage id="Features" />:</p>
                        <ul className=" mt-2 m-0 ps-2 list-disc list-inside fs-18 fw-400 fc-1E1E1E ff-InterRegular">
                            <li>{item?.features} </li>
                        </ul>
                    </div>
                </Row>
            ))}

            {/* Plan Modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isModalActive} handleClose={() => handleCloseModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2">{editPlan ? <FormattedMessage id="Edit Plan" /> : <FormattedMessage id="Add Plan" />}</h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseModel()} />
                                </div>
                            </Col>

                            <Col xs={12} md={6} className='mt-2'>
                                <LabelWithInput
                                    label={<FormattedMessage id="Plan Name" />}
                                    placeholder={intl.formatMessage({ id: "Plan Name" })}
                                    name="planName"
                                    type="text"
                                    value={formValue?.planName}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError?.planName?.error && isError?.planName?.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-2'>
                                <LabelWithInput
                                    label={<FormattedMessage id="Price" />}
                                    placeholder={intl.formatMessage({ id: "Price" })}
                                    name="price"
                                    type="number"
                                    value={formValue?.price}
                                    onChange={(e) => handleChange(e)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                SAR
                                            </InputAdornment>
                                        )
                                    }}
                                    errorText={isError && isError?.price?.error && isError?.price?.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={12} className="mt-4">
                                <p className='mb-2 teacher_label'><FormattedMessage id="Plan Type" /></p>
                                <div className="flex flex-row items-center flex-wrap gap-3 plan">
                                    {planTypeData?.length > 0 && planTypeData.map((k, i) => (
                                        <p className={planTypeId === k?.id ? 'selectPlan m-0' : 'unSelectPlan m-0'} onClick={() => handleSelect(k)}>{k?.name}</p>
                                    ))}
                                </div>
                            </Col>

                            <Col xs={12} md={12} className="mt-4">
                                <p className='mb-2 teacher_label'><FormattedMessage id="Billing Cycle" /></p>
                                <div className="flex flex-row items-center flex-wrap gap-3 plan">
                                    {billingCycleData?.length > 0 && billingCycleData.map((k, i) => (
                                        <p className={billingCycleId === k?.id ? 'selectPlan m-0' : 'unSelectPlan m-0'} onClick={() => handleSelectBillingCycle(k)}>{k?.name}</p>
                                    ))}
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <LabelWithInput
                                    label={<FormattedMessage id="Features" />}
                                    placeholder={intl.formatMessage({ id: "Features" })}
                                    name="features"
                                    type="text"
                                    height="118"
                                    multiline
                                    value={formValue?.features}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError?.features?.error && isError?.features?.errorMessage}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6} md={6} className=''>
                                <Button variant="outlined" className='cancelButton mt-5 md:mt-2 w-100' onClick={() => handleCloseModel()}><FormattedMessage id="Cancel" /></Button>
                            </Col>
                            <Col xs={6} md={6} className=''>
                                <Button variant="contained" className='button mt-5 md:mt-2 w-100' onClick={() => editPlan ? handleEditPlanSubmit() : handleAddPlanSubmit()}>{editPlan ? <FormattedMessage id="Save" /> : <FormattedMessage id="Add Plan" />}</Button>
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Plan Modal  */}

        </Row>
    );
};

export default ManagePlanDetails;
