import {
    UPDATE_USER_STATUS,
} from "./constant";

export const updateUserStatusCallAPI = (type, data, external = {}) => {
    switch (type) {
        case UPDATE_USER_STATUS:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};