import { takeLatest, put } from "redux-saga/effects";
import {
    FEATURED_LIST,
    FEATURED_LIST_SUCCESS,
    FEATURED_LIST_ERROR,
} from "./constant";
import url from "services/urls.json";
import { getMethod, getMethodWithToken } from "services/apiServices";
import { ClearTokenRedirectLogin, getUserRole } from "utils/general";

function* featuredListApi(action) {

    try {
        const response = getUserRole() === 'Student' ? yield getMethodWithToken(url.getFeaturedList) : yield getMethod(url.getFeaturedList);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: FEATURED_LIST_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: FEATURED_LIST_ERROR, payload: response });
            if (response.status === 401) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response);
            }
        }

    } catch (error) {
        yield put({ type: FEATURED_LIST_ERROR, payload: error });
    }
};

function* featuredListSaga() {
    yield takeLatest(FEATURED_LIST, featuredListApi);
};

export default featuredListSaga;