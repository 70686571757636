import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Welcome from "components/layout/welcomeLayout";
import { useLocation, useNavigate } from "react-router-dom";
import ForgotScreen from "components/feature/Teacher/ForgotScreen";
import SendEmail from "components/feature/Teacher/SendEmail";
import SetPasswords from "components/feature/Teacher/SetPassword";
import { LocaleContext } from 'routes/Router';
import { login, OtpVerification, reset, resetEmail, resetPassword } from "routes/AppUrls";
import { useDispatch } from "react-redux";
import { authEmailVerificationCallApi } from "store/Auth/Forgot/EmailVerfication/action";
import { AUTH_EMAIL_VERIFICATION } from "store/Auth/Forgot/EmailVerfication/constant";
import ToastNotification from "components/common/Toast";
import OtpVerificationScreen from "components/feature/Teacher/OtpVerificationScreen";
import { authOTPVerificationCallApi } from "store/Auth/Forgot/OtpVerification/action";
import { AUTH_OTP_VERIFICATION } from "store/Auth/Forgot/OtpVerification/constant";
import { FormattedMessage } from "react-intl";

const Forgot = () => {

    const { locale } = useContext(LocaleContext);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [conf, setConf] = ToastNotification();

    const handleRedirect = () => {
        switch (location?.pathname) {
            case `/${locale}/reset`:
                navigate(login(locale));
                break;
            case `/${locale}/otp_verification`:
                navigate(reset(locale));
                break;
            case `/${locale}/reset_email`:
                navigate(OtpVerification(locale));
                break;
            case `/${locale}/reset_password`:
                navigate(OtpVerification(locale));
                // navigate(resetEmail(locale));
                break;
        }
    };

    const handleRedirectScreen = () => {
        switch (location?.pathname) {
            case `/${locale}/reset`:
                return <ForgotScreen onClick={(value) => handleEmailVerification(value)} />
            case `/${locale}/otp_verification`:
                return <OtpVerificationScreen email={email} onClick={(value) => handleOtpVerification(value)} />
            // case `/${locale}/reset_email`:
            //     return <SendEmail />
            case `/${locale}/reset_password`:
                return <SetPasswords email={email} />
        };
    };

    const handleEmailVerification = (value) => {
        setEmail(value?.email);
        let body = {
            "email": value?.email
        };
        dispatch(authEmailVerificationCallApi(AUTH_EMAIL_VERIFICATION, body, { run: true, handleFunction: (res) => handleSuccess(res, "email"), navigate: navigate, handleToastError: (res) => ToastErrorFunction(res, "email"), toast: true }))
    };

    const handleOtpVerification = (value) => {
        let body = {
            "email": email,
            "token": value
        };
        dispatch(authOTPVerificationCallApi(AUTH_OTP_VERIFICATION, body, { run: true, handleFunction: (res) => handleSuccess(res, "otp"), navigate: navigate, handleToastError: (res) => ToastErrorFunction(res, "otp"), toast: true }))
    };

    const handleSuccess = (res, value) => {
        switch (value) {
            case 'email':
                if (res?.data?.status_code === 200) {
                    setConf({ msg: res?.data?.data, variant: 'success' });
                    navigate(OtpVerification(locale));
                } else {
                    setConf({ msg: res?.data?.data, variant: 'error' });
                }
                break;
            case 'otp':
                if (res?.data?.status_code === 200) {
                    setConf({ msg: res?.data?.message, variant: 'success' });
                    navigate(resetPassword(locale));
                } else {
                    setConf({ msg: res?.data?.message, variant: 'error' });
                }
                break;
        };
    };

    const ToastErrorFunction = (res, value) => {
        switch (value) {
            case 'email':
                setConf({ msg: res?.data?.message, variant: 'error' });
                break;
            case 'otp':
                setConf({ msg: res?.data?.message, variant: 'error' });
                break;
        };
    };

    return (
        <Container fluid>
            <Row>
                <Col md={6} style={{ padding: 0, margin: 0 }}>
                    <Welcome onClick={handleRedirect} heading={<FormattedMessage id="ForgotYourPassword" />} />
                </Col>
                <Col md={6} sm={12} className="right_Container">
                    <Row>
                        <Col xxl={1}>
                            {/* Empty space on the left */}
                        </Col>
                        <Col xxl={10}>
                            {handleRedirectScreen()}
                        </Col>
                        <Col xxl={1}>
                            {/* Empty space on the right */}
                        </Col>
                    </Row>
                </Col>
            </Row >
        </Container >
    )
};

export default Forgot;