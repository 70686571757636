import React, { useContext } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { LocaleContext } from 'routes/Router';
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import logoIcon from "assets/images/Navbar/logo.svg";
import facebookWhiteColorIcon from "assets/images/Navbar/facebookWhiteColorIcon.png";
import twitterWhiteColorIcon from "assets/images/Navbar/twitterWhiteColorIcon.png";
import InstaWhiteColorIcon from "assets/images/Navbar/InstaWhiteColorIcon.png";


const Footer = () => {
    const { locale } = useContext(LocaleContext);

    const handleScrollTop = () => {
        window.scrollTo({
            top: 850,
            behavior: 'smooth'
        });
    };

    return (
        <Row className="mt-5 bc-F7F5EF py-5 p-0 m-0">
            <Col md={{ span: 11 }} xl={{ span: 11 }} className={'mx-auto'}>
                <Row>
                    <Col md={{ span: 12 }} lg={{ span: 4 }}>
                        <div className="d-flex align-items-center mb-lg-4">
                            <img
                                src={logoIcon}
                                alt="Logo"
                                width="40"
                                height="30"
                                className={`d-inline-block align-top ${locale === "en" ? 'mr-2' : 'me-2'} items-center`}
                            />
                            <p className='p-0 m-0 fs-24 fw-600 ff-LexendDecaRegular fc-1E1E1E'><FormattedMessage id="MazadEdu" /></p>
                        </div>
                    </Col>
                    <Col md={{ span: 12 }} lg={{ span: 3 }} className="mt-4 mt-lg-0">
                        <h2 className="fs-24 fc-911E59 fw-600 ff-LexendDecaMedium mb-lg-4"><FormattedMessage id="About" /></h2>
                        <p className="fs-20 fc-1E1E1E fw-500 ff-InterRegular"><FormattedMessage id="Howitworks" /></p>
                        <p className="fs-20 fc-1E1E1E fw-400 ff-InterRegular cursor" onClick={handleScrollTop}><FormattedMessage id="Featured" /></p>
                        <p className="fs-20 fc-1E1E1E fw-400 ff-InterRegular"><FormattedMessage id="Privacy&Policy" /></p>
                        <p className="fs-20 fc-1E1E1E fw-400 ff-InterRegular"><FormattedMessage id="Terms&Condition" /></p>
                    </Col>
                    <Col md={{ span: 12 }} lg={{ span: 3 }} className="mt-4 mt-lg-0">
                        <h2 className="fs-24 fc-911E59 fw-600 ff-LexendDecaMedium mb-lg-4"><FormattedMessage id="Community" /></h2>
                        <p className="fs-20 fc-1E1E1E fw-500 ff-InterRegular"><FormattedMessage id="Events" /></p>
                        <p className="fs-20 fc-1E1E1E fw-400 ff-InterRegular"><FormattedMessage id="Blog" /></p>
                    </Col>
                    <Col md={{ span: 12 }} lg={{ span: 2 }} className="mt-4 mt-lg-0">
                        <h2 className="fs-24 fc-911E59 fw-600 ff-LexendDecaMedium mb-lg-4"><FormattedMessage id="Socials" /></h2>
                        <Stack direction="horizontal" gap={3} className="items-center mt-3">
                            <img src={facebookWhiteColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                            <p className="fs-20 fc-1E1E1E fw-400 ff-InterRegular m-0"><FormattedMessage id="Facebook" /></p>
                        </Stack>
                        <Stack direction="horizontal" gap={3} className="items-center mt-3">
                            <img src={twitterWhiteColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                            <p className="fs-20 fc-1E1E1E fw-400 ff-InterRegular m-0"><FormattedMessage id="Twitter" /></p>
                        </Stack>
                        <Stack direction="horizontal" gap={3} className="items-center mt-3">
                            <img src={InstaWhiteColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                            <p className="fs-20 fc-1E1E1E fw-400 ff-InterRegular m-0"><FormattedMessage id="Instagram" /></p>
                        </Stack>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Footer;
