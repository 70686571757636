import {
    GET_QUESTIONS, GET_QUESTIONS_ERROR, GET_QUESTIONS_SUCCESS,
} from "./constant";

const getQuestionsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_QUESTIONS:
            return { ...state, loading: true, error: '' };
        case GET_QUESTIONS_ERROR:
            return { ...state, type: GET_QUESTIONS_ERROR, loading: false, error: action.payload };
        case GET_QUESTIONS_SUCCESS:
            return { ...state, type: GET_QUESTIONS_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default getQuestionsReducer;