import {
    ADD_TEACHER_SUBJECT,
} from "./constant";

export const addTeacherSubjectCallAPI = (type, data, external = {}) => {
    switch (type) {
        case ADD_TEACHER_SUBJECT:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};