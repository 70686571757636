import {
    ASSESSMENT_VERIFICATION_EMAIL,
} from "./constant";

export const assessmentVerificationCallAPI = (type, data, external = {}) => {
    switch (type) {
        case ASSESSMENT_VERIFICATION_EMAIL:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};