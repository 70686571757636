import {
    ASSESSMENT_VERIFICATION_EMAIL,
    ASSESSMENT_VERIFICATION_EMAIL_ERROR,
    ASSESSMENT_VERIFICATION_EMAIL_SUCCESS,
} from "./constant";

const assessmentVerificationReducer = (state = {}, action) => {
    switch (action.type) {
        case ASSESSMENT_VERIFICATION_EMAIL:
            return { ...state, loading: true, error: '' };
        case ASSESSMENT_VERIFICATION_EMAIL_ERROR:
            return { ...state, type: ASSESSMENT_VERIFICATION_EMAIL_ERROR, loading: false, error: action.payload };
        case ASSESSMENT_VERIFICATION_EMAIL_SUCCESS:
            return { ...state, type: ASSESSMENT_VERIFICATION_EMAIL_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default assessmentVerificationReducer;