import {
    TEACHER_SEARCH,
    TEACHER_SEARCH_ERROR,
    TEACHER_SEARCH_SUCCESS,
} from "./constant";

const teacherSearchReducer = (state = {}, action) => {
    switch (action.type) {
        case TEACHER_SEARCH:
            return { ...state, loading: true, error: '' };
        case TEACHER_SEARCH_ERROR:
            return { ...state, type: TEACHER_SEARCH_ERROR, loading: false, error: action.payload };
        case TEACHER_SEARCH_SUCCESS:
            return { ...state, type: TEACHER_SEARCH_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default teacherSearchReducer;