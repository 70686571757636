import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import CustomNavbar from 'components/feature/Navbar';
import TeacherProfileDetails from 'components/feature/Student/TeacherProfileDetails';

const TeacherProfile = () => {


    return (
        <Container fluid>
            <Row>
                <CustomNavbar />
            </Row>
            <Row>
                <TeacherProfileDetails />
            </Row>
        </Container>
    );
};

export default TeacherProfile;