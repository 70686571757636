import {
    EDIT_FAQ,
} from "./constant";

export const editFaqCallAPI = (type, data, external = {}) => {
    switch (type) {
        case EDIT_FAQ:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};