import { FormattedMessage } from "react-intl";

const checkValidation = value => {

    const formError = {
        submit: true,
        planName: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        price: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        features: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
    };

    const emptyKeys = [];
    for (let key in value) {
        emptyKeys.push(key);
    };


    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }
    return formError;
}

export default checkValidation;