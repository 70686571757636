import { Col, Image, Row, Stack } from "react-bootstrap";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import aboutImage from "assets/images/Navbar/aboutUsImage.png";
import phoneColorIcon from "assets/images/Navbar/phoneColorIcon.png";
import mailColorIcon from "assets/images/Navbar/mailColorIcon.png";
import locationColorIcon from "assets/images/Navbar/locationColorIcon.png";
import facebookColorIcon from "assets/images/Navbar/facebookColorIcon.png";
import twitterColorIcon from "assets/images/Navbar/twitterColorIcon.png";
import InstaColorIcon from "assets/images/Navbar/InstaColorIcon.png";

const AboutUsScreen = () => {

    return (
        <>
            <Row className="mt-5 mb-5 justify-content-center m-0 p-0">
                <Col lg={{ span: 9 }} className="mx-auto bc-FFFFFF rounded-[32px] p-5">
                    <Row className="justify-content-center">
                        <h2 className="fs-40 fc-911E59 fw-600 ff-LexendDecaSemibold"><FormattedMessage id="About Us" /></h2>
                        <p className="mt-4 pt-2 fs-20 fc-282938 fw-600 m-0 ff-LexendDecaSemibold"><FormattedMessage id="Tentang Kursus" /></p>
                        <p className="fs-16 fc-282938 fw-400 ff-InterRegular">
                            Lorem ipsum dolor sit amet consectetur. Cras diam volutpat hac morbi. Pellentesque fusce at amet mauris cras. Quam scelerisque aliquam purus sodales suspendisse ut pellentesque. Risus habitant enim phasellus consectetur congue nulla neque quis. Elementum ornare risus magna integer varius duis sit. Mi odio in imperdiet lacus.
                            Sit netus sed et eu ullamcorper. Eu id quis arcu pellentesque ultrices leo elementum sodales sed. Nisi vivamus pretium habitasse lobortis auctor. Massa id egestas rhoncus neque. Etiam id in nam quis aliquet a nibh amet vel. Nulla commodo interdum et in nullam. Sed volutpat pharetra tortor ultrices sit luctus aenean aliquam sed. Lectus diam proin adipiscing egestas cras suscipit viverra lorem. Egestas bibendum enim vel at nibh. Vulputate netus montes blandit quisque blandit nulla. Eget varius risus sapien vel.                        </p>
                    </Row>

                    <Row className="d-flex justify-content-center align-items-center">
                        <Col lg={6} className="mt-4">
                            <Image src={aboutImage} alt="image" style={{ width: '-webkit-fill-available' }} />
                        </Col>

                        <Col lg={6} className="mt-4">
                            <h2 className="fs-20 fc-282938 fw-600 ff-LexendDecaSemibold"><FormattedMessage id="Proin adipiscing" /></h2>
                            <ul className="list-disc ">
                                <li className="fs-16 fc-282938 fw-400 ff-InterRegular">
                                    Kelas ditujukan untuk siswa dengan rasa keingin tahuan tinggi dalam bidang pemrograman website
                                </li>
                                <li className="fs-16 fc-282938 fw-400 ff-InterRegular">
                                    Kelas ini memberikan sertifikat ketika siswa bisa menyelesaikan setiap kursus yang ia pelajari
                                </li>
                                <li className="fs-16 fc-282938 fw-400 ff-InterRegular">
                                    Diharapkan siswa bisa menyelesaikan semua tugas yang telah diberikan di akhir kursus                                </li>
                                <li className="fs-16 fc-282938 fw-400 ff-InterRegular">
                                    Kelas ini didesain untuk pemula sehingga tidak ada prasyarat dalam pemahaman pemrograman sebelumnya.                                 </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className="d-flex mt-5 justify-content-center">
                        <h2 className="fs-20 fc-282938 fw-600 ff-LexendDecaSemibold"><FormattedMessage id="Vulputate eget aliquam" /></h2>
                        <p className="fs-16 fc-282938 fw-400 ff-InterRegular">
                            Sit netus sed et eu ullamcorper. Eu id quis arcu pellentesque ultrices leo elementum sodales sed. Nisi vivamus pretium habitasse lobortis auctor. Massa id egestas rhoncus neque. Etiam id in nam quis aliquet a nibh amet vel. Nulla commodo interdum et in nullam. Sed volutpat pharetra tortor ultrices sit luctus aenean aliquam sed. Lectus diam proin adipiscing egestas cras suscipit viverra lorem. Egestas bibendum enim vel at nibh. Vulputate netus montes blandit quisque blandit nulla. Eget varius risus sapien vel.
                        </p>
                    </Row>

                    <Row className="mt-4 pt-2">
                        <h2 className="fs-24 fc-911E59 fw-600 ff-LexendDecaSemibold"><FormattedMessage id="Contact Us" /></h2>
                        <Col lg={6} className="mt-4">
                            <Stack direction="horizontal" gap={2} className="flex items-center">
                                <img src={mailColorIcon} alt="icon" style={{ height: "32px", width: "32px" }} />
                                <p className="fs-18 fw-400 fc-1E1E1E ff-InterRegular m-0">{'contactmail1@mazad.com'}| {'contactmail2@mazad.com'}</p>
                            </Stack>
                        </Col>
                        <Col lg={6} className="mt-4">
                            <Stack direction="horizontal" gap={2} className="d-flex align-items-start">
                                <img src={locationColorIcon} alt="icon" style={{ height: "32px", width: "32px" }} />
                                <p className="fs-18 fw-400 fc-1E1E1E ff-InterRegular m-0">{'40236 Parker Corners, Jadeborough 10775-0149 Grant Garden, North Sisterburgh 18177-9926'}</p>
                            </Stack>
                        </Col>
                        <Col lg={6} className="mt-4">
                            <Stack direction="horizontal" gap={2} className="items-center">
                                <img src={phoneColorIcon} alt="icon" style={{ height: "32px", width: "32px" }} />
                                <p className="fs-18 fw-400 fc-1E1E1E ff-InterRegular m-0">{'+44 3214569871'}| {'+01 3214569871'}</p>
                            </Stack>
                        </Col>
                    </Row>

                    <Row className="d-flex mt-4 pt-2">
                        <h2 className="fs-20 fc-282938 fw-600 ff-LexendDecaSemibold"><FormattedMessage id="Socials" /></h2>
                        <Stack direction="horizontal" gap={3} className="items-center mt-3">
                            <img src={facebookColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                            <img src={twitterColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                            <img src={InstaColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                        </Stack>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default AboutUsScreen;