import { takeLatest, put } from "redux-saga/effects";
import {
    ASSESSMENT_VERIFICATION_EMAIL,
    ASSESSMENT_VERIFICATION_EMAIL_SUCCESS,
    ASSESSMENT_VERIFICATION_EMAIL_ERROR,
} from "./constant";
import url from "services/urls.json";
import { postMethodWithToken } from "services/apiServices";
import { ClearTokenRedirectLogin } from "utils/general";

function* postAssessmentVerificationEmail(action) {

    try {
        const response = yield postMethodWithToken(url.resendVerification, action.data);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: ASSESSMENT_VERIFICATION_EMAIL_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: ASSESSMENT_VERIFICATION_EMAIL_ERROR, payload: response });
            if (response.status === 401) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response);
            }
        }

    } catch (error) {
        yield put({ type: ASSESSMENT_VERIFICATION_EMAIL_ERROR, payload: error });
    }
}

function* assessmentVerificationSaga() {
    yield takeLatest(ASSESSMENT_VERIFICATION_EMAIL, postAssessmentVerificationEmail);
}

export default assessmentVerificationSaga;