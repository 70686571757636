import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Welcome from "components/layout/welcomeLayout";
import RegistrationForm from "components/feature/Teacher/RegistrationForm";
import { useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";
import { getLandingURL } from "routes/AppUrls";

const Registration = () => {

    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);

    const handleRedirect = () => {
        navigate(getLandingURL(locale));
    };

    return (
        <Container fluid>
            <Row style={{height:'100vh'}}>
                <Col md={6} style={{ padding: 0, margin: 0 }}>
                    <Welcome onClick={handleRedirect} />
                </Col>
                <Col md={6} sm={12} className="right_Container">
                    <Row>
                        <Col xxl={1}>
                            {/* Empty space on the left */}
                        </Col>
                        <Col xxl={10}>
                            <RegistrationForm />
                        </Col>
                        <Col xxl={1}>
                            {/* Empty space on the right */}
                        </Col>
                    </Row>
                </Col>
            </Row >
        </Container >
    )
};

export default Registration;