import {
    GET_CATEGORY_MANAGEMENT_DATA,
} from "./constant";

export const getCategoryManagementDataCallAPI = (type, data, external = {}) => {
    switch (type) {
        case GET_CATEGORY_MANAGEMENT_DATA:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};