import {
    SUBMIT_QUESTIONS, SUBMIT_QUESTIONS_ERROR, SUBMIT_QUESTIONS_SUCCESS,
} from "./constant";

const SubmitQuestionsReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBMIT_QUESTIONS:
            return { ...state, loading: true, error: '' };
        case SUBMIT_QUESTIONS_ERROR:
            return { ...state, type: SUBMIT_QUESTIONS_ERROR, loading: false, error: action.payload };
        case SUBMIT_QUESTIONS_SUCCESS:
            return { ...state, type: SUBMIT_QUESTIONS_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default SubmitQuestionsReducer;