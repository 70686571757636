import {
    GET_CONTENT_MANAGEMENT_DATA,
} from "./constant";

export const getContentManagementDataCallAPI = (type, data, external = {}) => {
    switch (type) {
        case GET_CONTENT_MANAGEMENT_DATA:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};