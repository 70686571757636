import {
    CONTACT_US,
    CONTACT_US_ERROR,
    CONTACT_US_SUCCESS,
} from "./constant";

const contactUsReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_US:
            return { ...state, loading: true, error: '' };
        case CONTACT_US_ERROR:
            return { ...state, type: CONTACT_US_ERROR, loading: false, error: action.payload };
        case CONTACT_US_SUCCESS:
            return { ...state, type: CONTACT_US_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default contactUsReducer;