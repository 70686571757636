import {
    AUTH_RESEND,
    AUTH_RESEND_ERROR,
    AUTH_RESEND_SUCCESS,
} from "./constant";

const authResendReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTH_RESEND:
            return { ...state, loading: true, error: '' };
        case AUTH_RESEND_ERROR:
            return { ...state, type: AUTH_RESEND_ERROR, loading: false, error: action.payload };
        case AUTH_RESEND_SUCCESS:
            return { ...state, type: AUTH_RESEND_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default authResendReducer;