import { AUTH_EMAIL_ERROR_VERIFICATION, AUTH_EMAIL_SUCCESS_VERIFICATION, AUTH_EMAIL_VERIFICATION } from "./constant";

const authEmailVerificationReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTH_EMAIL_VERIFICATION:
            return { ...state, loading: true, error: '' };
        case AUTH_EMAIL_SUCCESS_VERIFICATION:
            return { ...state, type: AUTH_EMAIL_SUCCESS_VERIFICATION, loading: false, error: '', payload: action.payload };
        case AUTH_EMAIL_ERROR_VERIFICATION:
            return { ...state, type: AUTH_EMAIL_ERROR_VERIFICATION, loading: false, error: action.pyload };
        default:
            return state;
    }
};

export default authEmailVerificationReducer;