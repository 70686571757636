import {
    EDIT_FAQ,
    EDIT_FAQ_ERROR,
    EDIT_FAQ_SUCCESS,
} from "./constant";

const editFaqDataReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_FAQ:
            return { ...state, loading: true, error: '' };
        case EDIT_FAQ_ERROR:
            return { ...state, type: EDIT_FAQ_ERROR, loading: false, error: action.payload };
        case EDIT_FAQ_SUCCESS:
            return { ...state, type: EDIT_FAQ_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default editFaqDataReducer;