import Error404 from 'components/layout/Error404';
import CategoryManagement from 'pages/CategoryManagement';
import AdminDashboard from 'pages/Dashboard/AdminDashboard';
import TeacherDashboard from 'pages/Dashboard/TeacherDashboard';
import InterviewRound from 'pages/InterviewRound';
import LandingPage from 'pages/LandingPage';
import ManagePlan from 'pages/ManagePlan';
import Profile from 'pages/Profile';
import PushNotification from 'pages/PushNotification';
import Questionnaire from 'pages/Questionnaire';
import Forgot from 'pages/Register/Teacher/Forgot';
import Login from 'pages/Register/Teacher/Login';
import PersonalDetails from 'pages/Register/Teacher/PersonalDetails';
import Professional from 'pages/Register/Teacher/Professional';
import ProfileSetups from 'pages/Register/Teacher/ProfileSetups';
import RegisterSuccessful from 'pages/Register/Teacher/RegisterSuccessful';
import Registration from 'pages/Register/Teacher/Registration';
import SentEmail from 'pages/Register/Teacher/SentEmail';
import SetPassword from 'pages/Register/Teacher/SetPassword';
import TeacherDetail from 'pages/Register/Teacher/TeacherDetail';
import SearchFilter from 'pages/Student/SearchFilter';
import TeacherProfile from 'pages/Student/TeacherProfile';
import InternalTeacher from 'pages/UserManagement/InternalTeacher';
import ParentStudent from 'pages/UserManagement/ParentStudent';
import TeacherUserManagement from 'pages/UserManagement/Teacher';
import StudentProfile from 'pages/Student/StudentProfile';

import {
    getLandingURL,
    getTeacherDetails,
    login,
    OtpVerification,
    pageNotFound,
    personalDetails,
    professionalDetails,
    profile,
    profileSetup,
    questions,
    reset,
    resetEmail,
    resetPassword,
    sentEmail,
    setPassword,
    successful,
    teacherDashboard,
    studentDashboard,
    register,
    InterViewRound,
    adminLogin,
    adminDashboard,
    parentStudentUserManagement,
    internalTeacherUserManagement,
    teacherUserManagement,
    categoryManagement,
    pushNotification,
    managePlan,
    filterStudentScreen,
    teacherProfileDetails,
    studentProfile,
    AboutUsNav,
    ContactUsNav,
    featureListDetails,
    support,
    contentManagement
} from './AppUrls';
import AboutUs from 'pages/Student/AboutUs';
import ContactUs from 'pages/Student/ContactUs';
import FeatureListDetailScreen from 'components/feature/Student/featureListDetailScreen';
import Support from 'pages/Support';
import ContentManagement from 'pages/ContentManagement';

const publicRoutes = [
    {
        path: locale => getLandingURL(locale),
        element: <LandingPage />,
        isNoSidebar: true

    },
    {
        path: locale => register(locale),
        element: <Registration />,
        isNoSidebar: true

    },
    {
        path: locale => getTeacherDetails(locale),
        element: <TeacherDetail />,
        isNoSidebar: true

    },
    {
        path: locale => pageNotFound(locale),
        element: <Error404 />,
        isNoSidebar: true

    },
    {
        path: locale => personalDetails(locale),
        element: <PersonalDetails />,
        isNoSidebar: true

    },
    {
        path: locale => setPassword(locale),
        element: <SetPassword />,
        isNoSidebar: true

    },
    {
        path: locale => professionalDetails(locale),
        element: <Professional />,
        isNoSidebar: true

    },
    {
        path: locale => sentEmail(locale),
        element: <SentEmail />,
        isNoSidebar: true

    },
    {
        path: locale => profileSetup(locale),
        element: <ProfileSetups />,
        isNoSidebar: true

    },
    {
        path: locale => successful(locale),
        element: <RegisterSuccessful />,
        isNoSidebar: true

    },
    {
        path: locale => login(locale),
        element: <Login />,
        isNoSidebar: true

    },
    {
        path: locale => adminLogin(locale),
        element: <Login />,
        isNoSidebar: true

    },
    {
        path: locale => reset(locale),
        element: <Forgot />,
        isNoSidebar: true

    },
    {
        path: locale => resetEmail(locale),
        element: <Forgot />,
        isNoSidebar: true

    },
    {
        path: locale => resetPassword(locale),
        element: <Forgot />,
        isNoSidebar: true

    },
    {
        path: locale => OtpVerification(locale),
        element: <Forgot />,
        isNoSidebar: true
    },
];

const privateRoutes = [
    {
        path: locale => teacherDashboard(locale),
        element: <TeacherDashboard />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Teacher', 'Administrator'],

    },
    {
        path: locale => questions(locale),
        element: <Questionnaire />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Teacher'],

    },
    {
        path: locale => profile(locale),
        element: <Profile />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Teacher'],

    },
    {
        path: locale => InterViewRound(locale),
        element: <InterviewRound />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Teacher', 'Administrator'],

    },
    {
        path: locale => studentDashboard(locale),
        element: <LandingPage />,
        isNoSidebar: true,
        isPrivate: true,
        allowedRoles: ['Student'],

    },
    {
        path: locale => filterStudentScreen(locale),
        element: <SearchFilter />,
        isNoSidebar: true,
        isPrivate: true,
        allowedRoles: ['Student'],

    },
    {
        path: locale => teacherProfileDetails(locale),
        element: <TeacherProfile />,
        isNoSidebar: true,
        isPrivate: true,
        allowedRoles: ['Student'],

    },
    {
        path: locale => studentProfile(locale),
        element: <StudentProfile />,
        isNoSidebar: true,
        isPrivate: true,
        allowedRoles: ['Student'],

    },
    {
        path: locale => adminDashboard(locale),
        element: <AdminDashboard />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
    {
        path: locale => parentStudentUserManagement(locale),
        element: <ParentStudent />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
    {
        path: locale => internalTeacherUserManagement(locale),
        element: <InternalTeacher />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
    {
        path: locale => teacherUserManagement(locale),
        element: <TeacherUserManagement />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
    {
        path: locale => categoryManagement(locale),
        element: <CategoryManagement />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
    {
        path: locale => contentManagement(locale),
        element: <ContentManagement />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
    {
        path: locale => pushNotification(locale),
        element: <PushNotification />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
    {
        path: locale => managePlan(locale),
        element: <ManagePlan />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
    {
        path: locale => support(locale),
        element: <Support />,
        isNoSidebar: false,
        isPrivate: true,
        allowedRoles: ['Administrator'],

    },
];

const commonRoutes = [
    {
        path: locale => AboutUsNav(locale),
        element: <AboutUs />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    },
    {
        path: locale => ContactUsNav(locale),
        element: <ContactUs />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    },
    {
        path: locale => featureListDetails(locale),
        element: <FeatureListDetailScreen />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true,
    },
];

export const allRoutes = [...publicRoutes, ...privateRoutes, ...commonRoutes];