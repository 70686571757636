import {
    BOOK_INTERVIEW_SLOT
} from "./constant";

export const bookInterViewSlotCallAPI = (type, data, external = {}) => {
    switch (type) {
        case BOOK_INTERVIEW_SLOT:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};