import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'assets/styles/common.scss';
import EmailVerification from 'components/feature/TeacherDashboard/EmailVerification';
import QuestionAnswer from 'components/feature/Questionnaire';
import { submitQuestionsCallAPI } from 'store/Dashboard/SubmitQuestions/action';
import { SUBMIT_QUESTIONS } from 'store/Dashboard/SubmitQuestions/constant';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import QuestionAnswerResult from 'components/feature/QuestionnaireSuccess';
import Assessment from 'components/feature/Assessment';

const Questionnaire = () => {

    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleStep = (data) => {
        let body = {
            "test_id": data?.test_id,
            "submitted_answers": data?.submitted_answers
        }
        dispatch(submitQuestionsCallAPI(SUBMIT_QUESTIONS, body, { run: true, handleFunction: (res) => handleRedirect(res), navigate: navigate }));
    };

    const handleRedirect = () => {
        setStep(2);
    };


    const renderStepContent = () => {
        switch (step) {
            case 1:
                return <QuestionAnswer onClick={(val) => handleStep(val)} />;
            case 2:
                return <QuestionAnswerResult onClick={(val) => setStep(3)} />;
            case 3:
                return <Assessment onClick={(val) => setStep(1)}/>;
            default:
                return <QuestionAnswer onClick={(val) => handleStep(val)} />;
        }
    };

    return (
        <Container fluid className='dashboard_container'>
            <Row>
                <Col lg={2}>
                    {/* Empty space on the left */}
                </Col>
                <Col lg={9} className="mt-lg-5 mb-lg-5">
                    {renderStepContent()}
                </Col>
                <Col lg={1}>
                    {/* Empty space on the right */}
                </Col>
            </Row>
        </Container>
    );
}

export default Questionnaire;