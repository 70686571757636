import {
    ADD_TEACHER_SUBJECT,
    ADD_TEACHER_SUBJECT_ERROR,
    ADD_TEACHER_SUBJECT_SUCCESS,
} from "./constant";

const addTeacherSubjectReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_TEACHER_SUBJECT:
            return { ...state, loading: true, error: '' };
        case ADD_TEACHER_SUBJECT_ERROR:
            return { ...state, type: ADD_TEACHER_SUBJECT_ERROR, loading: false, error: action.payload };
        case ADD_TEACHER_SUBJECT_SUCCESS:
            return { ...state, type: ADD_TEACHER_SUBJECT_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default addTeacherSubjectReducer;