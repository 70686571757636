import { takeLatest, put } from "redux-saga/effects";
import {
    GET_PROFILE,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR
} from "./constant";
import url from "services/urls.json";
import { getMethodWithToken } from "services/apiServices";
import { ClearTokenRedirectLogin, getUserRole } from "utils/general";

function* getProfile(action) {

    try {
        let response;
        if (getUserRole() === 'Administrator' && (window?.location?.pathname === "/" + action?.external?.locale + "/teacher_dashboard")) {
            response = yield getMethodWithToken(url.teacherProfile + action?.data);
        } else {
            response = yield getMethodWithToken(url.myProfile);
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: GET_PROFILE_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: GET_PROFILE_ERROR, payload: response });
            if (response.status === 401) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        }

    } catch (error) {
        yield put({ type: GET_PROFILE_ERROR, payload: error });
    }
}

function* getProfileSaga() {
    yield takeLatest(GET_PROFILE, getProfile);
}

export default getProfileSaga;