import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const WrapperTextField = styled(TextField)`
	width: 100%;
	margin-top: ${(props) => props.marginLabelTop ? `${props.marginLabelTop}px` : "20px !important"};

	.MuiInputLabel-root {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		transform: none;
		color: #1E1E1E;
		font-family: Inter_SemiBold;
		top: -5px;
		width:100%;
	}

	.MuiInputLabel-root.Mui-focused  {
		color: #1E1E1E;
	}

	.MuiFormControl-root{
		position:relative;
	}

	.MuiInput-root {
		border: 1px solid ${(props) => props.errorText ? '#EE3232' : '#DDDDDD'};
		border-radius: 8px;
		${(props) => !props.multiline && `
			height: ${props.height ? `${props.height}px` : '50px'};
			padding: 15px;
		`}
		margin-top: ${(props) => props.marginTop ? `${props.marginTop}px` : "25px"};
		font-family: Inter_Regular;
		font-size: 16px;
		font-weight: 400;
		line-height: 16px;

		${(props) =>
		props.bgColor && `
	 	    background-color:  ${props.theme[props.bgColor]};
	    `}

	    ${(props) =>
		props.borderNone && `
	 	    border: none;
	    `}

		&:before, &:after {
			border: none !important;
		}

		.MuiInput-input {
			color: #1E1E1E;
			cursor:  ${(props) => props.cursor === 'not-allowed' ? 'not-allowed' : "pointer"};
			font-family: Inter_Regular;
			font-size: 16px;
			font-weight: 400;
			line-height: 16px;
			padding: ${(props) => props.multiline ? '15px' : '0'};
			
			${(props) => props.multiline && `
				min-height: ${props.height ? `${props.height}px` : '80px'}; /* For multiline, define a min height */
				line-height: 1.5; /* Better line spacing for multiline content */
				overflow-y:auto !important;
			    max-height: ${props.height ? `${props.height}px !important` : '80px'};
			`}
		}
	}

	.MuiInput-root.Mui-focused {
		border: 1px solid #911E59;
		color: ${(props) => props.theme.darkSecondary};

		.MuiInput-input {
			color: ${(props) => props.theme.darkSecondary};
		}

		&:before, &:after {
			border: none !important;
		}
	}
`;

const WrapperErrorDiv = styled.div`
	font-size: 16px;
	color: #EE3232;
	line-height: 24px;
	font-weight: 400;
	font-family: Inter_Regular;
	margin-top: 5px;
	position:absolute;
	bottom:-30px;
`;

const WrapperHelperDiv = styled.div`
	color: #A2A2A2;
	margin-top: 2px;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	font-family: Inter_Regular;
`;

function LabelWithInput({ containerProps, ...props }) {
	return (
		<FormControl fullWidth>
			<WrapperTextField
				id="standard-required"
				variant="standard"
				fullWidth={true}
				InputLabelProps={{
					shrink: true,
				}}
				multiline={props.multiline}
				{...props}
			/>

			{props.errorText &&
				<WrapperErrorDiv>
					{props.errorText}
				</WrapperErrorDiv>
			}

			{props.helperInfoPassword &&
				<WrapperHelperDiv>
					<div>
						<FormattedMessage id="register.helperText" />
					</div>
				</WrapperHelperDiv>
			}
		</FormControl>
	);
}

LabelWithInput.defaultProps = {
	borderRadius: 4,
	fontSize: 16,
	textColor: "#1E1E1E"
};

export default LabelWithInput;
