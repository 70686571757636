import {
    DELETE_SUBJECT_GRADE_ADMIN,
    DELETE_SUBJECT_GRADE_ADMIN_ERROR,
    DELETE_SUBJECT_GRADE_ADMIN_SUCCESS,
} from "./constant";

const deleteSubjectGradeReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_SUBJECT_GRADE_ADMIN:
            return { ...state, loading: true, error: '' };
        case DELETE_SUBJECT_GRADE_ADMIN_ERROR:
            return { ...state, type: DELETE_SUBJECT_GRADE_ADMIN_ERROR, loading: false, error: action.payload };
        case DELETE_SUBJECT_GRADE_ADMIN_SUCCESS:
            return { ...state, type: DELETE_SUBJECT_GRADE_ADMIN_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default deleteSubjectGradeReducer;