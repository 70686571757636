import {
    COUNTRY_DROPDOWN,
    COUNTRY_DROPDOWN_ERROR,
    COUNTRY_DROPDOWN_SUCCESS,
} from "./constant";

const countryDropDownReducer = (state = {}, action) => {
    switch (action.type) {
        case COUNTRY_DROPDOWN:
            return { ...state, loading: true, error: '' };
        case COUNTRY_DROPDOWN_ERROR:
            return { ...state, type: COUNTRY_DROPDOWN_ERROR, loading: false, error: action.payload };
        case COUNTRY_DROPDOWN_SUCCESS:
            return { ...state, type: COUNTRY_DROPDOWN_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default countryDropDownReducer;