import { AUTH_RESET_ERROR_PASSWORD, AUTH_RESET_PASSWORD, AUTH_RESET_SUCCESS_PASSWORD } from "./constant";

const authResetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTH_RESET_PASSWORD:
            return { ...state, loading: true, error: '' };
        case AUTH_RESET_SUCCESS_PASSWORD:
            return { ...state, type: AUTH_RESET_SUCCESS_PASSWORD, loading: false, error: '', payload: action.payload };
        case AUTH_RESET_ERROR_PASSWORD:
            return { ...state, type: AUTH_RESET_ERROR_PASSWORD, loading: false, error: action.pyload };
        default:
            return state;
    }
};

export default authResetPasswordReducer;