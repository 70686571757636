import {
    STUDENT_REVIEW,
} from "./constant";

export const studentReviewCallAPI = (type, data, external = {}) => {
    switch (type) {
        case STUDENT_REVIEW:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};