import React from 'react';
import { Row, Container } from 'react-bootstrap';
import CustomNavbar from 'components/feature/Navbar';
import ContactUsScreen from 'components/feature/Student/ConatctUsScreen';

const ContactUs = () => {

    return (
        <Container fluid>
            <Row>
                <CustomNavbar />
            </Row>
            <Row >
                <ContactUsScreen />
            </Row>
        </Container>
    );
};

export default ContactUs;