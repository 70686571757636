import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Image, Stack, Badge, Card } from "react-bootstrap";
import 'assets/styles/common.scss';
import backIcon from "assets/images/backArrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";
import { useDispatch, useSelector } from "react-redux";
import { filterStudentScreen } from "routes/AppUrls";
import TeacherImage from "assets/images/Availability/teacherImage.svg";
import likeIcon from "assets/images/Studentdashboard/heartIcon.svg";
import callIcon from "assets/images/Studentdashboard/callIcon.svg";
import starIcon from "assets/images/Studentdashboard/starIcon.svg";
import verifiedIcon from "assets/images/verified.svg";
import "components/feature/Student/TeacherProfileDetails/teacherProfileDetails.scss"
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RatingProgressBar from "components/common/CustomProgressBar";
import { Button } from "@mui/material";
import emailIcon from "assets/images/Studentdashboard/emailIcon.svg";
import phoneIcon from "assets/images/Studentdashboard/phoneIcon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { TEACHER_FULL_PROFILE } from "store/StudentDashboard/TeacherFullProfile/constant";
import { teacherFullProfileCallAPI } from "store/StudentDashboard/TeacherFullProfile/action";
import CommonModal from "components/common/Modal";
import { Box } from "@mui/material";
import crossIcon from "assets/images/crossIcon.svg";
import LabelWithInput from "components/common/Input/LabelWithInput";
import CheckValidation from "./checkValidation";
import { STUDENT_REVIEW } from "store/StudentDashboard/StudentReview/constant";
import { studentReviewCallAPI } from "store/StudentDashboard/StudentReview/action";
import ToastNotification from "components/common/Toast";

const TeacherProfileDetails = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const intl = useIntl();
    const { locale } = useContext(LocaleContext);
    const [isModalActive, setIsModalActive] = useState(false);
    const [rating, setRating] = useState(0);
    const [isError, setIsError] = useState(false);
    const [conf, setConf] = ToastNotification();

    const [formValue, setformValue] = useState({
        review: "",
    });

    const TeacherFullProfileData = useSelector(state => state?.teacherFullProfileReducer?.payload?.data?.data);

    useEffect(() => {
        getFullProfileTeacherDetails();
    }, []);

    const getFullProfileTeacherDetails = () => {
        dispatch(teacherFullProfileCallAPI(TEACHER_FULL_PROFILE, location?.state?.id, { run: true, navigate: navigate }));
    };

    const handleClick = () => {
        navigate(filterStudentScreen(locale));
    };

    const handleClose = () => {
        setIsModalActive(false);
    };

    const handleRating = (rate) => {
        setRating(rate);
    };

    const handleModalOpen = () => {
        setRating(0);
        setIsError(false);
        setformValue((prev) => ({
            ...prev,
            ["review"]: ""
        }))
        setIsModalActive(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            let body = {
                "teacher_id": location?.state?.id,
                "rating": rating,
                "note": formValue?.review
            }
            dispatch(studentReviewCallAPI(STUDENT_REVIEW, body, { run: true, handleFunction: (res) => handleSuccss(res), navigate: navigate }))
        }
    };

    const handleSuccss = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: res?.data?.message, variant: 'success' });
            setIsModalActive(false);
            getFullProfileTeacherDetails();
        }
    };

    return (

        <Row className="bc-FDFCFA px-0 px-lg-5 m-0 p-0 pb-5" >
            <Col>
                <Row className="m-0 mt-4">
                    <Stack direction="horizontal" gap={3} className="items-center ps-0">
                        <img src={backIcon} alt="Back Icon" className="backArrow" style={{ cursor: "pointer" }} onClick={handleClick} />
                        <p className="p-0 m-0 fs-24 fw-500 ff-LexendDecaMedium fc-1E1E1E"><FormattedMessage id="Teacher’sDetail" /></p>
                    </Stack>
                </Row>

                <Row className="m-0 mt-3">
                    <Col md={8} className={locale === "en" ? "ps-0 pe-0 pe-md-2" : "pe-0 ps-0 ps-md-2"}>
                        <Row className="m-0 boxShadow">
                            <div className="flex">
                                <div className="me-3">
                                    <img
                                        src={TeacherFullProfileData?.profile_image !== null ? TeacherFullProfileData?.profile_image : TeacherImage}
                                        alt="Teacher"
                                        className="imgs-fluid"
                                    />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="fs-24 fw-500 ff-LexendDecaMedium fc-1E1E1E">{TeacherFullProfileData?.name}</h5>
                                        <Stack direction="horizontal" gap={3} className="items-center ">
                                            <p className="p-0 m-0 fs-20 fw-700 ff-InterBold fc-1E1E1E"><FormattedMessage id="QAR2.5" /> <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E"><FormattedMessage id="persession" /></span></p>
                                            <img src={likeIcon} alt="Like Icon" style={{ cursor: "pointer" }} />
                                        </Stack>
                                    </div>
                                    <p className="fs-18 fw-400 ff-InterRegular fc-212426 mb-1 mt-3">{TeacherFullProfileData?.qualificaitons?.[0]?.name}
                                        <span className="border-left-only ms-2 ps-2">{TeacherFullProfileData?.subjects?.[0]?.experience + " years of experience"}</span>
                                    </p>
                                    <div className="flex flex-wrap mt-3">
                                        <Stack direction="horizontal" gap={2} className="items-center">
                                            <img src={starIcon} alt="Star Icon" />
                                            <p className="p-0 m-0 fs-18 fw-400 ff-InterRegular fc-212426">{`${TeacherFullProfileData?.average_rating === null ? intl.formatMessage({ id: "NoReview" }) : TeacherFullProfileData?.average_rating}` + `${TeacherFullProfileData?.totol_reviews > 0 ? " (" + TeacherFullProfileData?.totol_reviews + ")" : ""}`} </p>
                                        </Stack>

                                        {/* <Stack direction="horizontal" gap={2} className="items-center">
                                            <img src={callIcon} alt="Call Icon" style={{ cursor: "pointer" }} onClick={handleClick} />
                                            <p className="p-0 m-0 fs-18 fw-400 ff-InterRegular fc-212426">{"690 calls"}</p>
                                        </Stack> */}
                                    </div>

                                </div>
                            </div>

                            <div className="mt-4">
                                <p className="p-0 m-0 fs-20 fw-500 ff-LexendDecaMedium fc-911E59"><FormattedMessage id="ProfileSummary" /></p>
                                <p className="p-0 m-0 fs-16 fw-400 ff-InterRegular fc-A2A2A2 mt-2">{TeacherFullProfileData?.bio}</p>
                            </div>

                            {/* <div className="mt-4">
                                <p className="p-0 m-0 fs-20 fw-500 ff-LexendDecaMedium fc-911E59">Specialised In</p>
                                <ul className="ps-2 mt-2 list-disc list-inside">
                                    <li className="fs-16 fw-400 ff-InterRegular fc-A2A2A2">Lorem ipsum dolor sit amet consectetur.</li>
                                    <li className="fs-16 fw-400 ff-InterRegular fc-A2A2A2">Lorem ipsum dolor sit amet consectetur.</li>
                                    <li className="fs-16 fw-400 ff-InterRegular fc-A2A2A2">Lorem ipsum dolor sit amet consectetur.</li>
                                    <li className="fs-16 fw-400 ff-InterRegular fc-A2A2A2">Lorem ipsum dolor sit amet consectetur.</li>
                                    <li className="fs-16 fw-400 ff-InterRegular fc-A2A2A2">Lorem ipsum dolor sit amet consectetur.</li>
                                    <li className="fs-16 fw-400 ff-InterRegular fc-A2A2A2">Lorem ipsum dolor sit amet consectetur.</li>
                                    <li className="fs-16 fw-400 ff-InterRegular fc-A2A2A2">Lorem ipsum dolor sit amet consectetur.</li>
                                </ul>
                            </div> */}
                        </Row>

                        <Row className="m-0 boxShadow mt-3">
                            <Col md={4}>
                                <p className="p-0 m-0 fs-20 fw-500 ff-LexendDecaMedium fc-911E59"><FormattedMessage id="WhatStudentsSay" /></p>
                                <p className="p-0 m-0 fs-32 fw-700 ff-InterBold fc-0F172A ">{TeacherFullProfileData?.average_rating}</p>
                                <Stack direction="horizontal" spacing={1}>
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <div
                                            key={star}
                                            className={star <= TeacherFullProfileData?.average_rating ? "starfill" : "starUnfill"}
                                        >
                                            {star <= TeacherFullProfileData?.average_rating ? (
                                                <StarIcon fontSize="medium" />
                                            ) : (
                                                <StarBorderIcon fontSize="medium" />
                                            )}
                                        </div>
                                    ))}
                                </Stack>
                                <p className="p-0 m-0 fs-16 fw-400 ff-InterRegular fc-8E9193 mt-2">{TeacherFullProfileData?.totol_reviews}</p>
                                <div className="mt-4">
                                    {TeacherFullProfileData?.rating_count?.length > 0 && TeacherFullProfileData?.rating_count.map((data, index) => (
                                        <RatingProgressBar
                                            key={index}
                                            rating={index}
                                            value={(TeacherFullProfileData?.rating_count?.[index] / 100) * 100} // Converts to percentage
                                            count={TeacherFullProfileData?.rating_count?.[index]}
                                        />
                                    ))}
                                </div>
                                <p className="p-0 m-0 fs-18 fw-500 ff-InterMedium fc-000000 mt-4"><FormattedMessage id="Shareyourexperience" /></p>
                                <Button variant="outlined" className="cancelButton mt-3" onClick={() => handleModalOpen()}>
                                    <FormattedMessage id="Leaveareview" />
                                </Button>
                            </Col>

                            <Col md={8}>
                                {TeacherFullProfileData?.review?.length > 0 && TeacherFullProfileData?.review.map((item, index) => (
                                    <div className={`border-bottom-only pb-3 ${index > 0 ? "mt-3" : ""}`} >
                                        <div className="flex items-center">
                                            <div className="me-3">
                                                <img
                                                    src={item?.student_profile_image !== null ? item?.student_profile_image : TeacherImage}
                                                    alt="Teacher"
                                                    style={{ height: "60px", width: "60px", maxWidth: "60px", borderRadius: "100%" }}
                                                />
                                            </div>

                                            <div style={{ width: '100%' }}>
                                                <h5 className="m-0 p-0 fs-18 fw-600 ff-LexendDecaMedium fc-0F172A">{item?.student_name}</h5>
                                                <p className="m-0 p-0 fs-14 fw-400 ff-InterRegular fc-A2A2A2 mt-2">{item?.date}</p>
                                            </div>
                                        </div>
                                        <Stack direction="horizontal" spacing={1} className="mt-3 items-center">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <div
                                                    key={star}
                                                    className={star <= item?.rating ? "starfill" : "starUnfill"}
                                                >
                                                    {star <= item?.rating ? (
                                                        <StarIcon fontSize="medium" />
                                                    ) : (
                                                        <StarBorderIcon fontSize="medium" />
                                                    )}
                                                </div>
                                            ))}
                                            <p className="m-0 p-0 fs-18 fw-600 ff-InterSemiBold fc-0F172A mt-2 ps-2 flex items-center">{item?.rating}</p>
                                        </Stack>
                                        <p className="p-0 m-0 fs-16 fw-400 ff-InterRegular fc-A2A2A2 mt-2">{item?.note}</p>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4} className={locale === "en" ? "pe-0 mt-3 mt-md-0 ps-0 ps-md-2" : "ps-0 pe-0 pe-md-2 mt-3 mt-md-0"}>
                        <Row className="m-0 boxShadow ">
                            <div className="mt-0">
                                <p className="p-0 m-0 fs-20 fw-500 ff-LexendDecaMedium fc-911E59"> <FormattedMessage id="ContactInformation" /></p>
                                <div className="d-flex justify-between mt-2">
                                    <Stack direction="horizontal" gap={2} className="items-center">
                                        <img src={emailIcon} alt="Email Icon" />
                                        <p className="p-0 m-0 fs-18 fw-400 ff-InterRegular fc-A2A2A2"><FormattedMessage id="EmailID" />:</p>
                                    </Stack>
                                    <p className="p-0 m-0 fs-18 fw-400 ff-InterRegular fc-1E1E1E">{TeacherFullProfileData?.email}</p>
                                </div>
                                <div className="d-flex justify-between mt-2">
                                    <Stack direction="horizontal" gap={2} className="items-center">
                                        <img src={phoneIcon} alt="Call Icon" />
                                        <p className="p-0 m-0 fs-18 fw-400 ff-InterRegular fc-A2A2A2"><FormattedMessage id="Phoneno" />.</p>
                                    </Stack>
                                    <p className="p-0 m-0 fs-18 fw-400 ff-InterRegular fc-1E1E1E">{TeacherFullProfileData?.phone}</p>
                                </div>
                            </div>

                            <div className="mt-4">
                                <p className="p-0 m-0 fs-20 fw-500 ff-LexendDecaMedium fc-911E59"><FormattedMessage id="Subjects" /></p>
                                {TeacherFullProfileData?.subjects?.length > 0 && TeacherFullProfileData?.subjects.map((result, index) => (
                                    result?.status?.slug === "verified" &&
                                    <div className="subjectsBox mt-3">
                                        <div className="flex align-items-center justify-between">
                                            <p className="mb-0 mr-3 fs-16 fw-600 ff-InterSemiBold fc-1E1E1E">{result?.name}</p>
                                            <Badge pill className={"verifiedPill d-flex items-center text-center"}>
                                                <img src={verifiedIcon} alt={"badge"} className="me-2" />
                                                {result?.status?.name}
                                            </Badge>
                                        </div>
                                        <div className="flex align-items-center mt-3 flex-wrap">
                                            <p className="mr-5 fs-16 fw-400 ff-InterRegular fc-A2A2A2"><FormattedMessage id="Grade" />: <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E">{result?.grade?.name}</span></p>
                                            <p className="mr-5 fs-16 fw-400 ff-InterRegular fc-A2A2A2"><FormattedMessage id="Material" />: <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E">{result?.teaching_material?.name}</span></p>
                                            <p className="mr-5 fs-16 fw-400 ff-InterRegular fc-A2A2A2"><FormattedMessage id="Language" />: <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E">{result?.language?.name}</span></p>
                                            <p className="mr-5 fs-16 fw-400 ff-InterRegular fc-A2A2A2"><FormattedMessage id="Experience" />: <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E">{result?.experience} {'year'}</span></p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Row>
                    </Col>
                </Row>

                {/* Review modal  */}
                <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isModalActive} handleClose={handleClose}
                    innerContent={
                        <Box >
                            <Row>
                                <Col xs={12} md={12}>
                                    <div className="flex justify-between">
                                        <h2 className="heading2"><FormattedMessage id="Review" /></h2>
                                        <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={handleClose} />
                                    </div>
                                </Col>

                                <Col xs={12} md={12} className="flex flex-col items-center justify-center mt-4">
                                    <p className="p-0 m-0 fs-18 fw-500 fc-1E1E1E ff-LexendDecaMedium"><FormattedMessage id="Youroverallreview" /></p>
                                    <Stack direction="horizontal" spacing={1} className="mt-3 items-center justify-center">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <div
                                                key={star}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleRating(star)}
                                                className={star <= rating ? "starfill" : "starUnfill"}
                                            >
                                                {star <= rating ? (
                                                    <StarIcon fontSize="large" />
                                                ) : (
                                                    <StarBorderIcon fontSize="large" />
                                                )}
                                            </div>
                                        ))}
                                    </Stack>

                                </Col>

                                <Col xs={12} md={12} className='mt-4'>
                                    <LabelWithInput
                                        label={<FormattedMessage id="Adddetailedreview" />}
                                        placeholder={intl.formatMessage({ id: "Writehere..." })}
                                        height="150"
                                        multiline
                                        name="review"
                                        value={formValue.review}
                                        onChange={(e) => handleChange(e)}
                                        errorText={isError && isError.review.error && isError.review.errorMessage}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-5 md:mt-2">
                                <Col>
                                    <Button variant="outlined" className='cancelButton w-100' onClick={() => handleClose()} ><FormattedMessage id="Cancel" /></Button>
                                </Col>
                                <Col>
                                    <Button variant="contained" onClick={() => handleSubmit()} className='button w-100'><FormattedMessage id="SubmitReview" /></Button>
                                </Col>
                            </Row>
                        </Box>
                    }
                />
                {/* Review modal  */}

            </Col >
        </Row >
    );
};

export default TeacherProfileDetails;