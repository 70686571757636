import {
    SUPPORT_LIST,
    SUPPORT_LIST_ERROR,
    SUPPORT_LIST_SUCCESS,
} from "./constant";

const supportListReducer = (state = {}, action) => {
    switch (action.type) {
        case SUPPORT_LIST:
            return { ...state, loading: true, error: '' };
        case SUPPORT_LIST_ERROR:
            return { ...state, type: SUPPORT_LIST_ERROR, loading: false, error: action.payload };
        case SUPPORT_LIST_SUCCESS:
            return { ...state, type: SUPPORT_LIST_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default supportListReducer;