import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import SelectInput from 'components/common/Input/SelectInput';
import 'assets/styles/common.scss';
import CheckValidation from "components/feature/Teacher/PersonalDetails/validation";
import { countryDropDownCallAPI } from 'store/Auth/Registration/Country/action';
import { COUNTRY_DROPDOWN } from 'store/Auth/Registration/Country/constant';
import { useDispatch, useSelector } from 'react-redux';
import { stateDropDownCallAPI } from 'store/Auth/Registration/State/action';
import { STATE_DROPDOWN } from 'store/Auth/Registration/State/constant';
import { cityDropDownCallAPI } from 'store/Auth/Registration/City/action';
import { CITY_DROPDOWN } from 'store/Auth/Registration/City/constant';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "components/feature/Teacher/PersonalDetails/PersonalDetails.scss";
import { setPassword } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from "react-intl";
import { PERSONAL_DETAILS } from 'store/BasicDetails/RegisterationDetails/constant';
import { PersonalDetail } from 'store/BasicDetails/RegisterationDetails/actions';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const PersonalDetails = () => {

    const navigate = useNavigate();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { locale } = useContext(LocaleContext);
    const [isError, setIsError] = useState(false);

    const [formValue, setformValue] = useState({
        fullName: "",
        country: "",
        state: "",
        city: "",
        email: "",
        phoneNumber: ""
    });

    const [formId, setformId] = useState({
        fullName: "",
        country: "",
        state: "",
        city: "",
        email: "",
        phoneNumber: ""
    });

    const countryList = useSelector(state => state?.countryDropDownReducer?.payload?.data);
    const stateList = useSelector(state => state?.stateDropDownReducer?.payload?.data);
    const cityList = useSelector(state => state?.cityDropDownReducer?.payload?.data);
    const Roles = useSelector(state => state?.RegistrationDetailsReducer?.userRoles);

    useEffect(() => {
        getCountryListApi();
    }, []);

    const getCountryListApi = () => {
        let body = {
            "slug": "country"
        };
        dispatch(countryDropDownCallAPI(COUNTRY_DROPDOWN, body, { navigate: navigate }));
    };

    const handleSubmit = () => {
        if (Roles !== "teacher") {
            delete formValue?.country;
            delete formValue?.state;
            delete formValue?.city;
        }
        const error = CheckValidation(formValue);

        setIsError(error);
        if (error.submit && isPhoneNumberValid) {
            dispatch(PersonalDetail(PERSONAL_DETAILS, { personalDetailsData: formId }, { navigate: navigate }));
            navigate(setPassword(locale));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
        setformId((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setformValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));

        setformId((prev) => ({
            ...prev,
            [name]: value?.id
        }))

        if (name === "country") {
            let body = {
                "slug": "state",
                "parent_id": value?.id
            };
            dispatch(stateDropDownCallAPI(STATE_DROPDOWN, body, { navigate: navigate }));
            setformValue((prev) => ({
                ...prev,
                ["state"]: "",
                ['city']: ""
            }));
        }
        if (name === "state") {
            let body = {
                "slug": "city",
                "parent_id": value?.id
            };
            dispatch(cityDropDownCallAPI(CITY_DROPDOWN, body, { navigate: navigate }));
            setformValue((prev) => ({
                ...prev,
                ['city']: ""
            }));
        }
    };

    ////////////////////////////////countryCode///////////////////////////////

    const [countryCode, setCountryCode] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const phoneNumberInputRef = useRef(null);

    const handleCountryCodeChange = (value, country) => {
        setCountryCode(`+${country.dialCode}`);
        phoneNumberInputRef.current.focus();
    };

    const handlePhoneNumberChange = (e) => {
        const phoneNumberValue = e.target.value;
        setformValue((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));

        setformId((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));

        const fullPhoneNumber = `${countryCode}${phoneNumberValue}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

        if (parsedPhoneNumber) {
            setIsPhoneNumberValid(parsedPhoneNumber.isValid());
        } else {
            setIsPhoneNumberValid(false);
        }
    };
    ////////////////////////////////countryCode///////////////////////////////

    return (
        <Container className='contain_page'>

            <Row className='pt-20  md:pt-120 mb-5'>
                <Row className='mb-5'>
                    <Col>
                        <h2 className='heading'><FormattedMessage id="register.PersonalDetails" /></h2>
                    </Col>
                </Row>

                <Col xs={12} md={12} className='mt-4'>
                    <LabelWithInput
                        label={<FormattedMessage id="register.FullName" />}
                        placeholder={intl.formatMessage({ id: "register.FullName" })}
                        name="fullName"
                        type="text"
                        value={formValue?.fullName}
                        onChange={(e) => handleChange(e)}
                        errorText={isError && isError.fullName.error && isError.fullName.errorMessage}
                    />
                </Col>

                {Roles === "teacher" &&
                    <>
                        <Col xs={12} md={6} className='mt-3'>
                            <SelectInput
                                label={<FormattedMessage id="register.Country" />}
                                placeholder={intl.formatMessage({ id: "register.Country" })}
                                name={"country"}
                                value={formValue?.country}
                                data={countryList?.data}
                                onChange={(e) => handleInputChange(e)}
                                errorText={isError && isError.country.error && isError.country.errorMessage}
                            />
                        </Col>

                        <Col xs={12} md={6} className='mt-3'>
                            <SelectInput
                                label={<FormattedMessage id="register.State" />}
                                placeholder={intl.formatMessage({ id: "register.State" })}
                                name={"state"}
                                value={formValue?.state}
                                data={stateList?.data}
                                onChange={(e) => handleInputChange(e)}
                                errorText={isError && isError.state.error && isError.state.errorMessage}
                            />
                        </Col>

                        <Col xs={12} md={6} className='mt-3'>
                            <SelectInput
                                label={<FormattedMessage id="register.City" />}
                                placeholder={intl.formatMessage({ id: "register.City" })}
                                name={"city"}
                                value={formValue?.city}
                                data={cityList?.data}
                                onChange={(e) => handleInputChange(e)}
                                errorText={isError && isError.city.error && isError.city.errorMessage}
                            />
                        </Col>
                    </>
                }

                <Col xs={12} md={Roles === "teacher" ? 6 : 12} className='mt-3'>
                    <LabelWithInput
                        label={<FormattedMessage id="register.EmailId" />}
                        placeholder={intl.formatMessage({ id: "register.EmailId" })}
                        name="email"
                        type="text"
                        value={formValue?.email}
                        onChange={(e) => handleChange(e)}
                        errorText={isError && isError.email.error && isError.email.errorMessage}
                    />
                </Col>

                <Col xs={12} md={12} className='mt-3'>
                    <div className="phone-number-container">
                        <label className="phone-label"><FormattedMessage id="PhoneNumber" /></label>
                        <div className="phone-input-wrapper">

                            <div className={locale === "en" ? "phone-en-react me-4 ms-0" : "phone-ar-react ms-4 me-0"}>
                                <PhoneInput
                                    country={'qa'}
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                    inputProps={{
                                        name: 'countryCode',
                                        required: true,
                                    }}
                                    placeholder="country"
                                    inputClass="custom-country-input"
                                    disableCountryCode={true}
                                    disableDropdown={false}
                                    onlyCountries={['qa', 'us', 'in', 'gb']}
                                />
                            </div>

                            <div className="phone-number-input">
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    value={formValue?.phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    ref={phoneNumberInputRef}
                                    placeholder="e.g: 8521364783"
                                    className={`custom-phone-input ${!isPhoneNumberValid ? 'invalid' : ''}`}
                                />
                            </div>
                        </div>

                        {isError && isError.phoneNumber.error && isError.phoneNumber.errorMessage ?
                            <p className="error-message">{isError.phoneNumber.errorMessage}</p>
                            :
                            !isPhoneNumberValid ?
                                <p className="error-message"><FormattedMessage id="Invalidphonenumberforselectedcountrycode" /></p>
                                :
                                ""
                        }

                    </div>
                </Col>
            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'><FormattedMessage id="register.Continue" /></Button>
            </Row>
        </Container>
    );
};

export default PersonalDetails;
