import { PASSWORD_DETAILS, PERSONAL_DETAILS, PROFESSIONAL_DETAILS, USER_ROLES } from "./constant";

export const PersonalDetail = (type, data) => {
    switch (type) {
        case PERSONAL_DETAILS:
            return {
                type: type,
                data: data
            }
    }
};

export const PasswordDetail = (type, data) => {
    switch (type) {
        case PASSWORD_DETAILS:
            return {
                type: type,
                data: data
            }
    }
};

export const ProfessionalDetail = (type, data) => {
    switch (type) {
        case PROFESSIONAL_DETAILS:
            return {
                type: type,
                data: data
            }
    }
};

export const UserRoles = (type, data) => {
    switch (type) {
        case USER_ROLES:
            return {
                type: type,
                data: data
            }
    }
};
