import {
    ADD_CONTENT_MANAGEMENT_DATA,
} from "./constant";

export const addContentManagementDataCallAPI = (type, data, external = {}) => {
    switch (type) {
        case ADD_CONTENT_MANAGEMENT_DATA:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};