import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Image, Stack, Badge, Card } from "react-bootstrap";
import 'assets/styles/common.scss';
import backIcon from "assets/images/backArrow.png";
import { useNavigate } from "react-router-dom";
import { getLandingURL, teacherProfileDetails } from "routes/AppUrls";
import { LocaleContext } from "routes/Router";
import "components/feature/Student/serachFilterScreen/searchFilter.scss";
import LabelWithInput from "components/common/Input/LabelWithInput";
import InputAdornment from '@mui/material/InputAdornment';
import searchIcon from "assets/images/Navbar/search.svg";
import { useIntl, FormattedMessage } from "react-intl";
import SelectInput from "components/common/Input/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import TeacherImage from "assets/images/Availability/teacherImage.svg";
import book from "assets/images/Availability/book.svg";
import recordVoice from "assets/images/Availability/record_voice_over.svg";
import Button from '@mui/material/Button';
import { gradeLevelDropDownCallAPI } from "store/Auth/Registration/GreadeLevel/action";
import { GRADE_LEVEL_DROPDOWN } from "store/Auth/Registration/GreadeLevel/constant";
import { subjectDropDownCallAPI } from "store/Auth/Registration/Subject/action";
import { SUBJECT_DROPDOWN } from "store/Auth/Registration/Subject/constant";
import { LANGUAGE_DROPDOWN } from "store/Auth/Registration/Language/constant";
import { languageDropDownCallAPI } from "store/Auth/Registration/Language/action";
import { teacherSearchCallAPI } from "store/StudentDashboard/TeacherSearch/action";
import { TEACHER_SEARCH } from "store/StudentDashboard/TeacherSearch/constant";

const SearchFilterScreen = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { locale } = useContext(LocaleContext);

    const TeacherSearchList = useSelector(state => state?.teacherSearchReducer?.payload?.data?.data);
    const GradeLevelList = useSelector(state => state?.gradeLevelDropDownReducer?.payload?.data);
    const SubjectList = useSelector(state => state?.subjectDropDownReducer?.payload?.data);
    const LanguageList = useSelector(state => state?.languageDropDownReducer?.payload?.data);

    const [searchData, setSearchData] = useState(TeacherSearchList);

    const [formValue, setFormValue] = useState({
        gradeLevel: "",
        language: "",
        experience: "",
        subject: "",
        search: ""
    });

    const [formId, setFormId] = useState({
        gradeLevel: "",
        language: "",
        subject: ""
    });

    useEffect(() => {
        getGradeLevelListApi();
        getSubjectListApi();
        getLanguageListApi();
    }, []);

    const getGradeLevelListApi = () => {
        let body = {
            "slug": "gradlevel"
        };
        dispatch(gradeLevelDropDownCallAPI(GRADE_LEVEL_DROPDOWN, body, { navigate: navigate }));
    };

    const getSubjectListApi = () => {
        let body = {
            "slug": "subject"
        };
        dispatch(subjectDropDownCallAPI(SUBJECT_DROPDOWN, body, { navigate: navigate }));
    };

    const getLanguageListApi = () => {
        dispatch(languageDropDownCallAPI(LANGUAGE_DROPDOWN, {}, { navigate: navigate }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e?.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));
        setFormId((prev) => ({
            ...prev,
            [name]: value?.id
        }));

        switch (name) {
            case "subject":
                getFilterCallApi(value?.name, formValue?.gradeLevel, formValue?.language, formValue?.experience);
                break;
            case "gradeLevel":
                getFilterCallApi(formValue?.subject, value?.name, formValue?.language, formValue?.experience);
                break;
            case "language":
                getFilterCallApi(formValue?.subject, formValue?.gradeLevel, value?.name, formValue?.experience);
                break;
        }


    };

    const getFilterCallApi = (subject, grade, board, experience) => {
        let body = {
            "filters": {
                "subject": subject,
                "grade": grade,
                "board": board,
                "experience": experience
            }
        }
        dispatch(teacherSearchCallAPI(TEACHER_SEARCH, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
    };

    const handleSuccess = (res) => {
        if (res?.status === 200) {
            setSearchData(res?.data?.data);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value
        }));

        if (name === "experience") {
            getFilterCallApi(formValue?.subject, formValue?.gradeLevel, formValue?.language, value);
        }

        if (name === "search" && value !== "") {
            const filteredUsers = TeacherSearchList && TeacherSearchList.filter((user) => user?.teacher_name?.toLowerCase()?.includes(value?.toLowerCase()));
            setSearchData(filteredUsers);
        } else {
            setSearchData(TeacherSearchList);
        }
    };

    const handleClick = () => {
        navigate(getLandingURL(locale));
    };

    return (
        <Row className="bc-FDFCFA px-0 px-lg-5 m-0 p-0 mb-5" >
            <Col>
                <Row className="filter bc-FFFFFF m-0 mt-4 pt-2 ">
                    <Stack direction="horizontal" gap={3} className="items-center ">
                        <img src={backIcon} alt="Back Icon" className="backArrow" style={{ cursor: "pointer" }} onClick={handleClick} />
                        <p className="p-0 m-0 fs-24 fw-500 ff-LexendDecaMedium fc-1E1E1E"><FormattedMessage id="Searchforteacher" /></p>
                    </Stack>

                    <Col md={8} className="mt-4">
                        <LabelWithInput
                            placeholder={intl.formatMessage({ id: "SearchTeacherName" })}
                            name="search"
                            type="text"
                            marginTop="0"
                            marginLabelTop="0"
                            height="56"
                            value={formValue?.search}
                            onChange={(e) => handleChange(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Image src={searchIcon} alt="icon" style={{ cursor: "pointer" }} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Col>

                    <Col md={8} className="mt-3">
                        <Row className="d-flex flex-wrap">
                            <Stack direction="horizontal" gap={3} className="items-center ">
                                <p className="p-0 m-0 fs-20 fw-600 ff-InterSemiBold fc-000000"><FormattedMessage id="Filters" />:</p>

                                <SelectInput
                                    placeholder={intl.formatMessage({ id: "register.SelectASubject" })}
                                    name="subject"
                                    marginTop="0"
                                    marginLabelTop="0"
                                    value={formValue?.subject}
                                    data={SubjectList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                />

                                <SelectInput
                                    placeholder={intl.formatMessage({ id: "register.GradeLevel" })}
                                    name={"gradeLevel"}
                                    marginTop="0"
                                    marginLabelTop="0"
                                    value={formValue?.gradeLevel}
                                    data={GradeLevelList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                />

                                <LabelWithInput
                                    placeholder={intl.formatMessage({ id: "Experience" })}
                                    name="experience"
                                    type="number"
                                    marginTop="0"
                                    marginLabelTop="0"
                                    value={formValue?.experience}
                                    onChange={(e) => handleChange(e)}
                                />

                                <SelectInput
                                    placeholder={intl.formatMessage({ id: "register.Language" })}
                                    name={"language"}
                                    marginTop="0"
                                    marginLabelTop="0"
                                    value={formValue?.language}
                                    data={LanguageList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </Stack>
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-4 pt-2 m-0">
                    <h2 className="fs-20 fw-600 ff-LexendDecaSemibold fc-334155"><FormattedMessage id="Top3SearchResults" /></h2>
                </Row>

                <Row className="m-0 filterShadow">
                    {searchData?.length > 0 && searchData.map((item, index) => (
                        index < 3 &&
                        <Card className={`mt-3 shadow border-0`}>
                            <Card.Body>
                                <Row>
                                    <Col md={12} className="flex items-center">
                                        <div className="me-3">
                                            <img
                                                src={item?.teacher_profile_image ? item?.teacher_profile_image : TeacherImage}
                                                alt="Teacher"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="fs-24 fw-500 ff-LexendDecaMedium fc-1E1E1E">{item?.teacher_name}</h5>
                                                <p className="p-0 m-0 fs-20 fw-700 ff-InterBold fc-1E1E1E"><FormattedMessage id="QAR2.5" /> <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E"><FormattedMessage id="persession" /></span></p>
                                            </div>

                                            <p className="fs-18 fw-400 ff-InterRegular fc-212426 mb-1 mt-3">{item?.qualifications?.[0]?.university_name} | {item?.experience + " years of experience"}</p>

                                            <div className="d-flex justify-content-between">
                                                <div className="flex flex-wrap mt-3">
                                                    <p className="fs-18 fw-500 ff-InterMedium fc-4D4F51 mb-1 me-2 flex items-center">
                                                        <badgeClasses className="badge me-2">
                                                            <img src={recordVoice} style={{ minHeight: "24px", minWidth: "24px" }} />
                                                        </badgeClasses> {item?.language}
                                                    </p>
                                                    <p className="fs-18 fw-500 ff-InterMedium fc-4D4F51  mb-1 flex items-center">
                                                        <Badge className="badge me-2">
                                                            <img src={book} style={{ minHeight: "24px", minWidth: "24px" }} />
                                                        </Badge> {item?.grade_name} {item?.subject_name}
                                                    </p>
                                                </div>

                                                <Button variant="outlined" className="cancelButton" onClick={() => navigate(teacherProfileDetails(locale), { state: { id: item?.teacher_id } })}>
                                                    <FormattedMessage id="ViewFullProfile" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ))}
                </Row>

                <Row className="mt-5 pt-4 m-0">
                    <h2 className="fs-20 fw-600 ff-LexendDecaSemibold fc-334155"><FormattedMessage id="Moreresults" /></h2>
                </Row>

                <Row className="m-0 filterShadow">
                    {searchData?.length > 0 && searchData.map((item, index) => (
                        index > 3 &&
                        <Card className={`mt-3 shadow border-0`}>
                            <Card.Body>
                                <Row>
                                    <Col md={12} className="flex items-center">
                                        <div className="me-3">
                                            <img
                                                src={item?.teacher_profile_image ? item?.teacher_profile_image : TeacherImage}
                                                alt="Teacher"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="fs-24 fw-500 ff-LexendDecaMedium fc-1E1E1E">{item?.teacher_name}</h5>
                                                <p className="p-0 m-0 fs-20 fw-700 ff-InterBold fc-1E1E1E"><FormattedMessage id="QAR2.5" /> <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E"><FormattedMessage id="persession" /></span></p>
                                            </div>

                                            <p className="fs-18 fw-400 ff-InterRegular fc-212426 mb-1 mt-3">{item?.qualifications?.[0]?.university_name} | {item?.experience + " years of experience"}</p>

                                            <div className="d-flex justify-content-between">
                                                <div className="flex flex-wrap mt-3">
                                                    <p className="fs-18 fw-500 ff-InterMedium fc-4D4F51 mb-1 me-2 flex items-center">
                                                        <badgeClasses className="badge me-2">
                                                            <img src={recordVoice} style={{ minHeight: "24px", minWidth: "24px" }} />
                                                        </badgeClasses> {item?.language}
                                                    </p>
                                                    <p className="fs-18 fw-500 ff-InterMedium fc-4D4F51  mb-1 flex items-center">
                                                        <Badge className="badge me-2">
                                                            <img src={book} style={{ minHeight: "24px", minWidth: "24px" }} />
                                                        </Badge> {item?.grade_name} {item?.subject_name}
                                                    </p>
                                                </div>

                                                <Button variant="outlined" className="cancelButton" onClick={() => navigate(teacherProfileDetails(locale), { state: { id: item?.teacher_id } })}>
                                                    <FormattedMessage id="ViewFullProfile" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ))}
                </Row>

            </Col>
        </Row>
    );
};

export default SearchFilterScreen;