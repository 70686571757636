import { takeLatest, put } from "redux-saga/effects";
import {
    BOOK_INTERVIEW_SLOT,
    BOOK_INTERVIEW_SLOT_SUCCESS,
    BOOK_INTERVIEW_SLOT_ERROR
} from "./constant";
import url from "services/urls.json";
import { postMethodWithToken } from "services/apiServices";
import { ClearTokenRedirectLogin, getUserRole } from "utils/general";

function* bookInterViewSlotApi(action) {

    try {
        let response;
        if (getUserRole() === 'Administrator') {
            response = yield postMethodWithToken(url.bookAdminInterviewSlot, action.data);
        } else {
            response = yield postMethodWithToken(url.bookInterviewSlot, action.data);
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: BOOK_INTERVIEW_SLOT_SUCCESS, payload: response });
            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: BOOK_INTERVIEW_SLOT_ERROR, payload: response });
            if (response.status === 401) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response);
            }
        }

    } catch (error) {
        yield put({ type: BOOK_INTERVIEW_SLOT_ERROR, payload: error });
    }
}

function* bookInterViewSlotSaga() {
    yield takeLatest(BOOK_INTERVIEW_SLOT, bookInterViewSlotApi);
}

export default bookInterViewSlotSaga;