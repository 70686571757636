import {
    UPDATE_USER_DATA,
    UPDATE_USER_DATA_ERROR,
    UPDATE_USER_DATA_SUCCESS,
} from "./constant";

const updateUserDataReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_USER_DATA:
            return { ...state, loading: true, error: '' };
        case UPDATE_USER_DATA_ERROR:
            return { ...state, type: UPDATE_USER_DATA_ERROR, loading: false, error: action.payload };
        case UPDATE_USER_DATA_SUCCESS:
            return { ...state, type: UPDATE_USER_DATA_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default updateUserDataReducer;