import { ACTIVE_INDEX, TEACHER_PROFILE_ID } from "./constant";

const initialDetails = {
    activeIndex: 1,
    teacherProfileId: ""
};

const basicDetailsReducer = (state = initialDetails, action) => {
    switch (action.type) {
        case ACTIVE_INDEX:
            return { ...state, activeIndex: action.data };
        case TEACHER_PROFILE_ID:
            return { ...state, teacherProfileId: action.data };
        default:
            return state;
    }
};

export default basicDetailsReducer;
