import { EmailValidation } from "utils/helper";
import { FormattedMessage } from "react-intl";


const CheckValidation = value => {

    const formError = {
        submit: true,
        email: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
    };

    const emptyKeys = [
        'email',
    ];


    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "email" && !EmailValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
            formError[emptyKeys[i]].errorMessage = <FormattedMessage id="PleaseEnterValidEmail!" />
        }
    }
    return formError;
}

export default CheckValidation;