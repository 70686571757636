import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Badge, Stack, Button, ButtonGroup } from "react-bootstrap";
import TeacherImage from "assets/images/Availability/teacherImage.svg";
import book from "assets/images/Availability/book.svg";
import recordVoice from "assets/images/Availability/record_voice_over.svg";
import 'assets/styles/common.scss';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/InterVIewSelectRound/InterViewSelectRound.scss";
import { LocaleContext } from "routes/Router";
import { getAvailableTeacherListCallAPI } from "store/Dashboard/GetAvailableTeacherList/action";
import { GET_AVAILABLE_TEACHER_LIST } from "store/Dashboard/GetAvailableTeacherList/constant";
import backIcon from "assets/images/backArrow.png";
import { getTeacherDetails, teacherDashboard, teacherUserManagement } from "routes/AppUrls";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectInput from "components/common/Input/SelectInput";
import moment from "moment";
import { bookInterViewSlotCallAPI } from "store/Dashboard/BookInterViewSlot/action";
import { BOOK_INTERVIEW_SLOT } from "store/Dashboard/BookInterViewSlot/constant";
import ToastNotification from "components/common/Toast";
import { getUserRole } from "utils/general";


const InterViewSelectRound = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const [conf, setConf] = ToastNotification();
    const intl = useIntl();
    const [selectTeacherId, setSelectTeacherId] = useState("");
    const [selectSubjectId, setSelectSubjectId] = useState("");
    const [teacherDetails, setTeacherDetails] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [timeSlotList, setTimeSlotList] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const teacherProfileId = useSelector(state => state?.basicDetailsReducer?.teacherProfileId);

    // const { id } = useParams();
    const { state } = useLocation();

    const AvailableTeacherList = useSelector(state => state?.getAvailableTeacherListReducer?.payload?.data?.data);

    useEffect(() => {
        getAvailableTeacherList();
    }, []);

    const getAvailableTeacherList = () => {
        if (getUserRole() === 'Administrator') {
            dispatch(getAvailableTeacherListCallAPI(GET_AVAILABLE_TEACHER_LIST, state, { id: teacherProfileId, run: true, navigate: navigate }));
        } else {
            dispatch(getAvailableTeacherListCallAPI(GET_AVAILABLE_TEACHER_LIST, state, { run: true, navigate: navigate }));
        }
    };

    const handleSelectSlot = (item) => {
        setSelectTeacherId('');
        setSelectSubjectId('');
        setTeacherDetails('');
        setSelectedDate(null);
        setSelectedTimeSlot('');
        setTimeSlotList('');
        setSelectTeacherId(item?.teacher?.id);
        setSelectSubjectId(item?.teacher?.subject_id);
        setTeacherDetails(item?.teacher);
    };

    const handleTimeSlot = (item) => {
        setSelectedTimeSlot(item);
    };

    const handleInputChange = (e) => {
        setTimeSlotList(e.target.value?.time);
        setSelectedDate(e.target.value?.date);
    };

    const handleInterViewBookSlot = () => {
        const combinedDateTime = moment(`${selectedDate} ${selectedTimeSlot?.available_time}`, "YYYY-MM-DD hh:mm A").format("YYYY-MM-DDTHH:mm:ss");
        if (getUserRole() === 'Administrator') {
            let body = {
                "new_teacher_id": teacherProfileId,
                "availability_id": selectedTimeSlot?.id,
                "topic": "Interview",
                "start_time": combinedDateTime,
                "duration": 1,
                "timezone": "UTC",
                "subject_id": selectSubjectId,
                "grade_id": state?.body?.gradeId,
                "teaching_material_id": state?.body?.teacherMaterialId
            }
            dispatch(bookInterViewSlotCallAPI(BOOK_INTERVIEW_SLOT, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
        } else {
            let body = {
                "availability_id": selectedTimeSlot?.id,
                "internal_teacher_id": selectTeacherId,
                "topic": "Interview",
                "start_time": combinedDateTime,
                "duration": 1,
                "timezone": "UTC",
                "subject_id": selectSubjectId,
                "grade_id": state?.body?.gradeId,
                "teaching_material_id": state?.body?.teacherMaterialId
            }
            dispatch(bookInterViewSlotCallAPI(BOOK_INTERVIEW_SLOT, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate }));
        }
    };

    const handleSuccess = (res) => {
        setConf({ msg: res?.data?.message, variant: 'success' });
        if (getUserRole() === 'Administrator') {
            navigate(teacherUserManagement(locale))
        } else {
            navigate(getTeacherDetails(locale))
        }
    };

    return (
        <Row className="mt-5 mt-lg-0 pb-5 m-0">
            <Col md={8}>
                <Row className="page-contents-view mt-3 m-0 p-15 md:p-32">
                    <div className="items-center p-0">
                        <Stack direction="horizontal" gap={2} className="flex">
                            <img src={backIcon} alt="Back Icon" className="backArrow" onClick={() => navigate(teacherDashboard(locale))} />
                            <h5 className="heading5 m-0 p-0"><FormattedMessage id="Interviewround" /></h5>
                        </Stack>
                        <p className="InterviewSubheading m-0 p-0 ps-3 ms-3 mt-2"><FormattedMessage id="You’vebeenmatchedwiththefollowingverifiedteachersfortheinterview." /></p>
                        <p className="m-0 p-0 mt-3 selectHeading ps-1"><FormattedMessage id="Selectoneoftheteacherstosetyouravailability" /></p>
                    </div>

                    {AvailableTeacherList?.length > 0 && AvailableTeacherList.map((item, index) => (
                        <Card className={`mb-3 mt-3 ${item?.teacher?.id === selectTeacherId ? 'shadowSelect' : 'shadow'} border-0`} onClick={() => handleSelectSlot(item)}>
                            <Card.Body>
                                <Row>
                                    <Col md={12} className="flex items-center">
                                        <div className="me-3">
                                            <img
                                                src={item?.teacher?.profile_image ? item?.teacher?.profile_image : TeacherImage}
                                                alt="Teacher"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div>
                                            <h5 className="heading6">{item?.teacher?.name}</h5>
                                            <p className=" paragraph1 mb-1 mt-3">{item?.teacher?.highest_qualification}</p>
                                            <div className="flex flex-wrap mt-3">
                                                <p className="paragraph1 mb-1 me-2 flex items-center">
                                                    <badgeClasses className="badge me-2">
                                                        <img src={recordVoice} style={{ minHeight: "24px", minWidth: "24px" }} />
                                                    </badgeClasses> {item?.teacher?.languages}
                                                </p>
                                                <p className="paragraph1 mb-1 flex items-center">
                                                    <Badge className="badge me-2">
                                                        <img src={book} style={{ minHeight: "24px", minWidth: "24px" }} />
                                                    </Badge> {item?.teacher?.grade} {item?.teacher?.subject}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ))}
                </Row>
            </Col>

            {selectTeacherId !== "" &&
                <Col md={4}>
                    <Row className="page-contents-view mt-3 m-0 p-15 md:p-32">
                        <div className="items-center">
                            <h5 className="m-0 p-0 TimeSlot"><FormattedMessage id="Selecttimeslot" /></h5>
                        </div>
                        <div className="flex justify-center items-center">
                            {/* <Button onClick={handlePreviousDate}>Previous</Button> Previous Button */}
                            <Col xs={12} md={8} className="flex">
                                <SelectInput
                                    placeholder={intl.formatMessage({ id: "SelectDate" })}
                                    name={"selectedDate"}
                                    value={selectedDate ? moment(selectedDate).format('Do MMMM, YYYY') : ''}
                                    data={teacherDetails?.availabities}
                                    onChange={(e) => handleInputChange(e)}
                                    marginTop={10}
                                />
                            </Col>
                            {/* <Button onClick={handleNextDate}>Next</Button> Next Button */}
                        </div>

                        <div className="mt-4">
                            <Col xs={12} md={12}>
                                <div className="slotList gap-2">
                                    {timeSlotList?.length > 0 && timeSlotList.map((item, index) => (
                                        <div className={(selectedTimeSlot === item) ? "slotSelect" : "slot"} onClick={() => handleTimeSlot(item)}>
                                            {item?.available_time} - {item?.available_end_time}
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </div>

                        <div className="mt-4 mb-1 selectedSlot">
                            <div>
                                <p className="mb-1 m-0 heading"> <FormattedMessage id="YourSelectedSlot" /></p>
                                {selectedTimeSlot !== "" &&
                                    // <p className="m-0 success">
                                    //     {moment(selectedDate).format('DD-MM-YYYY')}, {selectedTimeSlot?.available_time} - {selectedTimeSlot?.available_end_time}
                                    // </p>
                                    <p className="m-0 success">
                                        {selectedTimeSlot?.available_time} - {selectedTimeSlot?.available_end_time}
                                    </p>
                                }
                            </div>
                            <div>
                                <Button variant="contained" disabled={!selectedTimeSlot} className='button' onClick={handleInterViewBookSlot}><FormattedMessage id="BookSlot" /></Button>
                            </div>
                        </div>
                    </Row>
                </Col>
            }

        </Row >
    );
};

export default InterViewSelectRound;
