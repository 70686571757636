import React, { useContext, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import plusIcon from "assets/images/Navbar/plus.png";
import minusIcon from "assets/images/Navbar/minus.png";
import styled from 'styled-components';
import { LocaleContext } from 'routes/Router';
import 'assets/styles/common.scss';

const AccordionStyle = styled.div`
    .MuiPaper-root{
        border-bottom: 1px solid var(--Colors-Stroke-stroke, #DDDDDD) !important;
        box-shadow: none !important;
    }
`;

const ReusableAccordion = (props) => {
    const { locale } = useContext(LocaleContext);
    const [isExpanded, setIsExpanded] = useState(props?.defaultExpanded);

    const handleToggle = (event, expanded) => {
        setIsExpanded(expanded);
    };

    return (
        <AccordionStyle {...props} locale={locale}>
            <Accordion
                defaultExpanded={props?.defaultExpanded}
                // expanded={isExpanded === `panel${index}`}
                onChange={handleToggle}
            >
                <AccordionSummary expandIcon={isExpanded ? <img src={minusIcon} alt="icon" style={{ height: "24px", width: "24px" }} /> : <img src={plusIcon} alt="icon" style={{ height: "24px", width: "24px" }} />}>
                    <Typography className='fc-1E1E1E fw-500 ff-InterMedium fs-20 ' >{props?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <Typography className='fc-A2A2A2 fw-400 ff-InterRegular fs-16 '>{props?.children}</Typography>
                </AccordionDetails>
            </Accordion>
        </AccordionStyle>
    );
};

export default ReusableAccordion;
