import React, { useContext, useEffect, useState } from 'react';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import Button from '@mui/material/Button';
import TimeIcon from "assets/images/TeacherDashboard/timer.svg";
import circleCheckIcon from "assets/images/TeacherDashboard/circleCheckIcon.svg";
import "components/feature/Questionnaire/questionnaire.scss";
import { useSelector } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { teacherDashboard } from 'routes/AppUrls';
import { useNavigate } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from "react-intl";

const QuestionAnswer = ({ onClick }) => {

    const [selectAnswer, setSelectAnswer] = useState("");
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const [conf, setConf] = ToastNotification();
    const QuestionsData = useSelector(state => state?.getQuestionsReducer && state?.getQuestionsReducer?.payload?.data?.data);
    const [count, setCount] = useState(1);
    const [questionId, setQuestionId] = useState("");
    const [submitAnswers, setSubmitAnswers] = useState([]);
    const intl = useIntl();
    const [timeLeft, setTimeLeft] = useState(600);

    useEffect(() => {
        // Exit if the time is 0
        if (timeLeft === 0) return;

        // Decrease timeLeft by 1 every second
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [timeLeft]);


    // Convert seconds to minutes and seconds
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleRedirect = () => {
        if (count <= QuestionsData?.content?.questions?.length) {
            if (selectAnswer !== "") {
                submitAnswers.push({ "answer": selectAnswer, "question_id": questionId });
                setSelectAnswer("");
                if (count === QuestionsData?.content?.questions?.length) {
                    let body = {
                        "test_id": QuestionsData?.test_id,
                        "submitted_answers": submitAnswers
                    }
                    onClick(body);
                } else {
                    setCount(count + 1);
                }
            } else {
                setConf({ msg: intl.formatMessage({ id: "PleaseSelectAnswer" }) })
            }
        }
    };

    const handleSelectOption = (answer, questionId) => {
        setSelectAnswer(answer);
        setQuestionId(questionId);
    }

    return (
        <Row className="main-content p-4 mt-5 mt-lg-0 pb-5 m-0">
            <Row className='timerContainer'>
                <Col xs={12} sm={6} md={6} className='d-flex justify-content-start'>
                    <p className='questions m-0 align-items-center'><FormattedMessage id="Question" /> {count} <FormattedMessage id="of" /> {QuestionsData?.content?.questions?.length}</p>
                </Col>
                {/* <Col xs={4} sm={6} md={3}>
                    <Stack direction="horizontal" gap={2} className='d-flex justify-content-end justify-content-md-center'>
                        <Image src={TimeIcon} alt={'timeIcon'} />
                        <p className='Time m-0 flex'>
                            <div className='flex align-items-end' style={{ width: "50px" }}>{formatTime(timeLeft)}</div>
                            <div className='minute flex align-items-center'>min</div>
                        </p>
                    </Stack>
                </Col> */}
                <Col xs={6} sm={6} md={6} className='exitText d-none justify-content-end d-sm-flex'>
                    <p className='m-0' onClick={() => navigate(teacherDashboard(locale))}><FormattedMessage id="ExitTest" /></p>
                </Col>
            </Row>

            <Row className='questionDetails mt-4 mb-4'>
                {QuestionsData?.content?.questions?.length > 0 && QuestionsData?.content?.questions?.map((k, i) => (
                    count === (i + 1) &&
                    <Col md={8} className='justify-content-start'>
                        <p className='m-0 align-items-center questionHeading'>
                            {`Q${k?.question_id}. ${k?.question}`}
                        </p>
                        <h5 className='options mt-4'>
                            <FormattedMessage id="Options" />:
                        </h5>

                        <div className="question-list mt-4">
                            {k?.options?.length > 0 && k?.options?.map((question, index) => (
                                <div key={index} className={selectAnswer === question ? "selectQuestion-item" : "question-item"} onClick={() => handleSelectOption(question, k?.question_id)}>
                                    <div className='d-flex justify-content-center align-items-center gap-3'>
                                        <p className={selectAnswer === question ? "selecterCircle m-0" : "circle m-0"}>{index + 1}</p>
                                        <p className='m-0'>{question}</p>
                                    </div>
                                    {selectAnswer === question &&
                                        <div><Image src={circleCheckIcon} style={{ minWidth: "24px", minHeight: "24px" }} alt="icon" /></div>
                                    }
                                </div>
                            ))}
                        </div>
                    </Col>
                ))}
            </Row>

            <Stack direction="horizontal" gap={2} className='d-none justify-content-center d-sm-flex mt-5 mb-5'>
                <Button variant="contained" className='button' onClick={() => handleRedirect()}>{QuestionsData?.content?.questions?.length === count ? <FormattedMessage id="Submit&proceed" /> : <FormattedMessage id="Submit&proceedtonextquestion" />}</Button>
            </Stack>

            <Stack direction="horizontal" gap={2} className='timerContainer d-flex justify-content-between d-sm-none mt-5 mb-5'>
                <Button variant="contained" className='button' onClick={() => navigate(teacherDashboard(locale))}><FormattedMessage id="Exit" /></Button>
                <Button variant="contained" className='button' onClick={() => handleRedirect()}>{QuestionsData?.content?.questions?.length === count ? <FormattedMessage id="Submit&proceed" /> : <FormattedMessage id="Next" />}</Button>
            </Stack>
        </Row>
    );
};

export default QuestionAnswer;