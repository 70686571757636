import {
    EDIT_PROFILE,
    EDIT_PROFILE_ERROR,
    EDIT_PROFILE_SUCCESS,
} from "./constant";

const editProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_PROFILE:
            return { ...state, loading: true, error: '' };
        case EDIT_PROFILE_ERROR:
            return { ...state, type: EDIT_PROFILE_ERROR, loading: false, error: action.payload };
        case EDIT_PROFILE_SUCCESS:
            return { ...state, type: EDIT_PROFILE_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default editProfileReducer;