import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import 'assets/styles/common.scss';
import OtpInput from 'components/common/Input/OtpInput';
import { authEmailVerificationCallApi } from 'store/Auth/Forgot/EmailVerfication/action';
import { AUTH_EMAIL_VERIFICATION } from 'store/Auth/Forgot/EmailVerfication/constant';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from "react-intl";

const OtpVerificationScreen = ({ onClick, email }) => {

    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [conf, setConf] = ToastNotification();

    const ResendLoader = useSelector(state => state?.authEmailVerificationReducer?.loading);

    const OtpValidation = (value) => {
        if (value.length > 0 && value.length == 6) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const handleSubmit = () => {
        if (isValid) {
            onClick(otp);
        }
    };

    const handleResend = () => {
        if (!ResendLoader) {
            let body = {
                "email": email
            };
            dispatch(authEmailVerificationCallApi(AUTH_EMAIL_VERIFICATION, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate, handleToastError: (res) => ToastErrorFunction(res), toast: true }))
        }
    };

    const handleSuccess = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: res?.data?.data, variant: 'success' });
        } else {
            setConf({ msg: res?.data?.data, variant: 'error' });
        }
    };

    const ToastErrorFunction = (res) => {
        setConf({ msg: intl.formatMessage({ id: "InvalidCredential!" }), variant: 'error' });
    };

    return (
        <Container>

            <Row className='pt-20  md:pt-120'>
                <Row className='mb-5'>
                    <Col>
                        <h2 className='heading2'><FormattedMessage id="OtpVerification" /></h2>
                    </Col>
                </Row>

                <Col xs={12} md={12} className='mt-5'>
                    <OtpInput
                        marginTop="30"
                        TextFieldsProps={{ placeholder: 0 }}
                        validateChar={(val) => !isNaN(val)}
                        value={otp}
                        length={6}
                        onChange={(value) => { setOtp(value); OtpValidation(value); }}
                    />
                </Col>
            </Row>

            <Row className='my-5'>
                <Col xs={12} md={12} className='d-flex justify-content-center emailSubHeading'>
                    <p><FormattedMessage id="DidNotReceiveOtpCode" /></p>
                </Col>
                <Col xs={12} md={12} className='d-flex justify-content-center'>
                    <p className={!ResendLoader ? 'resend' : 'resend disable opacity-50'} onClick={handleResend}><FormattedMessage id="register.Resend" /></p>
                </Col>
            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'><FormattedMessage id="Verify&Proceed" /></Button>
            </Row>
        </Container>
    );
};

export default OtpVerificationScreen;
