import React from 'react';
import { Image } from 'react-bootstrap';
import imageIcon from "assets/images/TeacherDashboard/imageFrame.svg";

const InterviewScreen = () => {

    return (
      <div className="main-content flex text-center flex-col align-items-center justify-content-center page-content p-4 mt-5 mt-lg-0 pb-5 min-h-[89vh]">
        <Image src={imageIcon} alt="icon" className="mb-5 d-block mx-auto" />
        <h2>Interview Round</h2>
        <p>
          You will be notified when you are matched with our internal teachers for the interview round.
        </p>
      </div>
    );
};

export default InterviewScreen;