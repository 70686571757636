import {
    STATE_DROPDOWN,
    STATE_DROPDOWN_ERROR,
    STATE_DROPDOWN_SUCCESS,
} from "./constant";

const stateDropDownReducer = (state = {}, action) => {
    switch (action.type) {
        case STATE_DROPDOWN:
            return { ...state, loading: true, error: '' };
        case STATE_DROPDOWN_ERROR:
            return { ...state, type: STATE_DROPDOWN_ERROR, loading: false, error: action.payload };
        case STATE_DROPDOWN_SUCCESS:
            return { ...state, type: STATE_DROPDOWN_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default stateDropDownReducer;