import React, { useContext } from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import emailIcon from "assets/images/Registration/sent_email.png";
import { login, resetPassword } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { useDispatch, useSelector } from 'react-redux';
import { authResendCallAPI } from 'store/Auth/Resend/action';
import { AUTH_RESEND } from 'store/Auth/Resend/constant';
import ToastNotification from 'components/common/Toast';
import { FormattedMessage, useIntl } from "react-intl";

const SendEmail = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [conf, setConf] = ToastNotification();
    const intl = useIntl();
    const { locale } = useContext(LocaleContext);
    const location = useLocation();
    const ResetEmail = location?.pathname === `/${locale}/reset_email` ? true : false;
    const ResendLoader = useSelector(state => state?.authResendReducer?.loading);

    const handleSubmit = () => {
        if (ResetEmail) {
            navigate(resetPassword(locale));
        } else {
            navigate(login(locale));
        }
    };

    const handleResend = () => {
        if (!ResendLoader) {
            dispatch(authResendCallAPI(AUTH_RESEND, {}, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate, handleToastError: (res) => ToastErrorFunction(res), toast: true }));
        }
    };

    const handleSuccess = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: res?.data?.data, variant: 'success' });
        } else {
            setConf({ msg: res?.data?.data, variant: 'error' });
        }
    };

    const ToastErrorFunction = (res) => {
        setConf({ msg: intl.formatMessage({ id: "InvalidCredential!" }), variant: 'error' });
    };

    return (
        <Container className='container'>

            <Row className='pt-20  md:pt-120'>
                <Row className='my-5'>
                    <Col xs={12} md={12} className='d-flex justify-content-center'>
                        <Image src={emailIcon} alt={'icon'} height={62} width={84} />
                    </Col>

                    <Col xs={12} md={12} className='d-flex justify-content-center mt-4 emailHeading'>
                        {ResetEmail ?
                            <p><FormattedMessage id="register.EmailPasswordResetText" /></p>
                            :
                            <p><FormattedMessage id="register.EmailSentText" /></p>
                        }

                    </Col>
                </Row>
                <Row className='my-5'>
                    <Col xs={12} md={12} className='d-flex justify-content-center emailSubHeading'>
                        <p><FormattedMessage id="register.DidNotReceiveTheEmail" /></p>
                    </Col>
                    <Col xs={12} md={12} className='d-flex justify-content-center'>
                        <p className={!ResendLoader ? 'resend' : 'resend disable opacity-50'} onClick={handleResend}><FormattedMessage id="register.Resend" /></p>
                    </Col>
                </Row>
            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'>{ResetEmail ? <FormattedMessage id="register.Continue" /> : <FormattedMessage id="register.GoToLogin" />}</Button>
            </Row>

        </Container>
    );
};

export default SendEmail;
