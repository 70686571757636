import axios from "axios";
import LocalStorageManager from "managers/LocalStorageManger";
import url from "utils/environment/env.json";
import { getDeviceId, getDeviceName } from "utils/general";

//////BaseUrl////////////////////////////////////
var baseUrl = "";
if (process.env.NODE_ENV === 'production') {
   baseUrl = url.BASE_URL_PRODUCTION;
} else if (process.env.NODE_ENV === 'staging') {
   baseUrl = url.BASE_URL_STAGING;
} else {
   baseUrl = url.BASE_URL_DEVELOPMENT;
}
console.log("baseUrl :", baseUrl, "NODE_ENV :", process.env.NODE_ENV);
//////BaseUrl////////////////////////////////////

///////////token/////////////////////////////////
const getToken = () => {
   const token = LocalStorageManager.getItem("token");
   return token;
}
///////////token/////////////////////////////////


///////////Language/////////////////////////////////
const getLocale = () => {
   const locale = LocalStorageManager.getItem("locale");
   return locale;
}
///////////Language/////////////////////////////////

//////////////Hmac Key///////////////////////////
// var CryptoJS = require("crypto-js");
// const hmacConvert = (method, data) => {
//    var string = `${url.hmacKey}-${method}-${data !== "" ? JSON.stringify(data) : ""}`;
//    var hash = CryptoJS.HmacSHA256(string, url.hmacKey);
//    var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
//    return hashInBase64;
// };
//////////////Hmac Key///////////////////////////

export const getMethod = async (url) => {
   try {
      let data = await axios({
         method: "GET",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const postMethod = async (url, body) => {
   try {
      let data = await axios({
         method: "POST",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            "device-id": getDeviceId(),
            "device-type": "Web"
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const putMethod = async (url, body) => {
   try {
      let data = await axios({
         method: "PUT",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const patchMethod = async (url, body) => {
   try {
      let data = await axios({
         method: "PATCH",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const deleteMethod = async (url, body) => {
   const queryString = new URLSearchParams(body).toString();
   try {
      let data = await axios({
         method: "DELETE",
         url: baseUrl + url + '?' + queryString,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const getMethodWithToken = async (url) => {
   try {
      let data = await axios({
         method: "GET",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            Authorization: "Bearer" + " " + getToken(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const postMethodWithToken = async (url, body) => {
   try {
      let data = await axios({
         method: "POST",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            Authorization: "Bearer" + " " + getToken(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const postProfileMethodWithToken = async (url, body) => {
   try {
      let data = await axios({
         method: "POST",
         url: baseUrl + url,
         headers: {
            'Content-Type': 'multipart/form-data',
            "language": getLocale(),
            Authorization: "Bearer" + " " + getToken(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const postMethodWithRegisterToken = async (url, body) => {
   const token = LocalStorageManager.getItem('registerToken');
   try {
      let data = await axios({
         method: "POST",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            Authorization: "Bearer" + " " + token,
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const putMethodWithToken = async (url, body) => {
   try {
      let data = await axios({
         method: "PUT",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            'Authorization': "Bearer" + " " + getToken(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const deleteMethodWithToken = async (url, body) => {
   try {
      let data = await axios({
         method: "DELETE",
         url: baseUrl + url,
         headers: {
            "Content-Type": "application/json",
            "language": getLocale(),
            'Authorization': "Bearer" + " " + getToken(),
            "device-id": getDeviceId(),
            "device-type": getDeviceName()
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};




