import {
    GRADE_LEVEL_DROPDOWN,
    GRADE_LEVEL_DROPDOWN_ERROR,
    GRADE_LEVEL_DROPDOWN_SUCCESS,
} from "./constant";

const gradeLevelDropDownReducer = (state = {}, action) => {
    switch (action.type) {
        case GRADE_LEVEL_DROPDOWN:
            return { ...state, loading: true, error: '' };
        case GRADE_LEVEL_DROPDOWN_ERROR:
            return { ...state, type: GRADE_LEVEL_DROPDOWN_ERROR, loading: false, error: action.payload };
        case GRADE_LEVEL_DROPDOWN_SUCCESS:
            return { ...state, type: GRADE_LEVEL_DROPDOWN_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default gradeLevelDropDownReducer;