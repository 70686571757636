import {
    GET_ALL_STUDENTS_LIST,
    GET_ALL_STUDENTS_LIST_ERROR,
    GET_ALL_STUDENTS_LIST_SUCCESS,
} from "./constant";

const getAllStudentsListReducer = (state = {}, action) => {

    switch (action.type) {
        case GET_ALL_STUDENTS_LIST:
            return { ...state, loading: true, error: '' };
        case GET_ALL_STUDENTS_LIST_ERROR:
            return { ...state, type: GET_ALL_STUDENTS_LIST_ERROR, loading: false, error: action.payload };
        case GET_ALL_STUDENTS_LIST_SUCCESS:
            return { ...state, type: GET_ALL_STUDENTS_LIST_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default getAllStudentsListReducer;