import { takeLatest, put } from "redux-saga/effects";
import {
    SEARCH_USER_BY_FIELD,
    SEARCH_USER_BY_FIELD_SUCCESS,
    SEARCH_USER_BY_FIELD_ERROR,
} from "./constant";
import url from "services/urls.json";
import { postMethodWithToken } from "services/apiServices";
import { ClearTokenRedirectLogin } from "utils/general";

function* searchUserByFieldApi(action) {

    try {
        let params;
        if (action?.external?.params?.clearedList) {
            params = url.postsearchUserByField + "?aistatus=1" + "&page=" + action?.external?.params?.page
        } else if (action?.external?.params?.verifiedList) {
            params = url.postsearchUserByField + "?verified=1" + "&page=" + action?.external?.params?.page
        } else if (action?.external?.params?.student) {
            params = url.postsearchUserByField + "?role=student" + "&page=" + action?.external?.params?.page
        } else if (action?.external?.params?.internalTeacher) {
            params = url.postsearchUserByField + "?is_internal=1" + "&page=" + action?.external?.params?.page
        } else {
            params = url.postsearchUserByField + "?page=" + action?.external?.params?.page
        }
        const response = yield postMethodWithToken(params, action?.data);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: SEARCH_USER_BY_FIELD_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: SEARCH_USER_BY_FIELD_ERROR, payload: response });
            if (response.status === 401) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response);
            }
        }

    } catch (error) {
        yield put({ type: SEARCH_USER_BY_FIELD_ERROR, payload: error });
    }
};

function* searchUserByFieldSaga() {
    yield takeLatest(SEARCH_USER_BY_FIELD, searchUserByFieldApi);
};

export default searchUserByFieldSaga;