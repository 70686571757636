import React, { useContext } from "react";
import { Row, Col, Stack, Badge, Card, Image } from "react-bootstrap";
import 'assets/styles/common.scss';
import backIcon from "assets/images/backArrow.png";
import { useNavigate } from "react-router-dom";
import { getLandingURL, teacherProfileDetails } from "routes/AppUrls";
import { LocaleContext } from "routes/Router";
import "components/feature/Student/serachFilterScreen/searchFilter.scss";
import { useIntl, FormattedMessage } from "react-intl";
import TeacherImage from "assets/images/Availability/teacherImage.svg";
import book from "assets/images/Availability/book.svg";
import recordVoice from "assets/images/Availability/record_voice_over.svg";
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
import StarIcon from "assets/images/star.svg";

const FeatureListDetailScreen = () => {

    const navigate = useNavigate();
    const intl = useIntl();
    const { locale } = useContext(LocaleContext);

    const FeaturedListData = useSelector(state => state?.featuredListReducer?.payload?.data?.data);


    return (
        <Row className="bc-FDFCFA px-0 px-lg-5 m-0 p-0 mb-5" >
            <Col>
                <Row className="mt-4 pt-2 m-0">
                    <Stack direction="horizontal" gap={2} className="flex p-0">
                        <img src={backIcon} alt="Back Icon" className="backArrow" style={{ cursor: "pointer" }} onClick={() => navigate(getLandingURL(locale))} />
                        <h2 className="fs-20 fw-600 ff-LexendDecaSemibold fc-334155 p-0 m-0"><FormattedMessage id="Feature List" /></h2>
                    </Stack>
                </Row>

                <Row className="m-0 filterShadow">
                    {FeaturedListData?.length > 0 && FeaturedListData.map((item, index) => (
                        <Card className={`mt-3 shadow border-0`}>
                            <Card.Body>
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="d-flex flex-wrap">
                                        <img
                                            src={item?.profile_image ? item?.profile_image : TeacherImage}
                                            alt="Teacher"
                                            className="img-fluid me-3"
                                            style={{ height: "172px", width: "172px", objectFit: 'contain' }}
                                        />
                                        <div className="d-flex flex-wrap justify-content-between flex-col text-start">
                                            <h5 className="fs-24 fw-500 ff-LexendDecaMedium fc-1E1E1E">{item?.name}</h5>
                                            <p className="fs-18 fw-400 ff-InterRegular fc-212426 ">{item?.qualifications?.[0]?.university_name}
                                                <span className="border-left-only ms-2 ps-2">{item?.experience + " years of experience"}</span>
                                            </p>
                                            <p className="fs-18 fw-400 ff-InterRegular fc-212426 ">{item?.city?.name}</p>
                                            <div className="flex flex-wrap ">
                                                <p className="fs-18 fw-500 ff-InterMedium fc-4D4F51 mb-1 me-2 flex items-center">
                                                    <badgeClasses className="badge me-2">
                                                        <img src={recordVoice} style={{ minHeight: "24px", minWidth: "24px" }} />
                                                    </badgeClasses> {item?.language}
                                                </p>
                                                <p className="fs-18 fw-500 ff-InterMedium fc-4D4F51  mb-1 flex items-center">
                                                    <Badge className="badge me-2">
                                                        <img src={book} style={{ minHeight: "24px", minWidth: "24px" }} />
                                                    </Badge> {item?.grade_name} {item?.subject_name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-between flex-col text-end">
                                        <p className="p-0 m-0 fs-20 fw-700 ff-InterBold fc-1E1E1E"><FormattedMessage id="QAR2.5" /> <span className="fs-16 fw-500 ff-InterMedium fc-1E1E1E"><FormattedMessage id="persession" /></span></p>
                                        <div className="d-flex align-items-center justify-content-end mt-2">
                                            <Image src={StarIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                            <p className='mb-0 ml-2 fc-A2A2A2 fw-500 ff-InterRegular'>{item?.average_rating ? (+item.average_rating).toFixed(1) : null}</p>
                                        </div>

                                        {/* <Button variant="outlined" className="cancelButton">
                                            <FormattedMessage id="ViewFullProfile" />
                                        </Button> */}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default FeatureListDetailScreen;