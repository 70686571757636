import {
    ADD_CONTENT_MANAGEMENT_DATA,
    ADD_CONTENT_MANAGEMENT_DATA_ERROR,
    ADD_CONTENT_MANAGEMENT_DATA_SUCCESS,
} from "./constant";

const addContentManagementDataReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_CONTENT_MANAGEMENT_DATA:
            return { ...state, loading: true, error: '' };
        case ADD_CONTENT_MANAGEMENT_DATA_ERROR:
            return { ...state, type: ADD_CONTENT_MANAGEMENT_DATA_ERROR, loading: false, error: action.payload };
        case ADD_CONTENT_MANAGEMENT_DATA_SUCCESS:
            return { ...state, type: ADD_CONTENT_MANAGEMENT_DATA_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default addContentManagementDataReducer;