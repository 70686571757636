import {
    MARK_INTERNAL_TEACHER,
    MARK_INTERNAL_TEACHER_ERROR,
    MARK_INTERNAL_TEACHER_SUCCESS,
} from "./constant";

const markInternalTeacherReducer = (state = {}, action) => {
    switch (action.type) {
        case MARK_INTERNAL_TEACHER:
            return { ...state, loading: true, error: '' };
        case MARK_INTERNAL_TEACHER_ERROR:
            return { ...state, type: MARK_INTERNAL_TEACHER_ERROR, loading: false, error: action.payload };
        case MARK_INTERNAL_TEACHER_SUCCESS:
            return { ...state, type: MARK_INTERNAL_TEACHER_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default markInternalTeacherReducer;