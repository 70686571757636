import {
    GET_ALL_INTERNAL_TEACHER_LIST,
    GET_ALL_INTERNAL_TEACHER_LIST_ERROR,
    GET_ALL_INTERNAL_TEACHER_LIST_SUCCESS,
} from "./constant";

const getAllInternalTeachersListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_INTERNAL_TEACHER_LIST:
            return { ...state, loading: true, error: '' };
        case GET_ALL_INTERNAL_TEACHER_LIST_ERROR:
            return { ...state, type: GET_ALL_INTERNAL_TEACHER_LIST_ERROR, loading: false, error: action.payload };
        case GET_ALL_INTERNAL_TEACHER_LIST_SUCCESS:
            return { ...state, type: GET_ALL_INTERNAL_TEACHER_LIST_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default getAllInternalTeachersListReducer;