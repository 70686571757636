import {
    FEATURED_LIST,
    FEATURED_LIST_ERROR,
    FEATURED_LIST_SUCCESS,
} from "./constant";

const featuredListReducer = (state = {}, action) => {
    switch (action.type) {
        case FEATURED_LIST:
            return { ...state, loading: true, error: '' };
        case FEATURED_LIST_ERROR:
            return { ...state, type: FEATURED_LIST_ERROR, loading: false, error: action.payload };
        case FEATURED_LIST_SUCCESS:
            return { ...state, type: FEATURED_LIST_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default featuredListReducer;