import { ACTIVE_INDEX, TEACHER_PROFILE_ID } from "./constant";

export const basicDetailsCallApi = (type, data) => {
    switch (type) {
        case ACTIVE_INDEX:
            return {
                type: type,
                data: data
            }
    }
};

export const teacherProfileIdCallApi = (type, data) => {
    switch (type) {
        case TEACHER_PROFILE_ID:
            return {
                type: type,
                data: data
            }
    }
};