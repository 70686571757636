import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectInput from 'components/common/Input/SelectInput';
import 'assets/styles/common.scss';
import CheckValidation from "components/feature/Teacher/ProfessionalDetails/validation";
import { qualificationDropDownCallAPI } from 'store/Auth/Registration/Qualification/action';
import { QUALIFICATION_DROPDOWN } from 'store/Auth/Registration/Qualification/constant';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherDetails } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { ProfessionalDetail } from 'store/BasicDetails/RegisterationDetails/actions';
import { PROFESSIONAL_DETAILS } from 'store/BasicDetails/RegisterationDetails/constant';
import { FormattedMessage, useIntl } from "react-intl";

const ProfessionalDetails = () => {

    const navigate = useNavigate();
    const intl = useIntl();
    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);

    const [formvalue, setFormValue] = useState({
        qualification: "",
    });

    const [formId, setFormId] = useState({
        qualification: "",
    });

    const qualificationList = useSelector(state => state?.qualificationDropDownReducer?.payload?.data);

    useEffect(() => {
        getQualificationListApi();
    }, []);

    const getQualificationListApi = () => {
        let body = {
            "slug": "qualification"
        };
        dispatch(qualificationDropDownCallAPI(QUALIFICATION_DROPDOWN, body, { navigate: navigate }));
    };

    const handleSubmit = () => {
        const error = CheckValidation(formvalue);
        setIsError(error);
        if (error.submit) {
            dispatch(ProfessionalDetail(PROFESSIONAL_DETAILS, { ProfessionalDetailsData: formId }, { navigate: navigate }));
            navigate(getTeacherDetails(locale));
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));

        setFormId((prev) => ({
            ...prev,
            [name]: value?.id
        }));
    };

    return (
        <Container className='container'>

            <Row className='pt-20  md:pt-120 mb-5'>
                <Row className='mb-5'>
                    <Col>
                        <h2 className='heading'><FormattedMessage id="register.ProfessionalDetails" /></h2>
                    </Col>
                </Row>

                <Col xs={12} md={12}>
                    <SelectInput
                        label={<FormattedMessage id="register.HighestQualification" />}
                        placeholder={intl.formatMessage({ id: "register.HighestQualification" })}
                        name={"qualification"}
                        value={formvalue?.qualification}
                        data={qualificationList?.data}
                        onChange={(e) => handleInputChange(e)}
                        errorText={isError && isError.qualification.error && isError.qualification.errorMessage}
                    />
                </Col>

            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'><FormattedMessage id="register.Continue" /></Button>
            </Row>
        </Container>
    );
};

export default ProfessionalDetails;
