import React, { useContext, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import 'assets/styles/common.scss';
import CheckValidation from 'components/feature/Teacher/SetPassword/validation';
import { getTeacherDetails, login, professionalDetails } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { useDispatch, useSelector } from 'react-redux';
import { authResetPasswordCallApi } from 'store/Auth/Forgot/ResetPassword/action';
import { AUTH_RESET_PASSWORD } from 'store/Auth/Forgot/ResetPassword/constant';
import ToastNotification from 'components/common/Toast';
import { PasswordDetail } from 'store/BasicDetails/RegisterationDetails/actions';
import { PASSWORD_DETAILS } from 'store/BasicDetails/RegisterationDetails/constant';
import { FormattedMessage, useIntl } from "react-intl";

const SetPasswords = ({ email }) => {

    const navigate = useNavigate();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { locale } = useContext(LocaleContext);
    const location = useLocation();
    const [conf, setConf] = ToastNotification();
    const ResetPassword = location?.pathname === `/${locale}/reset_password` ? true : false;
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isPassConfirmVisible, setIsPassConfirmVisible] = useState(false);

    const [formValue, setFormValue] = useState({
        password: "",
        confirmPassword: "",
    });

    const Roles = useSelector(state => state?.RegistrationDetailsReducer?.userRoles);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            if (ResetPassword) {
                let body = {
                    "email": email,
                    "password": formValue?.password,
                    "password_confirmation": formValue?.confirmPassword
                };
                dispatch(authResetPasswordCallApi(AUTH_RESET_PASSWORD, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate, handleToastError: (res) => ToastErrorFunction(res), toast: true }));
            } else {
                dispatch(PasswordDetail(PASSWORD_DETAILS, { passwordDetailsData: formValue }, { navigate: navigate }));
                {
                    Roles === "teacher" ?
                        navigate(professionalDetails(locale))
                        :
                        navigate(getTeacherDetails(locale))
                }
            }
        }
    };

    const handleSuccess = (res) => {
        setConf({ msg: res?.data?.data, variant: 'success' });
        navigate(login(locale));
    };

    const ToastErrorFunction = (res) => {
        setConf({ msg: intl.formatMessage({ id: "InvalidCredential!" }), variant: 'error' });
    };

    return (
        <Container className='container'>

            <Row className='pt-20  md:pt-120 mb-5'>
                <Row className='mb-5'>
                    <Col>
                        {ResetPassword ?
                            <h2 className='heading'><FormattedMessage id="register.ResetPassword" /></h2>
                            :
                            <h2 className='heading'><FormattedMessage id="register.SetPassword" /></h2>
                        }
                    </Col>
                </Row>

                <Col xs={12} md={12}>
                    <LabelWithInput
                        label={<FormattedMessage id="register.Password" />}
                        placeholder={intl.formatMessage({ id: "register.Password" })}
                        helperInfoPassword={true}
                        name="password"
                        value={formValue?.password}
                        type={isPassVisible ? "text" : "password"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {isPassVisible ? (
                                        <VisibilityOutlinedIcon sx={{ cursor: "pointer", color: '#911E59' }} onClick={() => setIsPassVisible(!isPassVisible)} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ cursor: "pointer", color: '#911E59' }} onClick={() => setIsPassVisible(!isPassVisible)} />
                                    )}
                                </>
                            )
                        }}
                        errorText={isError && isError.password.error && isError.password.errorMessage}
                    />
                </Col>

                <Col xs={12} md={12} className='mt-5'>
                    <LabelWithInput
                        label={<FormattedMessage id="register.ConfirmPassword" />}
                        placeholder={intl.formatMessage({ id: "register.ConfirmPassword" })}
                        name="confirmPassword"
                        value={formValue?.confirmPassword}
                        type={isPassConfirmVisible ? "text" : "password"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {isPassConfirmVisible ? (
                                        <VisibilityOutlinedIcon sx={{ cursor: "pointer", color: '#911E59' }} onClick={() => setIsPassConfirmVisible(!isPassConfirmVisible)} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ cursor: "pointer", color: '#911E59' }} onClick={() => setIsPassConfirmVisible(!isPassConfirmVisible)} />
                                    )}
                                </>
                            )
                        }}
                        errorText={isError && isError.confirmPassword.error && isError.confirmPassword.errorMessage}
                    />
                </Col>

            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'><FormattedMessage id="register.Continue" /></Button>
            </Row>
        </Container>
    );
};

export default SetPasswords;
