import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const RatingProgressBar = ({ rating, value, count }) => {
    return (
        <Box display="flex" alignItems="center" mb={1}>
            {/* Rating label */}
            <Typography variant="body2" color="textSecondary">
                {rating}
            </Typography>

            {/* Progress bar */}
            <Box width="100%" mx={1}>
                <LinearProgress
                    variant="determinate"
                    value={value}
                    sx={{
                        height: 8,
                        borderRadius: 8,
                        backgroundColor: '#F5F5F5',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#375D84', // Adjust color to your preference
                        },
                    }}
                />
            </Box>

            {/* Count label */}
            <Typography variant="body2" color="textSecondary">
                ({count})
            </Typography>
        </Box>
    );
};

export default RatingProgressBar;