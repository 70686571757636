import {
    USER_TYPE_DROPDOWN,
} from "./constant";

export const userTypeDropdownCallAPI = (type, data, external = {}) => {
    switch (type) {
        case USER_TYPE_DROPDOWN:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};