import { EmailValidation, PhoneNumberValidation } from "utils/helper";
import { FormattedMessage } from "react-intl";

const CheckQualificationValidation = value => {

    const formError = {
        submit: true,
        specialisation: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        university: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        startYear: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        endYear: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
    };

    const emptyKeys = [
        'specialisation',
        'university',
        'startYear',
        'endYear',
    ];


    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }
    return formError;
}

export default CheckQualificationValidation;